import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';

import { Button, Input, Typography } from '~/ui/components';

import _ from 'lodash';
import { FilterContainer, FilterForm } from './Filters.styled';

export type FiltersProps = {
  onSubmit?: (values: any) => void;
  loading?: boolean;
};

export function Filters({ onSubmit, loading }: FiltersProps) {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const filters = useMemo(
    () => [
      {
        id: 'document',
        label: 'Buscar pelo CPF',
        initialValue: params.document,
        input: <Input placeholder="Insira o CPF" allowClear />,
      },
    ],
    [],
  );

  const handleOnSubmit = (values) => {
    onSubmit?.(_.omitBy(values, _.isEmpty));
  };

  return (
    <FilterContainer>
      <Typography type="paragraphLarge" weight={500}>
        Propostas Consignado Privado
      </Typography>

      <FilterForm inputs={filters} onSubmit={handleOnSubmit} loading={loading ? 1 : 0}>
        <Button size="md" rounded className="submit-button" loading={loading}>
          Buscar
        </Button>
      </FilterForm>
    </FilterContainer>
  );
}
