import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  weight: 700,
  color: themeToggleColor(theme, 'element.secondary'),
  element: 'span',
}))``;
