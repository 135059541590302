import React, { useCallback } from 'react';
import { Person } from '~/typings/entities/person';

import { Button } from '~/ui/components';

import { cellToValue } from '~/common/utils/table';
import { useDrawer } from '~/hooks/useDrawer';
import { UiTable } from './Invoices.styles';
import InvoiceDetails from '../../Drawers/InvoiceDetails/InvoiceDetails';

type InvoicesProps = {
  person: Person;
  loading?: boolean;
};

function Invoices({ person, loading }: InvoicesProps) {
  const { openDrawer } = useDrawer();

  const handleOnClick = useCallback(() => {
    openDrawer('invoice-drawer', <InvoiceDetails document={person.document} />, {
      title: 'Detalhamento de fatura',
      width: 560,
      closable: true,
    });
  }, [person]);

  const cellToDetails = () => (
    <Button
      onClick={handleOnClick}
      customColor="brand.primary"
      iconOnly
      icon="Eye"
      variant="text"
    />
  );

  const tableData = [
    {
      label: 'Acessar fatura do mês',
    },
  ];

  const columns = [
    { accessorKey: 'label', header: () => '', cell: cellToValue },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'end',
      headerAlign: 'end',
      cell: cellToDetails,
    },
  ];

  return (
    <UiTable
      noItemsMessage="Nenhuma transação encontrada"
      loading={loading}
      columns={columns}
      data={tableData}
      noPadding
      testId="invoice"
    />
  );
}

export default Invoices;
