import takeRequest from '~/store/takeRequest';

import * as CourierActions from './actions';

export function* courierSaga(): Generator {
  yield takeRequest(CourierActions.getCourierList);
  yield takeRequest(CourierActions.cancelCohort);
  yield takeRequest(CourierActions.createDispatchOrder);
  yield takeRequest(CourierActions.getCourierStrategies);
  yield takeRequest(CourierActions.createSegment);
  yield takeRequest(CourierActions.processSegment);
}
