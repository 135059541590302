import styled, { css } from 'styled-components';

export const CellWrapper = styled.div<{ disabled?: boolean; ellipsis?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.element.primary};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    `}
`;

export const EnrollmentsWrapper = styled.div`
  max-height: calc(100vh - 540px);
  height: 100%;
  min-height: 300px;
  padding-right: 8px;
`;

export const TitleWrapper = styled.div`
  margin-right: 4px;
`;
