import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Form } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  align-items: center;

  button {
    min-width: 170px;
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 24px;
  height: calc(100vh - 144px);
  justify-content: space-between;
  flex-wrap: nowrap;

  .select-date {
    width: 100% !important;
  }

  .select-date-button {
    background-color: ${({ theme }) => theme.colors.background.primary};
    border-radius: 8px;
    border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};

    :hover {
      background-color: ${({ theme }) => theme.colors.background.secondary};
    }
  }

  :has(.react-calendar) .select-date-button {
    border-color: ${themeColor('brand.primary')};
    background-color: ${themeColor('background.secondary')};
  }

  .ant-form-item {
    label {
      position: unset;
    }
  }

  .ant-form-item {
    .select-date-button {
      label {
        position: absolute;
      }
    }
  }

  [data-testid="cascader-select-label"] {
    position: absolute !important;
  }
`;
