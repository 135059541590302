import styled, { css } from 'styled-components';

import { Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div<{ marginBottom: boolean; marginTop: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? 12 : 0)}px;
  margin-top: ${({ marginTop }) => (marginTop ? 12 : 0)}px;

  div {
    span {
      overflow-wrap: anywhere;
    }
  }
`;

export const Label = styled(Typography).attrs(() => ({
  type: 'paragraphSmall',
  weight: 700,
  element: 'span',
}))``;

type WrapperType = {
  row?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  display: flex;
  flex-direction: column;

  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
      gap: 16px;
    `}

  button {
    padding: 4px;

    span {
      text-decoration: underline;
    }
  }
`;

export const InfoValue = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.neutral.primaryAlt,
}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
