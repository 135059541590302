import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';

export const ListContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutral.secondary};
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.secondaryAlt};

  display: flex;
  gap: 16px;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 14px 0 0;
    gap: 8px;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.secondaryAlt};
  padding: 8px 0;

  &:last-of-type {
    border: none;
  }

  @media (max-width: 900px) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.secondaryAlt};
    border-right: none;
    padding: 8px 24px 16px;

    &:last-of-type {
      border: none;
    }
  }
`;

export const GroupList = styled.div`
  display: flex;
  gap: 16px 24px;
  flex-wrap: wrap;
  flex: 1;
`;

export const SectionTitle = styled(Typography).attrs({
  type: 'bodySmall',
  weight: 700,
  color: 'element.secondary',
})``;

type SectionGroupType = {
  right?: boolean;
};

export const SectionGroup = styled.div<SectionGroupType>`
  display: flex;
  gap: 4px;
  flex-direction: column;

  ${({ right }) =>
    right &&
    css`
      width: clamp(115px, 20%, 100%);
    `}

  span {
    font-size: ${({ theme }) => theme.typography.types.bodySmall.size};
    line-height: ${({ theme }) => theme.typography.types.bodySmall.lineHeight};
    width: 100%;
    overflow-wrap: anywhere;

    &:nth-of-type(1) {
      color: ${({ theme }) => theme.colors.element.secondary};
    }

    &:nth-of-type(2) {
      color: ${({ theme }) => theme.colors.element.primary};
      font-weight: 600;
    }
  }
`;

export const RetriggeringPopover = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HistoryTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HistoryCell = styled.div`
  font-size: ${({ theme }) => theme.typography.types.bodyXSmall.size};
  line-height: ${({ theme }) => theme.typography.types.bodyXSmall.lineHeight};
  width: 50%;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    text-align: right;
  }
`;

export const HistoryRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0;
  justify-content: space-between;

  &:first-of-type {
    ${HistoryCell} {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.element.secondary};

      &:first-of-type {
        padding-left: 0;
      }
    }
  }
`;
