import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { SearchBar } from '~/ui/components';
import { AlertCircle, LoadingSpinner, Trash } from '~/ui/assets/icons';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getTicketResponsibles,
  removeResponsible,
  updateResponsible,
} from '~/store/tickets/actions';
import { getLoggedUser } from '~/store/user/actions';
import {
  Avatar,
  DropDown,
  Item,
  Label,
  List,
  SearchSection,
  IconWrapper,
} from './ManageResponsible.styles';

type OptionsType = {
  name: string;
  id: number;
};

interface ManageResponsibleContentProps {
  ticketId: number;
  value?: OptionsType;
}

function ManageResponsibleContent({ ticketId, value }: ManageResponsibleContentProps) {
  const [searchValue, setSearchValue] = useState('');
  const [wasFiltered, setWasFiltered] = useState(false);

  const getTicketResponsiblesControl = useAwaitControl(getTicketResponsibles);
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const removeResponsibleControl = useAwaitControl(removeResponsible);
  const updateResponsibleControl = useAwaitControl(updateResponsible);

  const loggedUser = getLoggedUserControl.result();
  const ticketResponsibles = getTicketResponsiblesControl.result();

  const isLoadingResponsibles = getTicketResponsiblesControl.isRunning();
  const isDeletingResponsible = removeResponsibleControl.isRunning();
  const isUpdatingResponsible = updateResponsibleControl.isRunning();

  // Aplicar o Clear na atualização

  const updatedOptions = useMemo(() => {
    const options = ticketResponsibles?._embedded?.responsibleList;
    if (!options) return [];

    const newOptions = [
      ...(value && value.name !== loggedUser.name
        ? [{ name: loggedUser.name, id: loggedUser.id }]
        : []),
      ...options,
    ];

    return newOptions;
  }, [ticketResponsibles, loggedUser, value]);

  const handleSelectItem = useCallback(
    (value) => {
      updateResponsibleControl.start({
        ticketId,
        responsibleId: value?.id,
        responsibleName: value?.name,
        userCreated: loggedUser.name,
      });
    },
    [ticketId, loggedUser],
  );

  const handleRemoveResponsible = useCallback(
    (userCreated) => {
      removeResponsibleControl.start({ ticketId, userCreated: userCreated ?? '' });
    },
    [ticketId],
  );

  const renderOptions = useMemo(
    () =>
      updatedOptions
        ?.filter((option) =>
          option.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
        )
        .map((option) => (
          <Item onClick={() => handleSelectItem(option)} key={option.id}>
            <Avatar small title={option.name} />

            <span>{option.name}</span>
          </Item>
        )),
    [updatedOptions],
  );

  const handleSearch = useCallback((value) => {
    if (value.length >= 3) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
        responsibleName: value,
      });
      setWasFiltered(true);
    }
  }, []);

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), []);

  const handleChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (searchValue.length === 0 && wasFiltered) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
      });
      setWasFiltered(false);
    }
  }, [searchValue, wasFiltered]);

  useEffect(() => {
    if (!ticketResponsibles) {
      getTicketResponsiblesControl.start({ page: 0, size: 50, sort: 'string' });
    }
  }, []);

  return (
    <DropDown>
      <SearchSection>
        <SearchBar onChange={handleChange} />
      </SearchSection>

      <List>
        {value && value?.name && (
          <Item spaceBetween>
            <Label>
              <Avatar small title={value.name} />
              <span>{value.name}</span>
            </Label>
            {!isDeletingResponsible && (
              <Trash onClick={() => handleRemoveResponsible(value.name)} />
            )}
            {isDeletingResponsible && <LoadingSpinner />}
          </Item>
        )}
        {isLoadingResponsibles && (
          <IconWrapper>
            <LoadingSpinner />
          </IconWrapper>
        )}
        {renderOptions}
        {!updatedOptions.length && !isLoadingResponsibles && 
          <IconWrapper>
            <AlertCircle width={20} height={20} />
            <span>Nenhuma opção disponível!</span>
          </IconWrapper>
        }
      </List>
    </DropDown>
  );
}

export default memo(ManageResponsibleContent);
