import React, { memo, useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import alert from '~/assets/images/alert.png';
import { Typography, Button } from '~/ui/components';
import { cancelCohort } from '~/store/courier/actions';
import { Content, Row, Wrapper } from './CancelCohort.styles';

interface CancelCohortProps {
  cohortId: number;
}

function CancelCohort({ cohortId }: CancelCohortProps) {
  const { closeModal } = useModal();

  const cancelCohortControl = useAwaitControl(cancelCohort);
  const isCanceling = cancelCohortControl.isRunning();
  const isSuccessfullyCanceled = cancelCohortControl.isSuccessful();

  const handleOnCancel = useCallback((id) => {
    cancelCohortControl.start({ id });
  }, []);

  useEffect(() => {
    if (isSuccessfullyCanceled) {
      closeModal('cancel-cohort-modal');
    }
  }, [isSuccessfullyCanceled]);

  return (
    <Wrapper>
      <img width={160} height={116} src={alert} alt="Alert" />
      <Content>
        <Typography type="bodyLarge" weight={600} element="p">
          Deseja mesmo cancelar os envios dessa lista?
        </Typography>
        <Typography type="paragraphSmall" element="span">
          Essa ação vai cancelar todos os próximos envios
        </Typography>
      </Content>
      <Row>
        <Button
          variant="outline"
          rounded
          size="md"
          onClick={() => closeModal('cancel-cohort-modal')}
        >
          Não desta vez
        </Button>
        <Button
          variant="contained"
          rounded
          size="md"
          loading={isCanceling}
          onClick={() => handleOnCancel(cohortId)}
        >
          Quero cancelar
        </Button>
      </Row>
    </Wrapper>
  );
}

export default memo(CancelCohort);
