import { AGREEMENT } from '~/common/constants';
import { LOAN_TYPES } from '~/typings/enums/LoanType';

const FIELD_TYPES = {
  basic: ['name', 'motherName', 'document', 'birthDate'],
  address: ['zipCode', 'street', 'addressNumber', 'district', 'city', 'state'],
  bank: ['bankNumber', 'checkingAccount', 'checkingAccountDigit', 'agency', 'agencyDigit'],
  registration: ['registrationNumber', 'registerNumberEmitter', 'registerNumberState', 'registerNumberDateOfIssue'],
  personal: ['maritalStatus', 'gender', 'nationality', 'cityOfBirth', 'stateOfBirth'],
};

export const FIELDS_RULES = [
  {
    rule: { agreementId: AGREEMENT.CONSIGPRIV },
    fields: [...FIELD_TYPES.basic, ...FIELD_TYPES.address, ...FIELD_TYPES.bank],
  },
  {
    rule: { loanType: LOAN_TYPES.FGTS_NEW },
    fields: [...FIELD_TYPES.basic, ...FIELD_TYPES.address, ...FIELD_TYPES.bank],
  },
  {
    rule: { loanType: [LOAN_TYPES.CONSIGNED_CARD_RMC, LOAN_TYPES.BENEFIT_CARD_RCC] },
    fields: [...FIELD_TYPES.basic, ...FIELD_TYPES.registration, ...FIELD_TYPES.personal],
  },
];