import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { Alert, Button, Tag, Typography } from '~/ui/components';
import { Card as Target } from '~/ui/assets/icons';
import { getInvoiceDetails } from '~/store/cards/actions';
import toMoney from '~/common/masked/toMoney';
import { useDrawer } from '~/hooks/useDrawer';
import { InvoiceStatusColors, InvoiceTransactionTypes } from '~/typings/enums/Card';
import {
  Card,
  Content,
  Info,
  Date,
  Section,
  SummaryPanel,
  Wrapper,
  Input,
} from './InvoiceDetails.styles';
import InvoiceDetailsLoading from './InvoiceDetailsLoading';

type InvoiceDetailsProps = {
  document: string;
};

function InvoiceDetails({ document }: InvoiceDetailsProps) {
  const [isCopied, setIsCopied] = useState(false);
  const { closeAllDrawers } = useDrawer();

  const getInvoiceControl = useAwaitControl(getInvoiceDetails);

  const invoiceDetails = getInvoiceControl.result();
  const invoiceDetailsLoading = getInvoiceControl.isRunning();
  const invoiceDetailsError = getInvoiceControl.hasFailure();

  const transactions = invoiceDetails?.transactions;

  const formatDate = (date: string, format = 'DD MMM[.] YYYY') => moment(date).format(format);
  const formatHour = (hour) => (hour.length > 5 ? hour.slice(0, 5) : hour);

  const handleCopy = useCallback((textToCopy) => {
    const clipBoard = navigator.clipboard;
    clipBoard.writeText(textToCopy).then(
      () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      },
      () => {
        message.error('Não foi possível copiar o texto');
      },
    );
  }, []);

  useEffect(() => {
    getInvoiceControl.start({ document });
  }, [document]);

  useEffect(() => {
    if (invoiceDetailsError) {
      closeAllDrawers();
    }
  }, [invoiceDetailsError]);

  if (invoiceDetailsLoading) return <InvoiceDetailsLoading />;

  return (
    <Wrapper>
      <Content position="space-between">
        <Section>
          <Typography type="caption">
            Vencimento - <b>{moment(invoiceDetails?.duoDate).format('DD/MM/YYYY')}</b>
          </Typography>
          <Content>
            <Typography type="caption">Status da fatura</Typography>
            <Tag
              small
              rounded
              bgColor={InvoiceStatusColors[invoiceDetails?.invoiceStatus]?.bg}
              textColor={InvoiceStatusColors[invoiceDetails?.invoiceStatus]?.text}
            >
              {InvoiceStatusColors[invoiceDetails?.invoiceStatus]?.label}
            </Tag>
          </Content>
        </Section>
        <Section>
          <Typography type="caption">Valor da fatura</Typography>
          <Typography type="paragraphLarge" weight={700}>
            {toMoney(invoiceDetails?.invoicePaymentValue)}
          </Typography>
        </Section>
      </Content>
      <SummaryPanel>
        <Info>
          <Typography type="caption">Gastos totais da fatura</Typography>
          <Typography type="caption">{toMoney(invoiceDetails?.invoiceTotalValue)}</Typography>
        </Info>
        <Info>
          <Typography type="caption">Desconto previsto no benefício</Typography>
          <Typography type="caption">{toMoney(invoiceDetails?.discount)}</Typography>
        </Info>
        <Info>
          <Typography type="caption">Valor restante à pagar</Typography>
          <Typography type="caption">{toMoney(invoiceDetails?.invoicePaymentValue)}</Typography>
        </Info>
      </SummaryPanel>
      <Alert status="info" fullWidth>
        Copie o código, em seguida cole e envie para o cliente.
      </Alert>
      <Input>
        <Typography type="bodyXSmall">{invoiceDetails?.paymentBarcodeLine}</Typography>
      </Input>
      <Button
        onClick={() => handleCopy(invoiceDetails?.paymentBarcodeLine)}
        variant="outline"
        rounded
        data-testid="barcode-copy-button"
        size="sm"
        disabled={isCopied}
      >
        Copiar código de barras
      </Button>
      <Typography type="bodyLarge" weight={700}>
        Lançamentos
      </Typography>
      {transactions?.map((transaction, index) => {
        const currentDate = formatDate(transaction?.transactionDate);
        const paddingBottom =
          transactions?.[index + 1] &&
          formatDate(transactions?.[index + 1]?.transactionDate) !== currentDate;
        let prevDate = '';

        if (index > 0) {
          prevDate = formatDate(transactions[index - 1]?.transactionDate);
        }

        return (
          <React.Fragment key={index}>
            {prevDate !== currentDate && (
              <Content position="center">
                <Date>{currentDate}</Date>
              </Content>
            )}
            <Content alignCenter pb={paddingBottom}>
              <Target width={24} height={24} />
              <Card pt={index !== 0 && prevDate === currentDate}>
                <Section>
                  <Typography type="bodyLarge" weight={600}>
                    {transaction?.businessName}
                  </Typography>
                  <Info>
                    <Typography type="paragraphSmall">
                      {InvoiceTransactionTypes?.[transaction?.transactionType] ||
                        transaction?.transactionType}
                    </Typography>
                    <Typography type="caption" color="element.secondary">
                      às {formatHour(transaction?.transactionHour)}
                    </Typography>
                  </Info>
                  <Info>
                    {transaction.installmentPlan > 1 ? (
                      <Typography type="paragraphSmall" weight={700}>
                        Parcela {transaction.installment} de {transaction.installmentPlan}
                      </Typography>
                    ) : (
                      <Typography type="bodyMedium" color="element.secondary" weight={700}>
                        À vista
                      </Typography>
                    )}
                    <Typography type="paragraphSmall" weight={700}>
                      {toMoney(transaction?.buyValue)}
                    </Typography>
                  </Info>
                </Section>
              </Card>
            </Content>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
}

export default InvoiceDetails;
