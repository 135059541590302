import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type FlexProps = HTMLAttributes<HTMLDivElement> & {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: number;
  grow?: number;
  shrink?: number;
  basis?: string;
  width?: string | number;
  height?: string | number;
  scroll?: 'x' | 'y' | 'both';
  ph?: number; // padding horizontal
  pv?: number; // padding vertical
  pt?: number; // padding top
  pb?: number; // padding bottom
  pl?: number; // padding left
  pr?: number; // padding right
  mt?: number; // margin top
  mb?: number; // margin bottom
  ml?: number; // margin left
  mr?: number; // margin right
  mh?: number; // margin horizontal
  mv?: number; // margin vertical
  padding?: string;
  margin?: string;
  radius?: number;
  bg?: string;
};

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  gap: ${({ gap }) => gap || 0}px;
  flex-grow: ${({ grow }) => grow || 0};
  flex-shrink: ${({ shrink }) => shrink || 0};
  flex-basis: ${({ basis }) => basis || 'auto'};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width || '100%')};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height || 'auto')};
  overflow-x: ${({ scroll }) => (scroll === 'x' || scroll === 'both' ? 'auto' : 'visible')};
  overflow-y: ${({ scroll }) => (scroll === 'y' || scroll === 'both' ? 'auto' : 'visible')};
  padding: ${({ padding, ph, pv, pt, pb, pl, pr }) =>
    padding || `${pv || pt || 0}px ${ph || pr || 0}px ${pv || pb || 0}px ${ph || pl || 0}px`};
  margin: ${({ margin, mh, mv, mt, mb, ml, mr }) =>
    margin || `${mv || mt || 0}px ${mh || mr || 0}px ${mv || mb || 0}px ${mh || ml || 0}px`};
  border-radius: ${({ radius }) => radius || 0}px;
  background: ${({ bg }) => bg || 'transparent'};
`;

export function Flex(props: FlexProps) {
  return <StyledFlex {...props} />;
}
