import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Form, Typography } from '~/ui/components';

type WrapperType = {
  center?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  button {
    min-width: fit-content;
  }

  button:nth-of-type(2) {
    padding-inline: 4px;
  }

  ${({ center }) =>
    center &&
    css`
      align-items: center;

      span {
        text-align: center;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;

  @media (max-width: 1170px) {
    flex-direction: column;
    align-items: start;
  }

  .ant-form-item {
    width: fit-content;
  }

  .input-list {
    flex-direction: row;
    width: fit-content;
  }
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 500,
  color: themeToggleColor(theme, 'element.secondary'),
  element: 'span',
}))``;
