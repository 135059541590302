import React from 'react';
import { Flex, Typography } from '~/ui/components';
import { Update } from './Title.styles';

type TitleProps = {
  onUpdate?: () => void;
  isUpdateDisabled?: boolean;
};

function Title({ onUpdate, isUpdateDisabled }: TitleProps) {
  return (
    <Flex ph={16} pv={24} justify="space-between">
      <Typography type="headingH6" weight={600}>
        Gestão de tickets
      </Typography>
      <Update onClick={onUpdate} disabled={isUpdateDisabled}>
        Atualizar
      </Update>
    </Flex>
  );
}

export default Title;
