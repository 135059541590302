import React, { memo, useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import alert from '~/assets/images/alert.png';
import { Typography, Button } from '~/ui/components';
import { createDispatchOrder } from '~/store/courier/actions';
import { Content, Row, Wrapper } from './ConfirmOrder.styles';

interface ConfirmOrderProps {
  order: {
    cohortMethod: string;
    strategy: string;
    group: string;
  };
}

function ConfirmOrder({ order }: ConfirmOrderProps) {
  const { closeModal } = useModal();

  const payload = { cohortMethod: order.cohortMethod, strategy: order.strategy };

  const createDispatchOrderControl = useAwaitControl(createDispatchOrder);
  const orderSuccessfullyCreated = createDispatchOrderControl.isSuccessful();

  const handleConfirmSubmit = useCallback((payload) => {
    createDispatchOrderControl.start(payload);
  }, []);

  useEffect(() => {
    if (orderSuccessfullyCreated) {
      closeModal('load-list-modal');
    }
  }, [orderSuccessfullyCreated]);

  return (
    <Wrapper>
      <img width={160} height={116} src={alert} alt="Alert" />
      <Content>
        <Typography type="bodyLarge" weight={600} element="p">
          Deseja mesmo criar a lista do grupo {order.group} da estratégia {order.strategy}?
        </Typography>
      </Content>
      <Row>
        <Button variant="outline" rounded size="md" onClick={() => closeModal('load-list-modal')}>
          Cancelar
        </Button>
        <Button variant="contained" rounded size="md" onClick={() => handleConfirmSubmit(payload)}>
          Carregar lista
        </Button>
      </Row>
    </Wrapper>
  );
}

export default memo(ConfirmOrder);
