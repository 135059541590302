import styled, { css } from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

interface Disabled {
  disabled?: boolean;
}

export type GridProps = {
  fullWidth?: boolean;
  grid?: string | number;
  responsive?: { [key: number]: string | number };
};


function calculateGrid(columns: number) {
  if (!columns) return '20% 20% 20% 20%'
  if (typeof columns === 'string') return columns;
  const gap = (34 / columns) * (columns - 1);
  return `calc(${100 / columns}% - ${gap}px)`.repeat(columns);
}

export const Container = styled.div<GridProps>`
  ${({ grid }) => css`
    display: grid;
    grid-template-columns: ${calculateGrid(grid)};
    gap: 34px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    a {
      text-decoration: underline;
    }

    ${({ responsive }) =>
      Object.keys(responsive || {})
        .reverse()
        .map(
          (key) => css`
            @media (max-width: ${key}px) {
              grid-template-columns: ${calculateGrid(responsive[key])};
            }
          `,
        )}
  `}
`;

export const Group = styled.div<Disabled>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const GroupName = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
  weight: 500,
})`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;

export const GroupValue = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: theme.colors.element.secondary,
  element: 'div',
}))`
  display: flex;
  align-items: center;
  gap: 4px;
  word-break: break-word;
`;
