import React from 'react';

import { Select } from 'antd';
import { useAwaitControl } from 'react-redux-await-control';

import { SearchSelect } from '~/common';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';

type BankSelectProps = {
  onChange: any;
};

export default function BankSelect({ onChange, ...props }: BankSelectProps) {
  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);

  const banks = getFinancialInstitutionsControl.result();

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      allowClear
      placeholder="Selecione"
      filterOption={(input, option) => SearchSelect(input, option.props.children)}
      optionFilterProp="children"
      onChange={onChange}
      {...props}
    >
      {banks.map((item) => (
        <Select.Option key={item.bankNumber}>{item.name}</Select.Option>
      ))}
    </Select>
  );
}
