import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Content = styled.div<{ marginBottom: boolean; marginTop: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? 12 : 0)}px;
  margin-top: ${({ marginTop }) => (marginTop ? 12 : 0)}px;

  div {
    span {
      overflow-wrap: anywhere;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: themeToggleColor(theme, 'element.secondary'),
  element: 'span',
}))``;
