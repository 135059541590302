import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAwaitControl } from 'react-redux-await-control';
import { withRouter } from 'react-router';

import { compose } from 'recompose';
import { Col, Form, Input, Row, Select } from 'antd';

import Masked from '~/common/masked';

import { OldActions as MenuAction, Modal } from '~/components';
import { SearchSelect } from '~/common';
import BankSelect from '~/components/BankSelect';
import { getContractDetails, resendToPayment, newResendToPayment } from '~/store/contracts/actions';
import LoanTypes from '~/constants/LoanTypes';
import isEmpty from 'lodash/isEmpty';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';
import { AGREEMENT } from '../../../common/constants';

const { Item: FormItem } = Form;
const { toDigit } = Masked;
const { Option } = Select;

const accountTypes = [
  {
    label: 'Conta corrente',
    value: 'CHECKING_ACCOUNT',
  },
  {
    label: 'Conta poupança',
    value: 'SAVINGS_ACCOUNT',
  },
];

function getFields(details) {
  return {
    agency: details.agency,
    agencyDigit: isEmpty(details.agencyDigit) ? '0' : details.agencyDigit,
    checkingAccount: details.checkingAccount,
    checkingAccountDigit: isEmpty(details.checkingAccountDigit)
      ? '0'
      : details.checkingAccountDigit,
    accountType: details.accountType,
    bankNumber: details.bankNumber,
  };
}

const availableSteps = [
  'FGTS_NEW_PAYMENT_RETURNED',
  'REF_PORT_PAYMENT_RETURNED',
  'PAYMENT_RETURNED',
];

function UpdateBankAccount({ form, match, kycValidation }) {
  const getContractDetailsControl = useAwaitControl(getContractDetails);
  const isSuccess = getContractDetailsControl.isSuccessful();
  const details = getContractDetailsControl.result();

  const getFinancialInstitutionsControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);

  const { id } = match.params;
  const { getFieldDecorator } = form;

  const [showModal, setShowModal] = useState(false);
  const [fields, setFields] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setFields(isSuccess ? getFields(details) : null);
    if (isSuccess) {
      form.setFieldsValue(getFields(details));
    }
  }, [details, isSuccess, details]);

  const fetchFinancialInstitutions = useCallback((contractId) => {
    getFinancialInstitutionsControl.start({ contractId });
  }, []);

  const onPressOpen = useCallback(() => {
    setShowModal(true);

    fetchFinancialInstitutions(id);
  }, [id]);

  const onPressClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const onChange = (field, type) => (event) => {
    if (
      type === 'number' &&
      (Number.isNaN(Number(event.target.value)) || event.target.value === null)
    )
      return;
    const value = event && event.target ? event.target.value : event;
    setFields({ ...fields, [field]: value });
  };

  const normalizeFields = (fields) => ({
    ...fields,
    agencyDigit:
      fields.agencyDigit === null || !fields.agencyDigit.trim().length ? '0' : fields.agencyDigit,
  });

  const onSubmit = useCallback(() => {
    const { loanType, person, financialInstitution: institution } = details;
    const resendToPaymentAction =
      loanType === LoanTypes.NEW &&
      person?.agreementName === 'INSS' &&
      institution.bankNumber === '329'
        ? newResendToPayment
        : resendToPayment;
    dispatch(resendToPaymentAction.start(normalizeFields({ contractId: id, ...fields })));
    onPressClose();
  }, [dispatch, details, fields, id, onPressClose]);

  const needKycValidation = useMemo(
    () => {
      const validationException = details?.loanType === LOAN_TYPES.FUTUREMARGIN || details?.person?.agreementId === AGREEMENT.CONSIGPRIV;
      return validationException ? false : kycValidation
    },
    [details, kycValidation],
  );

  if (!isSuccess || !availableSteps.includes(details.operationStep) || needKycValidation) {
    return null;
  }

  return (
    <>
      <MenuAction onPress={onPressOpen} title="Reenviar para pagamento" />
      <Modal
        ariaHideApp={false}
        keyboard
        enableFooter
        contentLabel="ResendBankAccount"
        visible={showModal}
        onCancel={onPressClose}
        okText="Atualizar e reenviar"
        onOk={onSubmit}
        onClick={onSubmit}
        title="Atualizar e reenviar para pagamento"
      >
        {/* <Spinner spinning={isLoading} /> */}
        <Form>
          <Row>
            <Col xs={{ span: 24 }}>
              <Col xs={{ span: 13 }}>
                <span>Banco</span>
                <FormItem>
                  {getFieldDecorator('bankNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione o banco',
                      },
                    ],
                  })(<BankSelect onChange={onChange('bankNumber')} />)}
                </FormItem>
              </Col>
              <Col xs={{ span: 10, offset: 1 }}>
                <span>Tipo da conta</span>
                <FormItem>
                  {getFieldDecorator('accountType', {
                    rules: [
                      {
                        required: true,
                        message: 'Digite o número do banco',
                      },
                    ],
                  })(
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Selecione o tipo de conta"
                      onChange={onChange('accountType')}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => SearchSelect(input, option.props.children)}
                    >
                      {accountTypes.map((item) => (
                        <Option key={item.value}>{item.label}</Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Col>
            <Col xs={{ span: 24 }}>
              <span>Agência</span>
              <Col xs={{ span: 24 }}>
                <Col xs={{ span: 19 }}>
                  <FormItem>
                    {getFieldDecorator('agency', {
                      normalize: (value) => toDigit(value?.trim()),
                      rules: [
                        {
                          required: true,
                          message: 'Digite a agência',
                        },
                      ],
                    })(
                      <Input
                        onChange={onChange('agency')}
                        placeholder="Digite a agência do banco"
                      />,
                    )}
                  </FormItem>
                </Col>
                <Col xs={{ span: 4, offset: 1 }}>
                  <FormItem>
                    {getFieldDecorator('agencyDigit', {
                      normalize: (value) => toDigit(value?.trim().charAt(0)),
                      rules: [
                        {
                          required: true,
                          message: 'Digite o dígito da agência',
                        },
                      ],
                    })(
                      <Input
                        onChange={onChange('agencyDigit', 'number')}
                        placeholder="Dígito da agência"
                      />,
                    )}
                  </FormItem>
                </Col>
              </Col>
            </Col>
            <Col xs={{ span: 24 }}>
              <span>Conta</span>
              <Col xs={{ span: 24 }}>
                <Col xs={{ span: 19 }}>
                  <FormItem>
                    {getFieldDecorator('checkingAccount', {
                      normalize: (value) => toDigit(value?.trim()),
                      rules: [
                        {
                          required: true,
                          message: 'Digite a conta',
                        },
                      ],
                    })(
                      <Input onChange={onChange('checkingAccount')} placeholder="Digite a conta" />,
                    )}
                  </FormItem>
                </Col>
                <Col xs={{ span: 4, offset: 1 }}>
                  <FormItem>
                    {getFieldDecorator('checkingAccountDigit', {
                      normalize: (value) => toDigit(value?.trim().charAt(0)),
                      rules: [
                        {
                          required: true,
                          message: 'Digite o dígito da conta',
                        },
                      ],
                    })(
                      <Input
                        onChange={onChange('checkingAccountDigit')}
                        placeholder="Dígito da conta"
                      />,
                    )}
                  </FormItem>
                </Col>
              </Col>
            </Col>
            <Col xs={{ span: 24 }}>
              <span>Comentário</span>
              <Col xs={{ span: 24 }}>
                <Input.TextArea onChange={onChange('comment')} />
              </Col>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default compose(withRouter, Form.create())(UpdateBankAccount);
