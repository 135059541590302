import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

import { Button } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 16px;
  gap: 12px;

  @media (max-width: 1260px) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 8px;

  > button {
    width: max-content;
  }
`;

export const Box = styled.div`
  position: relative;

  .select-date {
    width: 280px !important;
  }

  .select-date-button {
    background-color: ${({ theme }) => theme.colors.background.primary};
    border-radius: 8px;
    border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};

    :hover {
      background-color: ${({ theme }) => theme.colors.background.secondary};
    }
  }

  :has(.react-calendar) .select-date-button {
    border-color: ${themeColor('brand.primary')};
    background-color: ${themeColor('background.secondary')};
  }

  label, .ant-cascader-picker, .ant-cascader-picker-label {
    font-size: 14px !important;
  }
`;

export const SearchWrapper = styled.div`
  width: -webkit-fill-available;
  max-width: 260px;

  div {
    background-color: white;
    border-width: 1px;
    height: 56px;
    border-radius: 8px;
  }
`;

export const MoreFilters = styled(Button)`
  border-radius: 8px;
  padding: 0px 18px;

  span {
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;
