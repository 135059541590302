import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Button, Form, Select } from '~/ui/components';
import { TicketPriorities } from '~/typings/enums/Ticket/TicketPriorities';
import {
  getTicketDetails,
  getTicketResolvingAreas,
  getTicketStatus,
  updateTicket,
} from '~/store/tickets/actions';

import { useModal } from '~/hooks/useModal';
import { createSelectValues } from '~/common/selectValues';
import { getLoggedUser } from '~/store/user/actions';
import { getPersonDetail } from '~/store/people/actions';
import { Container, CustomButton, Text } from './TicketUpdateModal.styled';

type TicketUpdateModalProps = {
  id: number;
};

export function TicketUpdateModal({ id }: TicketUpdateModalProps) {
  const { closeModal } = useModal();
  const getTicketAreasControl = useAwaitControl(getTicketResolvingAreas);
  const updateTicketControl = useAwaitControl(updateTicket);
  const getTicketDetailsControl = useAwaitControl(getTicketDetails);
  const loggedUserControl = useAwaitControl(getLoggedUser);
  const personControl = useAwaitControl(getPersonDetail);
  const getTicketStatusControl = useAwaitControl(getTicketStatus);

  const ticket = getTicketDetailsControl.result(id);
  const areas = getTicketAreasControl.result();
  const loggedUser = loggedUserControl.result();
  const person = personControl.result();
  const status = getTicketStatusControl.result();

  const areasLoading = getTicketAreasControl.isRunning();
  const statusLoading = getTicketStatusControl.isRunning();
  const updateTicketLoading = updateTicketControl.isRunning();
  const updateTicketSuccess = updateTicketControl.isSuccessful();

  const statusOptions = useMemo(
    () =>
      status.reduce((result, item) => {
        const { groupStatus } = item;

        if (groupStatus) {
          const group = result.find((group) => group.name === groupStatus);
          if (group) {
            group.children.push(item);
          } else {
            result.push({ id: groupStatus, name: groupStatus, children: [item] });
          }
        } else {
          result.push(item);
        }

        return result;
      }, []),
    [status],
  );

  const priorityOptions = Object.keys(TicketPriorities).map((key) => ({
    value: key,
    label: TicketPriorities[key],
  }));

  const validateValue = (value, last = false) => {
    if (Array.isArray(value)) {
      return value.length ? value[last ? value.length - 1 : 0] : null;
    }

    return value || null;
  };

  const handleOnSubmit = (values) => {
    const data = {
      loggedUser: loggedUser.name,
      priorityTicket: values.priorityTicket,
      resolvingAreaId: validateValue(values.resolvingAreaId, true),
      ticketStatusId: validateValue(values.ticketStatusId, true),
      userCreated: loggedUser?.name,
      userCreatedId: loggedUser?.id,
      contracts: ticket?.contracts || [],
    };
    updateTicketControl.start({ ticketId: id, data, personId: person.id });
  };

  const handleTicketStatus = useMemo(() => {
    const firstStatus = statusOptions.filter((status) =>
      status.steps?.includes('UPDATE_TICKET_STATUS'),
    );

    const secondStatus = statusOptions.filter((status) => status.children);
    const filteredSecondStatus = secondStatus.map((obj) => ({
      ...obj,
      children: obj.children.filter((child) => child.steps?.includes('UPDATE_TICKET_STATUS')),
    }));

    return [...firstStatus, ...filteredSecondStatus];
  }, [statusOptions]);

  const inputs = useMemo(
    () => [
      {
        id: 'ticketStatusId',
        label: 'Status do Ticket',
        initialValue: createSelectValues(statusOptions, ticket?.ticketStatusId),
        input: (
          <Select
            options={handleTicketStatus}
            placeholder="Selecione o status"
            loading={statusLoading}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
        options: {
          rules: [{ required: true, message: 'Selecione o status do ticket' }],
        },
      },
      {
        id: 'priorityTicket',
        label: 'Prioridade',
        initialValue: ticket?.priorityTicket ? [ticket?.priorityTicket] : null,
        input: <Select options={priorityOptions} placeholder="Selecione a prioridade" />,
        options: {
          rules: [{ required: true, message: 'Selecione o status do ticket' }],
        },
      },
      {
        id: 'resolvingAreaId',
        label: 'Área resolvedora',
        initialValue: createSelectValues(areas, ticket?.resolvingAreaId),
        input: (
          <Select
            options={areas}
            placeholder="Selecione a área resolvedora"
            loading={areasLoading}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
    ],
    [areas, areasLoading, statusOptions, priorityOptions, ticket],
  );

  useEffect(() => {
    if (updateTicketSuccess) {
      closeModal('ticket-update-modal');
    }

    return () => {
      updateTicketControl.clear();
    };
  }, [updateTicketSuccess]);

  useEffect(() => {
    if (!areas?.length) getTicketAreasControl.start();
    getTicketStatusControl.start();
  }, []);

  return (
    <Container>
      <Text>
        Se você é uma pessoa autorizada para essa funcionalidade, preencha pelo menos um dos três
        campos abaixo:
      </Text>

      <Form inputs={inputs} onSubmit={handleOnSubmit} loading={updateTicketLoading ? 1 : 0}>
        <CustomButton fullWidth rounded>
          Atualizar
        </CustomButton>
      </Form>
    </Container>
  );
}
