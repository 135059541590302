import { InputProps as AntInputProps } from 'antd/lib/input';
import React, { forwardRef, useMemo, useRef, useState } from 'react';

import { InputContainer, InputLabel, StyledInput, StyledPasswordInput, StyledTextAreaInput } from './Input.styled';

export type InputProps = AntInputProps & {
  number?: boolean;
  label?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  password?: boolean;
  textArea?: boolean;
  rows?: number;
  size?: 'small' | 'normal' | 'large';
  icon?: React.ReactElement;
  disabled?: boolean;
  testId?: string;
  max?: number;
  min?: number;
};

export const Input = forwardRef(
  (
    {
      onChange,
      onBlur,
      number,
      allowClear,
      password,
      rows,
      textArea,
      size = 'large',
      icon,
      label,
      placeholder,
      testId = 'text',
      max,
      min,
      ...props
    }: InputProps,
    ref,
  ) => {
    const inputRef = useRef<any>(ref);
    const [withValue, setWithValue] = useState(!!props.value);

    const hasValue = useMemo(() => !!props.value || withValue, [props.value, withValue]);

    const setFocus = () => {
      inputRef.current.focus();
    };

    const handleOnChange = (e) => {
      const { value } = e.target;
      const maxAllowed = max ?? Infinity;

      if (number) {
        const reg = /^-?[0-9]*(\.[0-9]*)?$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
          if (value === '' || value === '-') {
            onChange?.(value);
            return;
          }

          let numericValue = Number(value);

          if (numericValue < min) {
            numericValue = min;
          }

          if (numericValue > maxAllowed) {
            numericValue = maxAllowed;
          }

          onChange?.(numericValue);
        }
      } else {
        onChange?.(value);
      }

      setWithValue(!!value);
    };

    const handleOnBlur = (e) => {
      const { value } = e.target;

      if (number) {
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.' || value === '-') {
          valueTemp = value.slice(0, -1);
        }

        if (valueTemp < min) valueTemp = min;
        if (valueTemp > max) valueTemp = max;

        onChange?.(valueTemp.replace(/0*(\d+)/, '$1'));
      }

      onBlur?.();
    };

    let InputComponent = StyledInput;

    if (password) {
      InputComponent = StyledPasswordInput;
    } else if (textArea) {
      InputComponent = StyledTextAreaInput;
    }

    return (
      <InputContainer>
        <InputLabel onClick={setFocus} withValue={hasValue} withIcon={!!icon} data-testid={`${testId}-input-label`}>
          {label || placeholder}
        </InputLabel>

        <InputComponent
          ref={inputRef}
          size={size}
          isDisabled={props.disabled}
          rows={rows}
          {...props}
          placeholder={null}
          allowClear={allowClear}
          className={`${allowClear ? 'allow-clear' : ''} ${props.className || ''}`}
          autoComplete="off"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          prefix={icon}
          withValue={hasValue}
          data-testid={`${testId}-input`}
          min={min}
          max={max}
        />
      </InputContainer>
    );
  },
);
