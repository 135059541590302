import React from 'react';

import {
  InfoSection,
  ListContainer,
  SectionTitle,
  SectionGroup,
  GroupList,
} from './ListInfo.styled';
import { ListType } from '../List/List';

export type ListInfoProps = {
  info: ListType;
};

export function ListInfo({ info }: ListInfoProps) {
  return (
    <ListContainer>
      <InfoSection right>
        <SectionTitle>Informaçõs da lista</SectionTitle>

        <GroupList>
          <SectionGroup>
            <span>Grupo</span>
            <span>{info?.groupName ?? '-'}</span>
          </SectionGroup>

          <SectionGroup>
            <span>Estratégia</span>
            <span>{info?.strategyName ?? '-'}</span>
          </SectionGroup>

          <SectionGroup>
            <span>Volume disponível</span>
            <span>{info?.totalCohortSize ?? '-'}</span>
          </SectionGroup>

          <SectionGroup>
            <span>Lista de dados</span>
            <span>{info?.cohortMethod ?? '-'}</span>
          </SectionGroup>
        </GroupList>
      </InfoSection>

      <InfoSection>
        {info?.children?.length > 0 && (
          <>
            <SectionTitle>Informações dos disparos</SectionTitle>
            <GroupList>
              <SectionGroup>
                <span>Total de Segmentos</span>
                <span>{info?.children?.length}</span>
              </SectionGroup>

              <SectionGroup>
                <span>Volumes enviados</span>
                <span>{info?.children?.reduce((acc, item) => acc + item.segmentSize, 0)}</span>
              </SectionGroup>
            </GroupList>
          </>
        )}
      </InfoSection>
    </ListContainer>
  );
}
