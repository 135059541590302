export enum ProposalsStatus {
  PENDING_CTPS = 'PENDING_CTPS',
  REJECTED_MAQUINA_CALCULO = 'REJECTED_MAQUINA_CALCULO',
  INCLUDED_CTPS = 'INCLUDED_CTPS',
  REJECTED_MOTOR_CREDITO = 'REJECTED_MOTOR_CREDITO',
  CONTRACTED = 'CONTRACTED',
  PARTIAL_APPROVED = 'PARTIAL_APPROVED',
  PENDING_CONTRACT = 'PENDING_CONTRACT',
  APPROVED = 'APPROVED',
  REJECTED_BY_JOB_CODE_VALIDATION = 'REJECTED_BY_JOB_CODE_VALIDATION',
  REJECT_MOTOR_CREDITO = 'REJECT_MOTOR_CREDITO',
}

export const ProposalStatusNames = {
  [ProposalsStatus.REJECTED_MAQUINA_CALCULO]: 'Sem oferta',
  [ProposalsStatus.REJECTED_MOTOR_CREDITO]: 'Sem oferta',
  [ProposalsStatus.REJECT_MOTOR_CREDITO]: 'Sem oferta',
  [ProposalsStatus.REJECTED_BY_JOB_CODE_VALIDATION]: 'Sem oferta',
  [ProposalsStatus.PENDING_CTPS]: 'Pendente CTPS',
  [ProposalsStatus.INCLUDED_CTPS]: 'Incluída CTPS',
  [ProposalsStatus.PARTIAL_APPROVED]: 'Parcialmente aprovado',
  [ProposalsStatus.APPROVED]: 'Oferta disponível',
  [ProposalsStatus.CONTRACTED]: 'Averbado',
  [ProposalsStatus.PENDING_CONTRACT]: 'Requerido'
}

export const ProposalStatusMessages = {
  [ProposalsStatus.REJECTED_MAQUINA_CALCULO]: 'Oferta não disponível de acordo com políticas internas.',
  [ProposalsStatus.REJECTED_MOTOR_CREDITO]: 'Oferta não disponível de acordo com políticas internas.',
  [ProposalsStatus.REJECT_MOTOR_CREDITO]: 'Oferta não disponível de acordo com políticas internas.',
  [ProposalsStatus.REJECTED_BY_JOB_CODE_VALIDATION]: 'Oferta não disponível de acordo com políticas internas.',
  [ProposalsStatus.PENDING_CTPS]: 'Proposta criada com sucesso, mas falta incluir na CTPS.',
  [ProposalsStatus.INCLUDED_CTPS]: 'Proposta criada com sucesso, incluída na CTPS.',
  [ProposalsStatus.PARTIAL_APPROVED]: 'Cliente precisa finalizar contratação',
  [ProposalsStatus.APPROVED]: 'Proposta gerada com sucesso',
  [ProposalsStatus.CONTRACTED]: 'Contrato averbado com sucesso.',
  [ProposalsStatus.PENDING_CONTRACT]: 'Cliente iniciou o processo de formalização.'
}

export const PartialApprovedStatusMessages = {
  CTPS: 'Cliente precisa finalizar contratação na CTPS.',
  APP: 'Cliente precisa finalizar contratação no app meutudo.',
}

export const ProposalStatusColors = {
  [ProposalsStatus.REJECTED_MAQUINA_CALCULO]: {
    bgColor: 'negative.secondary',
    textColor: 'negative.primaryAlt',
  },
  [ProposalsStatus.REJECTED_MOTOR_CREDITO]: {
    bgColor: 'negative.secondary',
    textColor: 'negative.primaryAlt',
  },
  [ProposalsStatus.PENDING_CTPS]: {
    bgColor: 'alert.secondary',
    textColor: 'alert.primaryAlt',
  },
  [ProposalsStatus.INCLUDED_CTPS]: {
    bgColor: 'positive.secondary',
    textColor: 'positive.primaryAlt',
  },
  [ProposalsStatus.PARTIAL_APPROVED]: {
    bgColor: 'alert.secondary',
    textColor: 'alert.primaryAlt',
  },
  [ProposalsStatus.CONTRACTED]: {
    bgColor: 'info.secondary',
    textColor: 'info.primaryAlt',
  },
  [ProposalsStatus.APPROVED]: {
    bgColor: 'positive.secondary',
    textColor: 'positive.primaryAlt',
  },
};
