export const COURIER_STATUS = {
  PENDING: 'Carregando',
  DISPATCHING: 'Pronto p/ enviar',
  RUNNING: 'Carregando',
  FINISHED: 'Finalizado',
  BLOCKED: 'Cancelado',
};

export const COURIER_STATUS_COLOR = {
  PENDING: 'alert',
  DISPATCHING: 'positive',
  RUNNING: 'alert',
  FINISHED: 'positive',
  BLOCKED: 'negative',
};
