import React, { useCallback, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import {
  TableColumns,
  Tag,
  CellProps,
  Popover,
  ListGroup,
  Typography,
  Button,
} from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { COURIER_STATUS, COURIER_STATUS_COLOR } from '~/typings/enums/CourierStatus';
import { DotsVertical } from '~/ui/assets/icons';
import support from '~/assets/images/support.png';
import { useDrawer } from '~/hooks/useDrawer';
import {
  cancelCohort,
  createDispatchOrder,
  getCourierList,
  processSegment,
} from '~/store/courier/actions';
import { Content, DetailButton, StyledTable, TableWrapper } from './List.styles';
import { SubmissionContent } from '../SubmissionContent/SubmissionContent';
import ReTriggers from '../ReTriggers/ReTriggers';
import { CohortDetail } from '../../drawers/CohortDetails/CohortDetails';
import CancelCohort from '../../modals/CancelCohort/CancelCohort';

export type ListType = {
  currentAvailableVolume: number;
  currentSentVolume: number;
  currentDispatch: number;
  totalCohortSize: number;
  createdAt: string;
  groupName: string;
  responsable: string;
  children: any;
  id: number;
  initialVolume: number;
  status: string;
  strategyId: number;
  strategyName: string;
  updatedAt: string;
  cohortMethod: string;
  dispatchDistance: number;
  maxDispatchesAllowed: number;
  dispatchesAllowed: number;
};

interface ListProps {
  onRowClick: (ListType) => void;
}

export function List({ onRowClick }: ListProps) {
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const theme = useTheme();

  const getCourierControl = useAwaitControl(getCourierList);
  const cancelCohortControl = useAwaitControl(cancelCohort);
  const createDispatchOrderControl = useAwaitControl(createDispatchOrder);
  const processSegmentControl = useAwaitControl(processSegment);

  const courierList = getCourierControl.result();
  const isLoading = getCourierControl.isRunning();

  const isSuccessfullyCanceled = cancelCohortControl.isSuccessful();
  const orderSuccessfullyCreated = createDispatchOrderControl.isSuccessful();
  const processedSuccessfully = processSegmentControl.isSuccessful();

  const normalizedCourierList = useMemo(
    () =>
      Array.isArray(courierList) &&
      courierList?.map((item) => ({
        ...item,
        children: item?.children.map((segment) => ({
          ...segment,
          dispatchDistance: item?.dispatchDistance,
          dispatchesAllowed: item?.dispatchesAllowed,
        })),
      })),
    [courierList],
  );

  const handleOnClickSubmission = useCallback((props: ListType) => {
    openModal(<SubmissionContent cohort={props} />, {
      title: `Enviar segmento`,
      id: 'submission-modal',
      closable: true,
      width: 540,
      className: 'max-height-modal',
    });
  }, []);

  const handleOnClickCancel = useCallback((props: ListType) => {
    openModal(<CancelCohort cohortId={props.id} />, {
      title: `Cancelar lista`,
      id: 'cancel-cohort-modal',
      closable: true,
      width: 450,
    });
  }, []);

  const renderStatus = (status) => {
    const pallete = COURIER_STATUS_COLOR[status];
    const bgColor = theme.colors?.[pallete]?.secondary;
    const textColor = theme.colors?.[pallete]?.primaryAlt;

    if (status) {
      return (
        <Tag small rounded bgColor={bgColor} textColor={textColor}>
          {COURIER_STATUS[status]}
        </Tag>
      );
    }

    return '-';
  };

  const handleOnClickId = (props: ListType) => {
    openDrawer('cohort-details', <CohortDetail cohort={props} />, {
      title: 'Detalhes do cohort',
      closable: true,
      width: 560,
    });
  };

  const renderId = (props: CellProps<ListType>) => {
    const { id, children } = props.row.original;

    if (!children) {
      return '';
    }

    return (
      <Button variant="text" color="primary" onClick={() => handleOnClickId(props.row.original)}>
        {id}
      </Button>
    );
  };

  const renderCurrentDispatch = (props: CellProps<ListType>) => {
    const {
      createdAt,
      dispatchDistance,
      currentDispatch,
      maxDispatchesAllowed,
      dispatchesAllowed,
      children,
    } = props.row.original;

    const initialDistach = 1;

    if (children) {
      return '';
    }

    return (
      <ReTriggers
        date={createdAt}
        timeGap={dispatchDistance}
        dispatchesAllowed={dispatchesAllowed - initialDistach}
        reTriggers={`${currentDispatch} / ${maxDispatchesAllowed}`}
      />
    );
  };

  const renderSubmission = (props: CellProps<ListType>) => {
    const { status, totalCohortSize, children } = props.row.original;
    const disabled = totalCohortSize === 0 || !['DISPATCHING'].includes(status);

    if (!children) {
      return '';
    }

    return (
      <DetailButton
        size="sm"
        iconOnly
        icon="CaretRight"
        customColor="brand.secondary"
        disabled={disabled}
        onClick={() => handleOnClickSubmission(props.row.original)}
      />
    );
  };

  const renderAction = (props: CellProps<ListType>) => {
    const { status, children } = props.row.original;
    const disabled = ['BLOCKED', 'FINISHED'].includes(status);

    if (!children) {
      return '';
    }

    return (
      <Popover
        width={250}
        placement="bottomLeft"
        noHeader
        trigger="hover"
        disabled={disabled}
        content={
          <ListGroup
            items={[{ id: 'cancel-cohort', label: 'Cancelar Cohort' }]}
            onClickItem={() => handleOnClickCancel(props.row.original)}
          />
        }
      >
        <DotsVertical
          width={24}
          heigth={24}
          color={disabled ? theme.colors.gray[900] : theme.colors.brand.primary}
        />
      </Popover>
    );
  };

  const columns: TableColumns<ListType> = [
    {
      accessorKey: 'id',
      header: () => 'ID',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: renderId,
    },
    {
      id: 'segment',
      header: () => 'SEGMENTOS',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => info.row.original?.children?.length ?? '',
    },
    {
      id: 'createdAt',
      header: () => 'DATA HORA',
      cell: (info) =>
        info.row.original?.createdAt
          ? moment(info.row.original?.createdAt).format('DD/MM/YY')
          : '-',
    },
    {
      id: 'updatedAt',
      header: () => 'ÚLTIMA ATUALIZAÇÃO',
      cell: (info) =>
        info.row.original?.updatedAt
          ? moment(info.row.original?.updatedAt).format('DD/MM/YY')
          : '-',
    },
    // {
    //   id: 'responsable',
    //   header: () => 'RESPONSÁVEL',
    //   cell: (info) => info.row.original?.responsable ?? '-',
    // },
    {
      id: 'groupName',
      header: () => 'GRUPO',
      cell: (info) => info.row.original?.groupName ?? '-',
    },
    {
      id: 'strategyName',
      header: () => 'ESTRATÉGIA',
      cell: (info) => info.row.original?.strategyName ?? '-',
    },
    {
      id: 'status',
      header: () => 'STATUS LISTA',
      cellAlign: 'center',
      headerAlign: 'center',
      minSize: 116,
      cell: (info) => renderStatus(info.row.original?.status),
    },
    {
      id: 'currentSentVolume',
      header: () => 'ENVIADOS',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => info.row.original?.currentSentVolume ?? '',
    },
    {
      id: 'currentDispatch',
      header: () => 'DISPAROS',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: renderCurrentDispatch,
    },
    {
      id: 'currentAvailableVolume',
      header: () => 'VOLUME DISPONÍVEL',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => info.row.original?.currentAvailableVolume ?? '',
    },
    {
      id: 'send',
      header: () => '',
      cell: renderSubmission,
    },
    {
      id: 'details',
      header: () => '',
      cell: renderAction,
    },
  ];

  useEffect(() => {
    getCourierControl.start();
  }, [isSuccessfullyCanceled, orderSuccessfullyCreated, processedSuccessfully]);

  return (
    <TableWrapper>
      {(isLoading || (Array.isArray(courierList) && courierList.length > 0)) && (
        <StyledTable
          noItemsMessage="Nenhum log encontrado"
          loading={isLoading}
          data={normalizedCourierList}
          columns={columns}
          responsiveCols={[
            'id',
            'segment',
            'createdAt',
            'groupName',
            'strategyName',
            'status',
            'currentDispatch',
            'send',
            'details',
          ]}
          onRowClick={onRowClick}
        />
      )}
      {!isLoading && !courierList?.length && (
        <Content>
          <img width={222} height={162} src={support} alt="Suporte" />
          <Typography type="subtitleSmall" weight={700}>
            Os Logs serão exibidos aqui!
          </Typography>
        </Content>
      )}
    </TableWrapper>
  );
}
