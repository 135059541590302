import takeRequest from '~/store/takeRequest';

import * as Actions from './actions';

export function* cardSaga() {
  yield takeRequest(Actions.getCardDetails);
  yield takeRequest(Actions.getTransactionHistory);
  yield takeRequest(Actions.updatePersonData);
  yield takeRequest(Actions.getInvoiceDetails);
}
