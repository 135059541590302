import React from 'react';

import { Shimmer, Tooltip } from '~/ui/components';
import { Container, GridProps, Group, GroupName, GroupValue } from './InfoGrid.styled';

export interface InfoGridItem {
  name: string;
  value: string | React.ReactElement;
  disabled?: boolean;
  copyable?: boolean;
  copyText?: string;
  info?: {
    title?: string;
    content: any;
    width?: number | string;
  };
}

type InfoGridProps = GridProps & {
  list: InfoGridItem[];
  loading?: boolean;
  shimmerLength?: number;
  fullWidth?: boolean;
};

function GridInfoShimmer({ length }: { length: number }) {
  return (
    <>
      {Array(length)
        .fill(null)
        .map((item, index) => (
          <Group key={index}>
            <GroupName>
              <Shimmer width={200} height={20} />
            </GroupName>

            <GroupValue>
              <Shimmer width={150} height={20} />
            </GroupValue>
          </Group>
        ))}
    </>
  );
}

export function InfoGrid({ list, loading, shimmerLength, ...props }: InfoGridProps) {
  if (loading) {
    return (
      <Container {...props}>
        <GridInfoShimmer length={shimmerLength || 8} />
      </Container>
    );
  }

  return (
    <Container {...props}>
      {list?.map((item) => (
        <Group key={item.name} disabled={item.disabled}>
          <GroupName>
            {item.name}{' '}
            {item?.info?.content && <Tooltip width={item.info.width || 350} {...item.info} />}
          </GroupName>

          <GroupValue copyable={item?.copyable} copyText={item?.copyText}>
            {item.value}
          </GroupValue>
        </Group>
      ))}
    </Container>
  );
}
