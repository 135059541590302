import styled from 'styled-components';

import { Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

export const Label = styled(Typography).attrs({
  type: 'caption',
  element: 'p',
  weight: 600,
})`
  margin-bottom: 6px;
  top: -3px;
`;

export const Info = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})`
  top: -3px;
`;

export const Event = styled.li<{ isConected: boolean }>`
  display: flex;
  padding-bottom: ${({ isConected }) => (isConected ? 35 : 0)}px;
  gap: 8px;
`;

export const IconContent = styled.div`
  position: relative;
  margin-right: 8px;
`;

export const Dot = styled.div<{ filled: boolean }>`
  width: 12px;
  height: 12px;
  display: block;
  background-color: ${({ theme, filled }) =>
    filled ? theme.colors.element.placeholder : theme.colors.element.invert};
  border-radius: 16px;
  border: 2px solid
    ${({ theme, filled }) =>
      filled ? theme.colors.element.placeholder : theme.colors.element.disabled};
`;

export const Line = styled.div<{ filled: boolean }>`
  position: absolute;
  top: 15px;
  left: 1px;
  width: 50%;
  border-right: 2px solid ${({ theme }) => theme.colors.divider.primary};
  border-color: ${({ isConected, filled }) => {
    if (isConected) {
      if (filled) {
        return ({ theme }) => theme.colors.element.placeholder;
      }
      return ({ theme }) => theme.colors.element.disabled;
    }
    return 'transparent';
  }};
  height: 170%;
`;
