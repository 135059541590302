import styled, { css } from 'styled-components';

type ContainerProps = {
  center?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 125px);
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  overflow: hidden;
  position: relative;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}

  * *::-webkit-scrollbar {
    width: 4px;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (max-width: 1260px) {
    max-height: 590px;
  }
`;
