import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';

import { Button } from '~/ui/components';

export const Update = styled(Button).attrs(({ theme }) => ({
  variant: 'contained',
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.secondary'),
  icon: 'Refresh',
}))`
  padding-inline: 16px;

  span,
  svg {
    color: ${({ theme }) => theme.colors.brand.primary} !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      span,
      svg {
        color: ${({ theme }) => theme.colors.element.primary} !important;
      }
    `}

  svg:nth-child(1) {
    order: 2;
    width: 16px;
    height: 16px;
  }

  span:nth-child(2) {
    order: 1;
    font-weight: 600;
  }
`;
