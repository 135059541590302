import React from 'react';
import { CellProps } from '~/ui/components';
import { Link } from 'react-router-dom';

type LinkOrFunction<TData> = string | ((props: CellProps<TData | any>) => void);

export function cellToLink<TData>(linkOrFunction: LinkOrFunction<TData>, text?: string) {
  return (props: CellProps<TData>) => {
    const value = text || props?.getValue<number | string>();

    if (typeof linkOrFunction === 'function') {
      return <div className='table-link' onClick={() => linkOrFunction?.(props)}>{value}</div>;
    }

    return <Link to={linkOrFunction}>{value}</Link>;
  };
}
