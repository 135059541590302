export default (str, searchString) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
  };

  const searchStringWithoutAccents = searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const strWithoutAccents = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const searchStringEscaped = escapeRegExp(searchStringWithoutAccents);

  return new RegExp(searchStringEscaped, 'i').test(strWithoutAccents);
};
