import React, { memo, useCallback, useMemo } from 'react';
import moment from 'moment';

import { Popover, Typography } from '~/ui/components';
import { Content, Label, Row, Wrapper } from './ReTriggers.styles';

interface ReTriggersProps {
  date: string;
  dispatchesAllowed: number;
  timeGap: number;
  reTriggers: string | number;
}

function ReTriggers({ date, dispatchesAllowed, timeGap, reTriggers }: ReTriggersProps) {
  const getNewTime = useCallback(
    (dispatchDistance) => moment(date).add(dispatchDistance, 'minutes').toISOString(),
    [date],
  );

  const renderTimeLine = (dispatchesAllowed, timeGap) => {
    const events = [];

    for (let i = 1; i <= dispatchesAllowed; i += 1) {
      events.push({
        number: i,
        time: moment(getNewTime(timeGap * i)).format('DD/MM/YYYY [às] HH:mm'),
      });
    }

    return events;
  };

  const content = useMemo(
    () => (
      <Wrapper>
        <Typography type="caption">Redisparos programados</Typography>
        <Content>
          <Row>
            <Label>Redisparo</Label>
            <Label>Data/hora</Label>
          </Row>
          {renderTimeLine(dispatchesAllowed, timeGap)?.map((item) => (
            <Row>
              <Typography type="paragraphSmall">{item.number}</Typography>
              <Typography type="paragraphSmall">{item.time}</Typography>
            </Row>
          ))}
        </Content>
      </Wrapper>
    ),
    [],
  );

  return (
    <Popover
      width={300}
      placement="bottom"
      noHeader
      trigger="hover"
      content={content}
      destroyTooltipOnHide
      disabled={false}
      noArrow
    >
      {reTriggers}
    </Popover>
  );
}

export default memo(ReTriggers);
