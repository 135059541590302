import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Form } from '~/ui/components';

export const FilterContainer = styled.div`
  padding: 32px 24px;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

export const FilterForm = styled(Form)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 372px;
  }

  .submit-button {
    width: 100%;
  }

  .extra-content {
    align-items: center;
    display: flex;
    max-width: 188px;
    width: 100%;
  }

  .ant-form-item {
    width: 100%;
    max-width: 372px;
  }
`;
