import styled from 'styled-components';
import { Button, Table } from '~/ui/components';

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.element.disabled};
  border-radius: 16px;
  min-height: 550px;

  div {
    border-radius: 16px;
    width: 100%;
  }
`;

export const DetailButton = styled(Button)`
  border-radius: 36px;

  svg {
    height: 12px !important;
    width: 12px !important;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.element.invert : theme.colors.brand.primary};
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;

  thead {
    height: 40px !important;
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.secondaryAlt} !important;

    td:first-child {
      button {
        background: none !important;
      }

      svg {
        color: ${({ theme }) => theme.colors.brand.primary} !important;
      }
    }

    &:hover {
      opacity: 1 !important;
      cursor: auto !important;
    }
  }

  // Removendo borda das linhas que possuem sub-linha
  tbody tr:has(+ .sub-row) {
    border-bottom: none !important;
  }

  // Ajustando estilização das sub-linhas
  tr.sub-row {
    background-color: transparent !important;

    td:first-child {
      svg {
        display: none;
      }
    }
  }
`;

export const Content = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;
