import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Button, SelectDate, SelectSearch } from '~/ui/components';
import { Calendar } from '~/ui/assets/icons';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getServiceChannels,
  getTicketProducts,
  getTicketReasons,
  getTicketResolvingAreas,
  getTicketResponsibles,
  getTicketStatus,
} from '~/store/tickets/actions';
import { debounce } from 'lodash';
import moment from 'moment';
import { useDrawer } from '~/hooks/useDrawer';
import { StyledForm, Wrapper } from './AllFilters.styles';
import {
  normalizeData,
  normalizedInitialData,
  normalizedInitialResolvingArea,
  normalizedInitialStatusData,
  normalizeProducts,
} from '../../common/ticket';
import { StyledSelect } from '../Actions/Actions.styles';

type FiltersProps = {
  onChange: (value: any) => void;
  initialValues: any;
  onClear: () => void;
};

function Filters({ onChange, initialValues, onClear }: FiltersProps) {
  const { closeDrawer } = useDrawer();

  const form = useRef<WrappedFormUtils>();

  const [searchReason, setSearchReason] = useState('');
  const [searchResponsible, setSearchResponsible] = useState('');
  const [wasFiltered, setWasFiltered] = useState(false);
  const [statusSearch, setStatusSearch] = useState('')

  const ticketStatusControl = useAwaitControl(getTicketStatus);
  const getTicketResponsiblesControl = useAwaitControl(getTicketResponsibles);
  const getServiceChannelsControl = useAwaitControl(getServiceChannels);
  const getTicketResolvingAreasControl = useAwaitControl(getTicketResolvingAreas);
  const getTicketReasonsControl = useAwaitControl(getTicketReasons);
  const getTicketProductsControl = useAwaitControl(getTicketProducts);

  const isLoadingResponsibles = getTicketResponsiblesControl.isRunning();
  const isLoadingResolvingAreas = getTicketResolvingAreasControl.isRunning();
  const isLoadingStatus = ticketStatusControl.isRunning();
  const isLoadingChannels = getServiceChannelsControl.isRunning();
  const isLoadingReasons = getTicketReasonsControl.isRunning();
  const isLoadingProducts = getTicketProductsControl.isRunning();

  const ticketStatus = ticketStatusControl.result();
  const ticketResponsibles = getTicketResponsiblesControl.result();
  const serviceChannels = getServiceChannelsControl.result();
  const ticketResolvingAreas = getTicketResolvingAreasControl.result();
  const ticketReasons = getTicketReasonsControl.result();
  const ticketProducts = getTicketProductsControl.result();

  const handleSubmit = useCallback((option) => {
    const updatedValue: any = {};

    Object.keys(option).forEach((key) => {
      if (option[key] && typeof option[key] === 'object' && 'value' in option[key]) {
        updatedValue[key] = option[key].value;
      } else {
        updatedValue[key] = option[key];
      }
    });

    if (updatedValue?.resolvingareaId) {
      updatedValue.resolvingareaId = option.resolvingareaId.length > 1 
        ? option.resolvingareaId[1] 
        : option.resolvingareaId
    }

    if (updatedValue?.start) {
      updatedValue.start = moment(option?.start[0]).format('YYYY-MM-DD');
      updatedValue.end = moment(option?.start[1]).format('YYYY-MM-DD');
    }

    onChange(updatedValue);
    closeDrawer('more-filters-drawer');
  }, []);

  const updatedReasons = useMemo(
    () =>
      ticketReasons?.filter((option) =>
        option.name?.toLocaleLowerCase().includes(searchReason?.toLocaleLowerCase()),
      ),
    [ticketReasons, searchReason],
  );

  const handleSearch = useCallback((value) => {
    if (value.length >= 3) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
        responsibleName: value,
      });
      setWasFiltered(true);
    }
  }, []);

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), []);

  const handleSearchResponsible = (value) => {
    setSearchResponsible(value);
    debouncedSearch(value);
  };

  const handleClear = useCallback(() => {
    closeDrawer('more-filters-drawer');
    onClear();
  }, [])

  const inputs = useMemo(
    () => [
      {
        id: 'product',
        label: 'Produto',
        initialValue: normalizedInitialData(ticketProducts, initialValues?.product),
        input: (
          <SelectSearch
            placeholder="Produto"
            fullWidth
            value={normalizedInitialData(ticketProducts, initialValues?.product)}
            searchable={false}
            options={normalizeProducts(ticketProducts)}
            loading={isLoadingProducts}
          />
        ),
      },
      {
        id: 'start',
        label: 'Filtrar por data',
        initialValue: initialValues.start ? [initialValues.start, initialValues.end] : null,
        input: (
          <SelectDate
            selectRange
            maxDate={new Date()}
            value={initialValues.start ? [initialValues.start, initialValues.end] : null}
            icon={<Calendar />}
            placeholder="Filtrar por data"
          />
        ),
      },
      {
        id: 'statusId',
        label: 'Status',
        initialValue: normalizedInitialStatusData(ticketStatus, initialValues?.statusId),
        input: (
          <SelectSearch
            placeholder="Status"
            onSearch={setStatusSearch}
            options={normalizeData(ticketStatus)?.filter((data) =>
              statusSearch ? data.label.toLowerCase().includes(statusSearch.toLowerCase().trim()) : true
            )}
            value={normalizedInitialStatusData(ticketStatus, initialValues?.statusId)}
            fullWidth
            loading={isLoadingStatus}
          />
        ),
      },
      {
        id: 'resolvingareaId',
        label: 'Área resolvedora',
        initialValue: normalizedInitialResolvingArea(ticketResolvingAreas, initialValues?.resolvingareaId) ?? [],
        input: (
          <StyledSelect
            placeholder="Área resolvedora"
            options={ticketResolvingAreas}
            loading={isLoadingResolvingAreas}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
      {
        id: 'responsibleId',
        label: 'Responsável',
        initialValue: normalizedInitialData(
          ticketResponsibles?._embedded?.responsibleList,
          initialValues?.responsibleId,
        ),
        input: (
          <SelectSearch
            placeholder="Responsável"
            fullWidth
            value={normalizedInitialData(
              ticketResponsibles?._embedded?.responsibleList,
              initialValues?.responsibleId,
            )}
            pictured
            onSearch={handleSearchResponsible}
            options={normalizeData(ticketResponsibles?._embedded?.responsibleList)}
            loading={isLoadingResponsibles}
          />
        ),
      },
      {
        id: 'reasonId',
        label: 'Motivo de abertura',
        initialValue: normalizedInitialData(updatedReasons, initialValues?.reasonId),
        input: (
          <SelectSearch
            placeholder="Motivo de abertura"
            fullWidth
            value={normalizedInitialData(updatedReasons, initialValues?.reasonId)}
            onSearch={setSearchReason}
            options={normalizeData(updatedReasons)}
            loading={isLoadingReasons}
          />
        ),
      },
      {
        id: 'channelId',
        label: 'Canal',
        initialValue: normalizedInitialData(serviceChannels, initialValues?.channelId),
        input: (
          <SelectSearch
            placeholder="Canal"
            searchable={false}
            value={normalizedInitialData(serviceChannels, initialValues?.channelId)}
            fullWidth
            options={normalizeData(serviceChannels)}
            loading={isLoadingChannels}
          />
        ),
      },
      {
        id: 'creatorId',
        label: 'Criado por',
        initialValue: normalizedInitialData(
          ticketResponsibles?._embedded?.responsibleList,
          initialValues?.creatorId,
        ),
        input: (
          <SelectSearch
            placeholder="Criado por"
            fullWidth
            value={normalizedInitialData(
              ticketResponsibles?._embedded?.responsibleList,
              initialValues?.creatorId,
            )}
            pictured
            onSearch={handleSearchResponsible}
            options={normalizeData(ticketResponsibles?._embedded?.responsibleList)}
            loading={isLoadingResponsibles}
          />
        ),
      },
    ],
    [
      serviceChannels,
      ticketResponsibles,
      ticketStatus,
      updatedReasons,
      ticketProducts,
      initialValues,
      statusSearch,
      isLoadingResponsibles,
      isLoadingResolvingAreas,
      isLoadingStatus,
      isLoadingReasons,
      isLoadingChannels,
      isLoadingProducts,
    ],
  );

  useEffect(() => {
    if (!ticketResponsibles) {
      getTicketResponsiblesControl.start({ page: 0, size: 50, sort: 'string' });
    }

    if (!serviceChannels.length) {
      getServiceChannelsControl.start();
    }

    if (!ticketResolvingAreas.length) {
      getTicketResolvingAreasControl.start();
    }

    if (!ticketReasons.length) {
      getTicketReasonsControl.start();
    }

    if (!ticketProducts.length) {
      getTicketProductsControl.start();
    }
  }, []);

  useEffect(() => {
    if (searchResponsible.length === 0 && wasFiltered) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
      });
      setWasFiltered(false);
    }
  }, [searchResponsible, wasFiltered]);

  return (
    <StyledForm ref={form} name="reset-form" onSubmit={handleSubmit} inputs={inputs}>
      <Wrapper>
        <Button 
          rounded 
          variant="outline" 
          type="button" 
          data-testid="ticket-form-clear-button" 
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button rounded type="submit" data-testid="ticket-form-button">
          Filtrar
        </Button>
      </Wrapper>
    </StyledForm>
  );
}

export default Filters;
