import React from 'react';

import { Shimmer } from '~/ui/components';
import { Card, Content, Info, Section, SummaryPanel, Wrapper } from './InvoiceDetails.styles';

function InvoiceDetailsLoading() {
  return (
    <Wrapper>
      <Content position="space-between">
        <Section style={{ width: '100%' }}>
          <Shimmer width={171} height={20} />

          <Content>
            <Shimmer width={171} height={24} />
          </Content>
        </Section>
        <Section style={{ width: '100%', alignItems: 'end' }}>
          <Shimmer width={106} height={20} />
          <Shimmer width={106} height={28} />
        </Section>
      </Content>
      <SummaryPanel>
        <Info>
          <Shimmer width={147} height={20} />
          <Shimmer width={71} height={20} />
        </Info>
        <Info>
          <Shimmer width={102} height={20} />
          <Shimmer width={71} height={20} />
        </Info>
        <Info>
          <Shimmer width={147} height={20} />
          <Shimmer width={71} height={20} />
        </Info>
      </SummaryPanel>
      <Shimmer width={508} height={44} />
      <Shimmer width={508} height={52} />
      <Shimmer width={508} height={36} />
      <Shimmer width={63} height={28} />

      <Content position="center">
        <Shimmer width={118} height={26} />
      </Content>

      <Content alignCenter pb>
        <Shimmer width={24} height={24} />
        <Card>
          <Section>
            <Shimmer width={100} height={28} />
            <Info>
              <Shimmer width={130} height={24} />
              <Shimmer width={51} height={20} />
            </Info>
            <Info>
              <Shimmer width={40} height={24} />
              <Shimmer width={60} height={24} />
            </Info>
          </Section>
        </Card>
      </Content>
    </Wrapper>
  );
}

export default InvoiceDetailsLoading;
