import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Button } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getTicketDetails,
  getTicketResolvingAreas,
  getTicketStatus,
  updateTicket,
} from '~/store/tickets/actions';
import { createSelectValues } from '~/common/selectValues';
import { TicketPriorities } from '~/typings/enums/Ticket/TicketPriorities';
import { getLoggedUser } from '~/store/user/actions';
import { getPersonDetail } from '~/store/people/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { StyledForm, StyledSelect, Wrapper } from './Actions.styles';

interface ActionsProps {
  id: number;
}

function Actions({ id }: ActionsProps) {
  const form = useRef<WrappedFormUtils>();
  const { closeDrawer } = useDrawer();

  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const getTicketDetailsControl = useAwaitControl(getTicketDetails);
  const getTicketAreasControl = useAwaitControl(getTicketResolvingAreas);
  const updateTicketControl = useAwaitControl(updateTicket);
  const loggedUserControl = useAwaitControl(getLoggedUser);
  const personControl = useAwaitControl(getPersonDetail);

  const ticket = getTicketDetailsControl.result(id);
  const areas = getTicketAreasControl.result();
  const status = getTicketStatusControl.result();
  const loggedUser = loggedUserControl.result();
  const person = personControl.result();

  const areasLoading = getTicketAreasControl.isRunning();
  const statusLoading = getTicketStatusControl.isRunning();
  const updateTicketLoading = updateTicketControl.isRunning();

  const successfullyUpdated = updateTicketControl.isSuccessful();

  const statusOptions = useMemo(
    () =>
      status.reduce((result, item) => {
        const { groupStatus } = item;

        if (groupStatus) {
          const group = result.find((group) => group.name === groupStatus);
          if (group) {
            group.children.push(item);
          } else {
            result.push({ id: groupStatus, name: groupStatus, children: [item] });
          }
        } else {
          result.push(item);
        }

        return result;
      }, []),
    [status],
  );

  const handleTicketStatus = useMemo(() => {
    const firstStatus = statusOptions.filter((status) =>
      status.steps?.includes('UPDATE_TICKET_STATUS'),
    );

    const secondStatus = statusOptions.filter((status) => status.children);
    const filteredSecondStatus = secondStatus.map((obj) => ({
      ...obj,
      children: obj.children.filter((child) => child.steps?.includes('UPDATE_TICKET_STATUS')),
    }));

    return [...firstStatus, ...filteredSecondStatus];
  }, [statusOptions]);

  const priorityOptions = Object.keys(TicketPriorities).map((key) => ({
    value: key,
    label: TicketPriorities[key],
  }));

  const validateValue = (value, last = false) => {
    if (Array.isArray(value)) {
      return value.length ? value[last ? value.length - 1 : 0] : null;
    }

    return value || null;
  };

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        loggedUser: loggedUser.name,
        priorityTicket: values.priorityTicket,
        resolvingAreaId: validateValue(values.resolvingAreaId, true),
        ticketStatusId: validateValue(values.ticketStatusId, true),
        userCreated: loggedUser?.name,
        userCreatedId: loggedUser?.id,
        contracts: ticket?.contracts || [],
      };
      updateTicketControl.start({ ticketId: id, data, personId: person.id });
    },
    [person, id, loggedUser],
  );

  const inputs = useMemo(
    () => [
      {
        id: 'ticketStatusId',
        label: 'Status',
        initialValue: createSelectValues(statusOptions, ticket?.ticketStatusId),
        input: (
          <StyledSelect
            placeholder="Status"
            options={handleTicketStatus}
            loading={statusLoading}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
      {
        id: 'priorityTicket',
        label: 'Prioridade',
        initialValue: ticket?.priorityTicket ? [ticket?.priorityTicket] : null,
        input: <StyledSelect placeholder="Prioridade" options={priorityOptions} />,
      },
      {
        id: 'resolvingAreaId',
        label: 'Área resolvedora',
        initialValue: createSelectValues(areas, ticket?.resolvingAreaId),
        input: (
          <StyledSelect
            placeholder="Área resolvedora"
            options={areas}
            loading={areasLoading}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
    ],
    [areas, areasLoading, statusOptions, priorityOptions, ticket],
  );

  useEffect(() => {
    if (!areas?.length) getTicketAreasControl.start();

    getTicketStatusControl.start();
  }, []);

  useEffect(() => {
    getTicketDetailsControl.start({ id }, { actionId: id });

    return () => {
      getTicketDetailsControl.clear();
    };
  }, [id]);

  useEffect(() => {
    if (successfullyUpdated) {
      updateTicketControl.clear();
      closeDrawer('ticket-actions');
    }
  }, [successfullyUpdated]);

  return (
    <StyledForm ref={form} name="reset-form" onSubmit={handleSubmit} inputs={inputs}>
      <Wrapper>
        <Button rounded variant="outline" type="button" data-testid="ticket-form-clear-button">
          Limpar
        </Button>
        <Button
          rounded
          type="submit"
          data-testid="ticket-form-submit-button"
          loading={updateTicketLoading}
        >
          Alterar status
        </Button>
      </Wrapper>
    </StyledForm>
  );
}

export default Actions;
