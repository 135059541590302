import styled from 'styled-components';
import { Content } from '~/ui/components';

export const Box = styled(Content)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;
`;

export const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
