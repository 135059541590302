import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';

import getInitials from '~/common/getInitials';
import masked from '~/common/masked';
import { Shimmer, Tag, Tooltip } from '~/ui/components';
import { Person, PersonRegistrationLog } from '~/typings/entities/person';
import { ProfileTags } from '~/components';
import toPhone from '~/common/masked/toPhone';
import { CancelPopover } from '~/components/ProfileResume/CancelPopover';
import { getPersonRegistrationLogs } from '~/store/people/actions';
import { formatName } from '~/common';
import { ClixClient } from '~/typings/entities/Clix';
import getAccountType from '~/typings/enums/AccountType';
import RegistrationStatus from '~/typings/enums/RegistrationStatus';
import { ArrowRightCircle, Check, Close } from '~/ui/assets/icons';
import toCPFFormat from '~/common/masked/toCPFFormat';
import {
  ProfileResumeContainer,
  ProfileInitials,
  ProfileImageContainer,
  ProfileName,
  ProfileInfo,
  ProfileData,
  ProfileInfoWithPopover,
  ExtraTagsWrapper,
  CopyButton,
  Copied,
  NameContent,
  ProfileWrapper,
  Icon,
  Bagde,
} from './ProfileResume.styled';
import { RefusedPopover } from './RefusedPopover';
import { TicketButton } from './TicketButton';

interface ProfileResumeProps {
  person: Person;
  clixDetails?: ClixClient;
  loading?: boolean;
  image?: any;
  responsiveActions?: React.ReactNode;
  className?: string;
}

export function ProfileResume({
  person,
  loading,
  image,
  responsiveActions,
  ...props
}: ProfileResumeProps) {
  const [copied, setCopied] = useState(false);

  const history = useHistory();

  const listPersonRegistrationLogControl = useAwaitControl(getPersonRegistrationLogs);

  const isPersonPage = history.location.pathname.startsWith('/backoffice/people');

  const canceledByClient = useMemo(() => person?.nameUserWhoCancel === person?.name, [person]);

  const formatDate = useCallback((dateToFormat, isDateAndHour = true) => {
    if (!dateToFormat) return 'Indisponível';

    if (isDateAndHour) {
      return moment(dateToFormat).format('DD/MM/YYYY [às] HH:mm');
    }

    return moment(dateToFormat).format('DD/MM/YYYY');
  }, []);

  const humanizeDate = useCallback((dateToFormat) => {
    if (!dateToFormat) return 'Indisponível';

    return masked.fromNow(dateToFormat);
  }, []);

  const imageOrInitials = useMemo(
    () =>
      image ? (
        <Avatar className="avatar" shape="circle" src={image} />
      ) : (
        <ProfileInitials>{getInitials(person.name)}</ProfileInitials>
      ),
    [image, person.name],
  );

  const registerTag = useMemo(() => {
    if (['REFUSED'].includes(person?.registrationStatus)) {
      return {
        label: 'Reprovado',
        badge: <Close width={13} height={13} data-testid="rejected-registration" />,
      };
    }

    if (['APPROVED', 'COMPLETE'].includes(person?.registrationStatus)) {
      return {
        label: 'Aprovado',
        badge: <Check width={9} height={9} data-testid="approved-registration" />,
      };
    }

    return null;
  }, [person]);

  const handleCopy = useCallback(() => {
    const clipBoard = navigator.clipboard;

    clipBoard.writeText(window.location.href).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  }, [window.location.href]);

  const copyButton = useMemo(
    () => (
      <Tooltip
        className="copyButton"
        content={copied ? 'COPIADO!' : 'COPIAR LINK DO CLIENTE'}
        small
      >
        {copied ? (
          <Copied data-testid="copied-check-icon" />
        ) : (
          <CopyButton data-testid="copy-button" onClick={handleCopy} />
        )}
      </Tooltip>
    ),
    [copied],
  );

  useEffect(() => {
    if (person.id) {
      listPersonRegistrationLogControl.start({ personId: person.id });
    }
  }, [person]);

  const personRegistrationLog =
    listPersonRegistrationLogControl.result() as PersonRegistrationLog[];

  return (
    <ProfileResumeContainer {...props}>
      {loading ? (
        <>
          <Shimmer circular width={88} height={88} />
          <ProfileInfo>
            <Shimmer width={220} height={20} />
            <Shimmer width={185} height={20} />
            <Shimmer width={160} height={20} />
            <Shimmer width={160} height={20} />
            <Shimmer width={100} height={20} />
          </ProfileInfo>
        </>
      ) : (
        <>
          {!responsiveActions && <TicketButton />}

          <ProfileImageContainer>
            {imageOrInitials}
            {registerTag && (
              <Tooltip content={registerTag.label} small placement="top">
                <Bagde status={registerTag.label}>{registerTag.badge}</Bagde>
              </Tooltip>
            )}
          </ProfileImageContainer>

          <ProfileWrapper>
            <ProfileInfo>
              <NameContent>
                {!isPersonPage ? (
                  <>
                    <Link to={`/backoffice/people/${person?.id}`}>
                      <ProfileName withLink>{formatName(person.name)}</ProfileName>
                    </Link>
                    <Icon>{copyButton}</Icon>
                  </>
                ) : (
                  <>
                    <ProfileName>{formatName(person.name)}</ProfileName>
                    <Icon>{copyButton}</Icon>
                  </>
                )}
              </NameContent>

              <ProfileWrapper flexWrap>
                {person?.id && (
                  <ProfileInfoWithPopover>
                    <ProfileData>Person ID:</ProfileData>
                    <ProfileData copyable>{person.id}</ProfileData>
                  </ProfileInfoWithPopover>
                )}

                {person?.registrationStatus && (
                  <ProfileData>
                    Status: {RegistrationStatus(person?.registrationStatus)}
                  </ProfileData>
                )}

                {person?.birthDate ? (
                  <ProfileData copyable>{humanizeDate(person?.birthDate)} de idade</ProfileData>
                ) : (
                  <ProfileData>Idade indisponível</ProfileData>
                )}

                {person?.document && (
                  <ProfileData copyable>{toCPFFormat(person.document)}</ProfileData>
                )}
                {person?.phone && <ProfileData copyable>{toPhone(person.phone)}</ProfileData>}

                {person?.email && (
                  <ProfileData copyable bold>
                    {person.email}
                  </ProfileData>
                )}

                {!!person?.disabled && (
                  <ProfileInfoWithPopover>
                    {canceledByClient ? (
                      'Cancelado pelo cliente'
                    ) : (
                      <>
                        Agente que reprovou: <b>{person?.nameUserWhoCancel}</b>
                      </>
                    )}
                    <CancelPopover canceledByClient={canceledByClient} person={person} />
                  </ProfileInfoWithPopover>
                )}

                {personRegistrationLog?.length > 0 && (
                  <ProfileInfoWithPopover>
                    Histórico de cadastro:
                    <RefusedPopover registrationLog={personRegistrationLog} />
                  </ProfileInfoWithPopover>
                )}

                <ProfileData>
                  <ArrowRightCircle width={16} height={16} />
                  Último acesso app/web: {formatDate(person?.lastAcess)}
                </ProfileData>
              </ProfileWrapper>
              <ExtraTagsWrapper>
                {person?.accountType === 'MAGNETIC_CARD' && (
                  <Tag small rounded>
                    {getAccountType(person?.accountType)}
                  </Tag>
                )}
              </ExtraTagsWrapper>
              <ProfileTags externalId={person?.id} type="PERSON" />
            </ProfileInfo>
            {responsiveActions}
          </ProfileWrapper>
        </>
      )}
    </ProfileResumeContainer>
  );
}
