import styled, { css } from 'styled-components';
import typography from '~/ui/theme/typography';
import { themeToggleColor } from '~/common/utils/theme';
import get from 'lodash/get';

export type TypographyTypesKeys = keyof typeof typography.types;

export type TypographyProps = {
  type?: TypographyTypesKeys;
  color?: string;
  width?: number;
  weight?: string | number;
  center?: boolean;
  fullWidth?: boolean;
};

type DefaultCssProps = {
  lineHeight?: string;
  size?: string;
  fontFamily?: string;
  copied?: boolean;
  contentEditable?: boolean;
} & TypographyProps;

type CopyButtonProps = {
  copied?: boolean;
};

export const CopyButton = styled.button<CopyButtonProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  display: none;
  align-items: center;
  justify-content: center;

  .anticon-check {
    svg {
      color: ${({ theme }) => theme.colors.success.main};
    }
  }

  ${({ copied }) =>
    copied &&
    css`
      pointer-events: none;
    `}
`;

const defaultCSS = css<DefaultCssProps>`
  color: ${({ color, theme }) =>
    get(theme.colors, color) ||
    color ||
    themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' })};
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  line-height: ${({ lineHeight }) => lineHeight ?? lineHeight};
  font-size: ${({ size }) => size ?? size};
  font-weight: ${({ weight }) => weight ?? weight};
  font-family: ${({ fontFamily }) => typography.fonts[fontFamily || typography.defaultFamily]};
  position: relative;
  margin: 0;
  padding: 0;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  &:hover {
    .copy-button {
      display: flex;
    }
  }

  ${({ copied }) =>
    copied &&
    css`
      .copy-button {
        display: flex;
      }
    `}

  ${CopyButton} {
    color: ${({ color }) => color};
  }

  ${({ contentEditable }) =>
    contentEditable &&
    css`
      outline: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.brand.primary};
    `}
`;

export const Bold = styled.b`
  font-weight: ${({ weight }) => weight ?? weight};
  color: ${({ color, theme }) =>
    get(theme.colors, color) ||
    color ||
    themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' })};
`;

export const TypographyElements = {
  h1: styled.h1`
    ${defaultCSS}
  `,
  h2: styled.h2`
    ${defaultCSS}
  `,
  h3: styled.h3`
    ${defaultCSS}
  `,
  h4: styled.h4`
    ${defaultCSS}
  `,
  h5: styled.h5`
    ${defaultCSS}
  `,
  h6: styled.h6`
    ${defaultCSS}
  `,
  p: styled.p`
    ${defaultCSS}
  `,
  div: styled.div`
    ${defaultCSS}
  `,
  span: styled.span`
    ${defaultCSS}
  `,
  label: styled.label`
    ${defaultCSS}
  `,
  caption: styled.caption`
    ${defaultCSS}
  `,
  a: styled.a`
    ${defaultCSS}
  `,
  small: styled.small`
    ${defaultCSS}
  `,
};

export type TypographyElementsKeys = keyof typeof TypographyElements;
