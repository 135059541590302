import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, Input, Select, SelectDate, Tag } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { remoteValues } from '~/common/utils/firebase/remoteConfig';
import {
  getExperts,
  getReasons,
  getServiceChannels,
  getTicketJourneys,
  getTicketProducts,
  getTicketResolutionReasons,
  getTicketStatus,
  getTicketTeams,
} from '~/store/tickets/actions';
import { FilterButton, FilterForm, FilterPopover } from './Filters.styled';

export type FilterProps = {
  onSubmit?: (values: any) => void;
  onClear?: () => void;
  initialValues?: { ticketId: string };
};

const ASSESSMENT_STATUS = [
  { label: 'Avaliado', value: 'DONE' },
  { label: 'Em andamento', value: 'IN_PROGRESS' },
  { label: 'Não avaliado', value: 'NONE' },
];

export function Filters({ onSubmit, onClear, initialValues }: FilterProps) {
  const form = useRef<WrappedFormUtils>();
  const { assessmentAgents } = remoteValues;

  const [visible, setVisible] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [ticketId, setTicketId] = useState(null);

  const getExpertsControl = useAwaitControl(getExperts);
  const experts = getExpertsControl.result();

  const getTeamsControl = useAwaitControl(getTicketTeams);
  const teams = getTeamsControl.result();

  const getProductsControl = useAwaitControl(getTicketProducts);
  const products = getProductsControl.result();

  const getJourneysControl = useAwaitControl(getTicketJourneys);
  const journeys = getJourneysControl.result();

  const getReasonsControl = useAwaitControl(getReasons);
  const reasons = getReasonsControl.result();

  const getChannelsControl = useAwaitControl(getServiceChannels);
  const channels = getChannelsControl.result();

  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const status = getTicketStatusControl.result();

  const getResolutionReasonsControl = useAwaitControl(getTicketResolutionReasons);
  const resolutionReasons = getResolutionReasonsControl.result();

  const handleProductChange = useCallback(
    ([loanType]) => {
      setSelectedProduct(loanType);

      if (loanType !== selectedProduct) {
        form.current?.setFieldsValue({ journeyId: [], reasonId: [] });
      }
    },
    [selectedProduct],
  );

  const handleJourneyChange = useCallback(
    ([journey]) => {
      setSelectedJourney(journey);

      if (journey !== selectedJourney) {
        form.current?.setFieldsValue({ reasonId: [], resolutionReasonId: [] });
      }
    },
    [selectedJourney],
  );

  const handleSelectionStatusChange = useCallback(([selectedStatus]) => {
    setSelectedStatus(selectedStatus);
    form.current?.setFieldsValue({ resolutionReasonId: [] });
  }, []);

  const filteredJourneys = useMemo(() => {
    const filteredItems = journeys?.filter((journey) =>
      journey.products?.includes(selectedProduct),
    );

    return filteredItems || [];
  }, [journeys, selectedProduct]);

  const filteredReasons = useMemo(() => {
    const filteredItems = reasons?.filter(
      (reason) =>
        reason.journeys?.includes(selectedJourney) && reason.products?.includes(selectedProduct),
    );
    return filteredItems || [];
  }, [journeys, selectedJourney, selectedProduct]);

  const filteredResolutionReasons = useMemo(() => {
    const filteredItems = resolutionReasons?.filter(
      (reason) =>
        reason.journeys?.includes(selectedJourney) && reason.status?.includes(selectedStatus),
    );
    return filteredItems || [];
  }, [resolutionReasons, selectedStatus, selectedJourney]);

  const filters = useMemo(
    () => [
      {
        id: 'ticketId',
        label: 'ID do ticket',
        initialValue: initialValues?.ticketId,
        input: <Input onChange={(e) => setTicketId(e)} />,
      },
      {
        id: 'period',
        input: <SelectDate selectRange placeholder="Período" maxDate={new Date()} />,
        options: {
          rules: [{ required: !ticketId, message: 'Campo obrigatório' }],
        },
      },
      {
        id: 'creatorId',
        input: (
          <Select
            options={experts}
            placeholder="Expert"
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
      {
        id: 'assessmentAgentId',
        input: <Select options={assessmentAgents || []} placeholder="Avaliado por" />,
      },
      {
        id: 'product',
        input: (
          <Select
            options={products}
            placeholder="Produto"
            onChange={handleProductChange}
            fieldNames={{ label: 'description', value: 'name' }}
          />
        ),
      },
      {
        id: 'journeyId',
        input: (
          <Select
            options={filteredJourneys}
            placeholder="Jornada"
            onChange={handleJourneyChange}
            fieldNames={{ label: 'name', value: 'id' }}
            disabled={!selectedProduct}
          />
        ),
      },
      {
        id: 'reasonId',
        input: (
          <Select
            options={filteredReasons}
            placeholder="Motivo da abertura"
            fieldNames={{ label: 'name', value: 'id' }}
            disabled={!selectedProduct || !selectedJourney}
          />
        ),
      },
      {
        id: 'statusId',
        input: (
          <Select
            options={status}
            placeholder="Status da resolução"
            fieldNames={{ label: 'name', value: 'id' }}
            onChange={handleSelectionStatusChange}
          />
        ),
      },
      {
        id: 'resolutionReasonId',
        input: (
          <Select
            options={filteredResolutionReasons}
            placeholder="Justificativa da resolução"
            disabled={!filteredResolutionReasons?.length}
            fieldNames={{ label: 'justification', value: 'id' }}
          />
        ),
      },
      {
        id: 'assessmentStatus',
        input: <Select options={ASSESSMENT_STATUS} placeholder="Status da avaliação" />,
      },
      {
        id: 'channelId',
        input: (
          <Select
            options={channels}
            placeholder="Canal"
            fieldNames={{ label: 'name', value: 'id' }}
          />
        ),
      },
      {
        id: 'contractOrigin',
        input: (
          <Select
            placeholder="Origem do contato"
            options={[
              { label: 'Ativo', value: 'ACTIVE' },
              { label: 'Receptivo', value: 'RECEPTIVE' },
            ]}
          />
        ),
      },
    ],
    [
      experts,
      products,
      filteredJourneys,
      filteredReasons,
      filteredResolutionReasons,
      status,
      channels,
      ticketId,
      initialValues,
    ],
  );

  const handleOnSubmit = (values: any) => {
    const count = Object.keys(values).filter((el) =>
      Array.isArray(values[el]) ? !!values[el].length : !!values[el],
    ).length;
    onSubmit?.(values);

    setFilterCount(count);
    setVisible(false);
  };

  const handleOnReset = () => {
    form.current?.resetFields();
    setFilterCount(0);
    setSelectedProduct(null);
    setSelectedJourney(null);
    setSelectedStatus(null);
    setTicketId(null);

    onClear?.();
  };

  useEffect(() => {
    if (!teams?.length) getTeamsControl.start();
    if (!products?.length) getProductsControl.start();
    if (!journeys?.length) getJourneysControl.start();
    if (!reasons?.length) getReasonsControl.start();
    if (!channels?.length) getChannelsControl.start();
    if (!status?.length) getTicketStatusControl.start();
    if (!experts?.length) getExpertsControl.start();
    if (!resolutionReasons?.length) getResolutionReasonsControl.start();
  }, []);

  useEffect(() => {
    if (!selectedProduct) {
      form.current?.resetFields(['statusId', 'resolutionReasonId']);
      setSelectedProduct(null);
      setSelectedJourney(null);
      setSelectedStatus(null);
    }
  }, [selectedProduct]);

  return (
    <FilterPopover
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <FilterForm ref={form} inputs={filters} onSubmit={handleOnSubmit}>
          <Flex gap={16} className="button-container">
            <Button rounded className="submit-button" customColor="black">
              Filtrar
            </Button>

            <Button
              type="button"
              rounded
              className="reset-button"
              customColor="black"
              variant="outline"
              onClick={handleOnReset}
            >
              Apagar filtros
            </Button>
          </Flex>
        </FilterForm>
      }
    >
      <FilterButton>
        {!!filterCount && (
          <Tag small rounded bgColor="brand.primary" textColor="brand.overPrimary">
            {filterCount}
          </Tag>
        )}
        Filtros
      </FilterButton>
    </FilterPopover>
  );
}
