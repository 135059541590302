import React from 'react';
import toPersonDocument from '~/common/masked/toPersonDocument';
import { cellToDate, cellToLink, cellToMoney } from '~/common/utils/table';
import { useDrawer } from '~/hooks/useDrawer';

import { ConsigPrivProposal } from '~/typings/entities/Proposals';
import {
  PartialApprovedStatusMessages,
  ProposalsStatus,
  ProposalStatusColors,
  ProposalStatusMessages,
  ProposalStatusNames,
} from '~/typings/enums/Proposals';
import { CellProps, Table, TableColumns, Tag, Tooltip } from '~/ui/components';

import { ProposalDetails } from './ProposalDetails/ProposalDetails';

import { TableContainer } from './ProposalsTable.styled';

export type PersonsTableProps = {
  data: ConsigPrivProposal[];
  loading?: boolean;
};

export function ProposalsTable({ data, loading }: PersonsTableProps) {
  const { openDrawer } = useDrawer();

  const cellToStatus = ({ getValue, row }: CellProps<ConsigPrivProposal>) => {
    const value = getValue<string>();
    const origin = row.original.proposalOrigin;
    let proposalMessage: string;

    const colors = ProposalStatusColors?.[value] || {
      bgColor: 'neutral.secondaryAlt',
      textColor: 'neutral.primary',
    };

    if (value === ProposalsStatus.PARTIAL_APPROVED) {
      proposalMessage = PartialApprovedStatusMessages?.[origin];
    } else {
      proposalMessage = ProposalStatusMessages?.[value];
    }

    if (proposalMessage) {
      return (
        <Tooltip content={proposalMessage} small>
          <Tag {...colors}>{ProposalStatusNames?.[value] || value || '-'}</Tag>
        </Tooltip>
      )
    }

    return <Tag {...colors}>{ProposalStatusNames?.[value] || value || '-'}</Tag>;
  };

  function handleLinkClick(props: CellProps<ConsigPrivProposal>) {
    openDrawer('proposalDetails', <ProposalDetails info={props.row.original} />, {
      title: 'Detalhes da Proposta',
      closable: true,
      width: 557
    });
  }

  const columns: TableColumns<ConsigPrivProposal> = [
    {
      accessorKey: 'idSolicitacao',
      header: () => 'COD',
      cell: cellToLink(handleLinkClick),
    },
    {
      accessorKey: 'validUntil',
      header: () => 'DATA EXPIRAÇÃO',
      cell: cellToDate('DD/MM/YYYY'),
    },
    {
      accessorKey: 'document',
      header: () => 'CPF',
      cell: ({ getValue }) => {
        const value = getValue();
        return toPersonDocument(value) || '-';
      },
    },
    {
      accessorKey: 'status',
      header: () => 'STATUS',
      cell: cellToStatus,
    },
    {
      accessorKey: 'installmentsNumber',
      header: () => 'PARCELAS',
      cellAlign: 'center',
      headerAlign: 'center',
    },
    {
      accessorKey: 'installmentsValue',
      header: () => 'VALOR PARCELA',
      cell: cellToMoney,
      cellAlign: 'right',
      headerAlign: 'right',
    },
    {
      accessorKey: 'value',
      header: () => 'VALOR PROPOSTA',
      cell: cellToMoney,
      cellAlign: 'right',
      headerAlign: 'right',
    },
  ];

  return (
    <TableContainer>
      <Table
        noItemsMessage="Nenhuma proposta encontrada"
        responsiveCols={['idSolicitacao', 'validUntil', 'document', 'status', 'installmentsNumber']}
        loading={loading}
        columns={columns}
        data={data}
        maxHeight={600}
        isTHeadFixed
      />
    </TableContainer>
  );
}
