import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Button, Typography } from '~/ui/components';

export const DrawerHeaderContainer = styled.div`
  border-bottom: 1px dashed ${themeColor('divider.primary', { dark: 'divider.secondary' })};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 30px 24px 24px;
  gap: 4px;

  button {
    max-height: 25px;
    height: 25px;
    width: 25px;
    max-width: 25px;
  }
`;

export const HeaderTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondaryAlt' }),
}))`
  flex: 1;

  b {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const CloseButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  icon: 'Close',
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))`
  background-color: ${themeColor('background.secondary')};
  border-radius: 12px;
  padding: 20px;
  position: relative;
  width: 40px;
  height: 40px;

  svg {
    color: ${themeColor('element.primary')};
    position: absolute;
    width: 24px;
    height: 24px;
  }
`;

export const BackButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  icon: 'Backwards',
  rounded: true,
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))`
  max-height: 24px;
  height: 24px;
  width: 24px;
  max-width: 24px;
  min-width: 24px;
  margin-right: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const DrawerBody = styled.div`
  padding: 0;
`;
