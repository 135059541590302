import React, { useCallback, useEffect, useState } from 'react';

import screenRegister from '~/hoc/screenRegister';
import { Content, Typography } from '~/ui/components';
import { ListInfo } from '~/screens/Courier/components';
import { useAwaitControl } from 'react-redux-await-control';
import { createDispatchOrder, getCourierList } from '~/store/courier/actions';
import { Box, ContentGroup } from './Courier.styles';
import { List } from './components/List/List';
import Selectors from './components/Selectors/Selectors';

function Courier() {
  const [listInfo, setListInfo] = useState(null);

  const createDispatchOrderControl = useAwaitControl(createDispatchOrder);
  const getCourierControl = useAwaitControl(getCourierList);

  const orderSuccessfullyCreated = createDispatchOrderControl.isSuccessful();
  const isCourierListLoading = getCourierControl.isRunning();
  const courierList = getCourierControl.result();

  const handleOnRowClick = useCallback((object) => {
    if (object?.children) {
      setListInfo(object);
    }
  }, []);

  useEffect(() => {
    if (orderSuccessfullyCreated) {
      setTimeout(() => {
        if (!isCourierListLoading) {
          const newInfo = courierList?.[0];
          setListInfo(newInfo);
        }
      }, 1000);
    }
  }, [orderSuccessfullyCreated, courierList, isCourierListLoading]);

  return (
    <Content>
      <Box>
        <Typography type="headingH6" weight={600}>
          Disparador - Courier
        </Typography>
        <Selectors />

        {listInfo && <ListInfo info={listInfo} />}

        <ContentGroup>
          <Typography type="bodyLarge" weight={600}>
            Listas carregadas
          </Typography>

          <List onRowClick={handleOnRowClick} />
        </ContentGroup>
      </Box>
    </Content>
  );
}

export default screenRegister({
  screenName: 'Courier',
  path: '/backoffice/courier',
  headerTitle: 'Courier',
  customLayout: true,
})(Courier);
