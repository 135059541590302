import React, { useCallback } from 'react';
import { Button, Tag, Typography } from '~/ui/components';
import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { Money } from '~/common';
import LoanType, { LOAN_TYPES } from '~/typings/enums/LoanType';
import {
  FGTSOpportunityStatus,
  FGTSOpportunityStatusColors,
  INSSOpportunityStatus,
  INSSOpportunityStatusColors,
  PortabilityStatus,
  PortabilityStatusColors,
} from '~/typings/enums/Opportunity';
import { useDrawer } from '~/hooks/useDrawer';
import { Info, Content, Wrapper, Label, Section } from './Card.styled';
import { Opportunity } from '../../../Drawers/Opportunity/Opportunity';
import { Integration } from '../../../Drawers/Integration/Integration';
import { useTracking } from '~/hooks/useTracking';
import { ampli } from '~/ampli';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';

interface CardProps {
  opportunity: OpportunityListItem;
}

export function Card({ opportunity }: CardProps) {
  const { openDrawer } = useDrawer();
  const { handleTracking } = useTracking();
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const personDetails = getPersonDetailsControl.result();

  const isPortability = (loanType: string) => loanType === LOAN_TYPES.PORTABILITY;

  const isINSS = (loanType: string) => {
    const INSSLoans = [
      LOAN_TYPES.NEW,
      LOAN_TYPES.PORTABILITY,
      LOAN_TYPES.PORTABILITYWITHOUTREFIN,
      LOAN_TYPES.REFIN,
      LOAN_TYPES.FUTUREMARGIN,
    ];
    return INSSLoans.includes(loanType);
  };

  const renderStatus = useCallback((loanType, statusKey) => {
    if (isINSS(loanType)) {
      if (isPortability(loanType)) {
        return PortabilityStatus[statusKey];
      }
      return INSSOpportunityStatus[statusKey];
    }

    return FGTSOpportunityStatus[statusKey];
  }, []);

  const renderStatusColor = useCallback((loanType, statusKey) => {
    if (isINSS(loanType)) {
      if (isPortability(loanType)) {
        return PortabilityStatusColors[statusKey];
      }
      return INSSOpportunityStatusColors[statusKey];
    }

    return FGTSOpportunityStatusColors[statusKey];
  }, []);

  const isCardDisabled = (loanType, portableContracts, status) => {
    if (isPortability(loanType)) {
      return portableContracts?.[0]?.simulationPortabilityType !== 'UPDATED_HISCON';
    }

    const notDisabled = ['OK', 'PORT_OUT'];

    return !notDisabled.includes(status);
  };

  const statusKey = isPortability(opportunity?.loanType)
    ? opportunity?.portableContracts?.[0]?.simulationPortabilityType
    : opportunity?.status;

  const status = renderStatus(opportunity?.loanType, statusKey);
  const colors = renderStatusColor(opportunity?.loanType, statusKey);
  const isDisabled = isCardDisabled(
    opportunity?.loanType,
    opportunity?.portableContracts,
    opportunity?.status,
  );

  const handleOnClick = (opportunity: OpportunityListItem) => {
    openDrawer('opportunity-details', <Opportunity opportunity={opportunity} />, {
      width: 460,
      title: `Detalhes da oportunidade`,
      closable: true,
      destroyOnClose: true,
      noPadding: true,
    });

    const event = ampli.formalizacaoIniciouFormalizacaoCardpeople({
      titulo_tela: 'PersonDetailsNew',
      cliente_person_id: personDetails.enrollmentId,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);
  };

  return (
    <Wrapper onClick={() => handleOnClick(opportunity)} disabled={isDisabled}>
      <Section>
        <Content row>
          <Content>
            <Info>matricula</Info>
            <Info bold>{opportunity?.enrollment ?? '-'}</Info>
          </Content>
          <Tag small bgColor={colors?.bgColor} textColor={colors?.textColor} rounded>
            {status}
          </Tag>
        </Content>
        <Typography type="paragraphLarge" weight={500}>
          {LoanType(opportunity?.loanType)}
        </Typography>
      </Section>
      <Section>
        <Content>
          <Label>Receba até</Label>
          <Typography type="subtitleSmall" weight={600}>
            {Money(opportunity?.loanMaxValue)}
          </Typography>
        </Content>
        <Button rounded variant="outline" color="primary" disabled={isDisabled} size="sm">
          Simular
        </Button>
      </Section>
    </Wrapper>
  );
}
