/* eslint-disable camelcase */
import biometryTUDO from './images/tudo-biometry.png';
import refinIcon from './images/refinIcon.png';
import portabilityIcon from './images/portabilityIcon.png';
import newLoanIcon from './images/newLoanIcon.png';
import creditCardIcon from './images/creditCardIcon.png';
import logo from './images/tudo-logo.png';
import backofficeLogo from './images/backoffice-logo.png';
import iconTudo from './images/icon-tudo.png';
import logoColor from './images/logo-colorfull.png';
import mask from './images/mask.png';
import maskStroke from './images/mask_stroke.png';
import itsAlmostThere from './images/itsAlmostThere.png';
import bigCheck from './images/bigCheck.png';
import user from './images/user.png';
import success from './images/success.png';
import failure from './images/failure.png';

import lineConnection from './images/lineConnection.png';
import connectionTUDO from './images/connectionTUDO.png';
import connectionMeuINSS from './images/connectionMeuINSS.png';
import noComments from './images/no-comments.png';
import noPendencies from './images/no-pendencies.png';
import personGlasses from './images/person-glasses.png';
import error from './images/error.png';
import personAirplane from './images/person-airplane.png';
import paperPlane from './images/paperPlane.png';
import support from './images/support.png';
import alert from './images/alert.png';

import bank001 from './images/FinancialInstitutions/001@3x.png';
import bank004 from './images/FinancialInstitutions/004@3x.png';
import bank033 from './images/FinancialInstitutions/033@3x.png';
import bank041 from './images/FinancialInstitutions/041@3x.png';
import bank104 from './images/FinancialInstitutions/104@3x.png';
import bank237 from './images/FinancialInstitutions/237@3x.png';
import bank254 from './images/FinancialInstitutions/254@3x.png';
import bank318 from './images/FinancialInstitutions/318@3x.png';
import bank320 from './images/FinancialInstitutions/320@3x.png';
import bank326 from './images/FinancialInstitutions/326@3x.png';
import bank341 from './images/FinancialInstitutions/341@3x.png';
import bank422 from './images/FinancialInstitutions/422@3x.png';
import bank623 from './images/FinancialInstitutions/623@3x.png';
import bank655 from './images/FinancialInstitutions/655@3x.png';
import bank707 from './images/FinancialInstitutions/707@3x.png';
import bank739 from './images/FinancialInstitutions/739@3x.png';
import bank955 from './images/FinancialInstitutions/955@3x.png';
import bankDefault from './images/FinancialInstitutions/default.png';

import documentDocument from './images/Documents/Document.png';
import documentPersonDocument__Back from './images/Documents/PersonDocument__Back.png';
import documentPersonDocument__Front from './images/Documents/PersonDocument__Front.png';
import documentDrivesLicense__Back from './images/Documents/DrivesLicense__Back.png';
import documentDrivesLicense__Front from './images/Documents/DrivesLicense__Front.png';

export default {
  biometryTUDO,
  noComments,
  noPendencies,
  refinIcon,
  portabilityIcon,
  newLoanIcon,
  creditCardIcon,
  connectionTUDO,
  connectionMeuINSS,
  mask,
  maskStroke,
  itsAlmostThere,
  bigCheck,
  logo,
  logoColor,
  user,
  iconTudo,
  backofficeLogo,
  bank001,
  bank004,
  bank033,
  bank041,
  bank104,
  bank237,
  bank254,
  bank318,
  bank320,
  bank326,
  bank341,
  bank422,
  bank623,
  bank655,
  bank707,
  bank739,
  bank955,
  bankDefault,
  documentDocument,
  documentPersonDocument__Back,
  documentPersonDocument__Front,
  documentDrivesLicense__Back,
  documentDrivesLicense__Front,
  lineConnection,
  success,
  failure,
  personGlasses,
  error,
  personAirplane,
  paperPlane,
  support,
  alert,
};
