import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 24px;
  gap: 16px;
`;

type ContentProps = {
  position?: 'space-between' | 'center';
  alignCenter?: boolean;
  pt?: boolean;
  pb?: boolean;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  gap: 4px;

  ${({ position }) =>
    position &&
    css`
      justify-content: ${position};
    `}

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `}

  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.element.disabled};
    `}

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

export const SummaryPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  gap: 8px;
  padding: 16px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Card = styled.div<{ pt: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-left: 8px;

  ${({ pt }) =>
    pt &&
    css`
      padding-top: 16px;
      border-top: 0.5px dotted ${({ theme }) => theme.colors.divider.primary};
    `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Date = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.secondary'),
  weight: 700,
}))`
  border: 1px solid ${({ theme }) => theme.colors.element.placeholder};
  border-radius: 15px;
  width: fit-content;
  padding: 2px 15px;
`;

export const Input = styled.div`
  padding: 16px 12px;
  border: 1px solid ${({ theme }) => theme.colors.divider.primary};
  border-radius: 15px;
`;
