export enum CardNames {
  BENEFIT_CARD = 'Cartão Benefício',
  CONSIGNED_CARD = 'Cartão Consignado',
}

export enum CardStatusPalette {
  Ativo = 'positive',
  Bloqueado = 'negative',
}

export enum CardContractStatusPalette {
  ACTIVE = 'positive',
  CANCELLED = 'negative',
  SIGNED = 'info',
  REQUESTED = 'neutral',
}

export enum CardContractStatus {
  ACTIVE = 'Ativo',
  CANCELLED = 'Cancelado',
  SIGNED = 'Assinado',
  REQUESTED = 'Requerido',
}

export enum InvoiceTransactionTypes {
  CREDIT = 'Crédito',
  DEBIT = 'Débito',
}

export const InvoiceStatusColors = {
  OPEN: {
    bg: 'info.secondary',
    text: 'info.primaryAlt',
    label: 'Aberta',
  },
  CLOSED: {
    bg: 'negative.secondary',
    text: 'alert.primaryAlt',
    label: 'Fechada',
  },
  PAYED: {
    bg: 'positive.secondary',
    text: 'positive.primaryAlt',
    label: 'Paga',
  },
  INSTALLMENT: {
    bg: 'neutral.secondary',
    text: 'neutral.primaryAlt',
    label: 'Parcelada',
  },
};
