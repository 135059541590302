import { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export default css`
  .mt-select-dropdown {
    &.ant-cascader-menus {
      border-radius: 16px;
      background: transparent;
      box-shadow: none;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
      }

      &.left {
        & > div {
          flex-direction: row-reverse;
        }
      }

      .ant-cascader-menu {
        min-width: 0px;
        border-radius: 16px;
        height: auto;
        max-height: 200px;
        padding: 8px 0;
        background: ${({ theme }) => theme.colors.background.primary};
        box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        overflow-x: hidden;
        border: none;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .ant-cascader-menu:not(:empty):not(:first-child):has(> *:nth-child(n + 7)) {
        max-height: 380px;
      }

      .ant-cascader-menu-item {
        color: ${themeColor('element.primary', { dark: 'element.placeholder' })};
        font-family: Libre Franklin, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &:hover,
        &.ant-cascader-menu-item-active {
          font-weight: 400;
          background-color: ${({ theme }) => theme.colors.state.hover};
        }

        &.ant-cascader-menu-item-expand {
          padding-right: 32px;
        }

        &.ant-cascader-menu-item-disabled {
          opacity: 0.4;
        }
      }

      .ant-cascader-menu-item-expand-icon {
        right: auto !important;
        margin-left: 9px;

        svg {
          color: ${({ theme }) => theme.colors.primary.main};
        }
      }
    }
    &.right > div .ant-cascader-menu:not(:empty):not(:first-child) {
      min-width: 380px;
      position: absolute;
      left: calc(100% + 4px);
    }
    &.left > div .ant-cascader-menu:not(:empty):not(:first-child) {
      min-width: 380px;
      position: absolute;
      right: calc(100% + 4px);
    }
  }
`;
