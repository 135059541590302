import React, { useCallback, useEffect, useMemo, useState } from 'react';

import screenRegister from '~/hoc/screenRegister';
import { Container, TableWrapper } from '~/screens/TicketsManagement/TicketsManagement.styled';
import useUserRoles from '~/hooks/useUserRoles';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getTickets,
  removeResponsible,
  updateResponsible,
  updateTicket,
} from '~/store/tickets/actions';
import usePagination from '~/hooks/usePagination';
import { debounce } from 'lodash';
import { Flex, Typography } from '~/ui/components';
import TicketTable from './components/TicketTable/TicketTable';
import Filters from './components/Filters/Filters';

function TicketsManagement() {
  const { ready, hasRole } = useUserRoles();
  const [filters, setFilters] = useState({});
  const [ticketId, setTicketId] = useState(null);
  const [wasFiltered, setWasFiltered] = useState(false);

  const getTicketsControl = useAwaitControl(getTickets);
  const updateTicketControl = useAwaitControl(updateTicket);
  const updateResponsibleControl = useAwaitControl(updateResponsible);
  const removeResponsibleControl = useAwaitControl(removeResponsible);

  const loadingTickets = getTicketsControl.isRunning();

  const tickets = getTicketsControl.result();
  const successfullyUpdated = updateTicketControl.isSuccessful();
  const responsibleSuccessfullyUpdated = updateResponsibleControl.isSuccessful();
  const responsibleSuccessfullyRemoved = removeResponsibleControl.isSuccessful();

  const { page, size, paginatedData, renderPagination, clear } = usePagination({
    data: tickets?.tickets,
    total: tickets?.total,
    pageSize: 50,
  });

  const handleFilters = useCallback((value) => {
    setFilters(value);
    clear();
    getTicketsControl.clear();
  }, []);

  const handleSearch = useCallback(
    (ticketId) => {
      if (ticketId.length >= 3) {
        getTicketsControl.start({ ...filters, page, size, sort: 'id,desc', ticketId });
        setWasFiltered(true);
      }
    },
    [filters, page, size],
  );

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), []);

  const handleChange = (value) => {
    setTicketId(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (
      (!paginatedData[page] && !loadingTickets && tickets.total !== 0 && tickets.page !== page) ||
      responsibleSuccessfullyUpdated ||
      successfullyUpdated ||
      responsibleSuccessfullyRemoved ||
      (ticketId?.length === 0 && wasFiltered)
    ) {
      getTicketsControl.start({ ...filters, page, size, sort: 'id,desc', ticketId });
    }

    if (ticketId?.length === 0 && wasFiltered) {
      setWasFiltered(false);
    }
  }, [
    page,
    size,
    paginatedData,
    loadingTickets,
    filters,
    tickets,
    responsibleSuccessfullyUpdated,
    successfullyUpdated,
    responsibleSuccessfullyRemoved,
    ticketId,
    wasFiltered,
  ]);

  useEffect(() => {
    if (successfullyUpdated || responsibleSuccessfullyUpdated || responsibleSuccessfullyRemoved) {
      clear();
      updateResponsibleControl.clear();
      updateTicketControl.clear();
      removeResponsibleControl.clear();
    }
  }, [successfullyUpdated, responsibleSuccessfullyUpdated, responsibleSuccessfullyRemoved]);

  useEffect(() => {
    setFilters({ statusId: 63 });
  }, [])

  if (!ready) return <Container />;

  if (!hasRole('TICKET_SUPERVISOR')) {
    return (
      <Container center>
        <Flex ph={16} pv={16} align="center" justify="center">
          <Typography type="bodyMedium" weight={600}>
            Você não tem permissão para acessar essa tela
          </Typography>
        </Flex>
      </Container>
    );
  }

  return (
    <Container>
      <Filters onChange={handleFilters} onSearch={handleChange} />
      <TableWrapper>
        <TicketTable data={paginatedData} />
        {tickets && renderPagination()}
      </TableWrapper>
    </Container>
  );
}

export default screenRegister({
  screenName: 'TicketsManagement',
  headerTitle: 'Gestão de Tickets',
  path: '/backoffice/tickets-management',
})(TicketsManagement);
