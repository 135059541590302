import React, { useCallback } from 'react';

import { ActionProps, Tabs } from '~/ui/components';
import { EditPersonInfoModal } from '~/components';

import { useModal } from '~/hooks/useModal';
import { Opportunity } from '~/typings/entities/Opportunity';
import { Person } from '~/typings/entities/person';

import { PersonalInfo } from '~/screens/Person/components/Tabs/PersonalInfo';

import { AboutClient } from '../Tabs';

import { ActionButton } from './PersonInfo.styled';

interface InfoSectionProps {
  person: Person;
  clixContracts: any[];
  fgtsOpportunity: Opportunity;
  loading?: boolean;
}

export function PersonInfo({ person, clixContracts, fgtsOpportunity, loading }: InfoSectionProps) {
  const { openModal } = useModal();

  const handleActionButtonClick = useCallback(() => {
    openModal(<EditPersonInfoModal />, {
      id: 'edit-person-info',
      title: 'Editar informações do cliente',
      closable: true,
    });
  }, []);

  const ExtraActions = useCallback(
    (props: ActionProps) => <ActionButton onClick={handleActionButtonClick} {...props} />,
    [],
  );

  return (
    <Tabs
      collapsable
      initCollapsed
      loading={loading}
      extraActions={<ExtraActions />}
      persistOnQuery={{ key: 'info' }}
      testId="personal-info"
      tabs={[
        {
          key: 'sobre',
          title: 'Sobre seu cliente',
          component: (
            <AboutClient
              personDetails={person}
              clixContracts={clixContracts}
              fgtsOpportunity={fgtsOpportunity}
            />
          ),
        },
        {
          key: 'adicionais',
          title: 'Informações adicionais',
          component: <PersonalInfo personDetails={person} />,
        },
      ]}
    />
  );
}
