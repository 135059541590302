import styled, { css } from 'styled-components';
import { Cascader } from 'antd';
import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export type CustomComponentsAreaProps = {
  newWidth?: string | number;
};

export const PortalSelect = styled.div<CustomComponentsAreaProps>`
  .ant-cascader-menus {
    width: ${({ newWidth }) => (Number.isInteger(newWidth) ? `${newWidth}px` : newWidth)};
    div {
      display: flex;
      position: relative;
      align-items: center;
    }
  }
  .ant-cascader-menus:first-child {
    width: 100%;
  }
  .ant-cascader-menu:first-child {
    width: 100%;
    min-width: 300px;
  }
`;

export const StyledCascader = styled(Cascader)`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  border-radius: 8px 8px 0 0;

  &.ant-cascader-picker {
    border-radius: 8px 8px 0 0;
    background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};

    .ant-cascader-picker-label {
      font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
      line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      color: ${themeColor('element.primary', { dark: 'element.placeholder' })};
      padding: 0 8px;
    }

    .ant-cascader-input {
      border: none;
      outline: none !important;
      box-shadow: none !important;
      font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
      line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-input-lg {
      height: 56px;
      padding: 0 8px;
    }

    &.ant-cascader-picker-disabled {
      opacity: 0.6;
    }

    &.ant-cascader-picker-with-value {
      .ant-cascader-picker-label {
        color: transparent;
      }
    }

    &.ant-cascader-picker-with-value,
    &.ant-cascader-picker-focused {
      .ant-input-lg {
        height: 24px;
        transform: translate(0, 25px);
      }
    }

    &:has(.ant-cascader-picker-label:not(:empty)) {
      .ant-input-lg {
        height: 24px;
        transform: translate(0, 25px);
      }

      .ant-cascader-picker-label {
        transform: translate(0, 9px);
      }
    }
  }

  .ant-cascader-picker-arrow {
    color: ${({ theme }) => theme.colors.element.primary};
  }

  .ant-cascader-picker-clear {
    background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
    color: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const SelectLabel = styled(Typography).attrs({
  element: 'label',
  type: 'bodyMedium',
  color: 'element.placeholder',
})`
  position: absolute;
  transform: translate(8px, 16px);
  pointer-events: none;
  z-index: 8;
  transition-property: transform, font-size, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:has(+ .ant-cascader-picker-with-value),
  &:has(+ .ant-cascader-picker-focused),
  &:has(+ .ant-cascader-picker .ant-cascader-picker-label:not(:empty)) {
    font-size: ${({ theme }) => theme.typography.types.bodyXSmall.size};
    font-weight: ${({ theme }) => theme.typography.types.bodyXSmall.weight};
    line-height: ${({ theme }) => theme.typography.types.bodyXSmall.lineHeight};
    color: ${({ theme }) => theme.colors.element.secondary};
    transform: translate(8px, 8px);
  }
`;
