import React, { useCallback } from 'react';
import moment from 'moment';

import { InfoGroup } from '~/ui/components';
import { Container, Content, Divider, Label, Section } from './CohortDetails.styled';
import { Timeline } from '../../components/Timeline/Timeline';
import { ListType } from '../../components/List/List';

interface CohortDetailProps {
  cohort: ListType;
}

export function CohortDetail({ cohort }: CohortDetailProps) {
  const isCompleted = useCallback((time) => {
    const newTime = new Date(time);
    const now = new Date();

    return newTime.getTime() <= now.getTime();
  }, []);

  const getNewTime = useCallback((dispatchDistance, createdAt) => {
    const createdAtDate = new Date(createdAt);
    createdAtDate.setMinutes(createdAtDate.getMinutes() + dispatchDistance);

    return createdAtDate.toISOString();
  }, []);

  const renderTimeLine = (segments, timeGap, createdAt) => {
    const events = [{ label: 'Segmento 1', value: 'Disparo inicial', completed: true }];

    for (let i = 2; i <= segments; i += 1) {
      events.push({
        label: `Redisparo ${i - 1}`,
        value: moment(getNewTime(timeGap * (i - 1), createdAt)).format('HH:mm'),
        completed: isCompleted(getNewTime(timeGap * (i - 1), createdAt)),
      });
    }

    return events;
  };

  return (
    <Container>
      <Section>
        <InfoGroup label="ID-Cohort" value={cohort?.id} />
        <Content marginTop>
          <InfoGroup label="Grupo" value={cohort?.groupName} />
          <InfoGroup label="Estratégia" value={cohort?.strategyName} />
          <InfoGroup label="Volume total" value={cohort?.totalCohortSize} />
        </Content>
      </Section>
      <Divider />
      {cohort?.children?.map((item, index) => (
        <Section key={index}>
          <Label>Segmento {index + 1}</Label>
          <Content marginBottom>
            <InfoGroup
              label="Data/hora"
              value={moment(item?.createdAt).format('DD/MM/YY - HH:mm')}
            />
            <InfoGroup label="Enviados" value={item?.segmentSize} />
            <InfoGroup
              label="Frequência dos redisparos"
              value={`à cada ${cohort?.dispatchDistance}min.`}
            />
          </Content>
          <Timeline
            events={renderTimeLine(
              cohort?.dispatchesAllowed,
              cohort?.dispatchDistance,
              item?.createdAt,
            )}
          />
        </Section>
      ))}
    </Container>
  );
}
