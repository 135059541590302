import React from 'react';
import { Link } from 'react-router-dom';
import { cellToDate } from '~/common/utils/table';

import { Person } from '~/typings/entities/person';
import { Table, TableColumns } from '~/ui/components';
import RegistrationStatus from '~/typings/enums/RegistrationStatus';
import toDate from '~/common/masked/toDate';

import { PersonsTableContainer } from './PersonsTable.styled';

export type PersonsTableProps = {
  data: Person[];
  loading?: boolean;
};

export function PersonsTable({ data, loading }: PersonsTableProps) {
  const columns: TableColumns<Person> = [
    {
      accessorKey: 'id',
      header: () => 'COD',
      cell: ({ row }) => (
        <Link to={`/backoffice/people/${row.original.id}`}>{row.original.id}</Link>
      ),
    },
    {
      accessorKey: 'name',
      header: () => 'NOME',
    },
    {
      accessorKey: 'registrationStatus',
      header: () => 'SITUAÇÃO',
      cell: ({ getValue }) => {
        const value = getValue();
        return RegistrationStatus(value) || '-';
      },
    },
    {
      accessorKey: 'registrationDate',
      cellAlign: 'center',
      headerAlign: 'center',
      header: () => 'DATA DE REGISTRO',
      cell: cellToDate('DD/MM/YYYY'),
    },
  ];

  return (
    <PersonsTableContainer>
      <Table
        noItemsMessage="Nenhuma pessoa encontrada"
        loading={loading}
        columns={columns}
        data={data}
        maxHeight={600}
        isTHeadFixed
      />
    </PersonsTableContainer>
  );
}
