import React, { useCallback, useEffect, useMemo } from 'react';
import { getPersonDetail } from '~/store/people/actions';
import { ListGroup, Popover, Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import moment from 'moment';

import { Ticket } from '~/typings/entities/Ticket';
import { useDrawer } from '~/hooks/useDrawer';
import { TicketDetails } from '~/components';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { TicketResumePopover } from '~/screens/Person/components/Tabs/Tickets/TicketResumePopover';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getReasons,
  getServiceChannels,
  getTicketsByUser,
  getTicketStatus,
  selectTicket,
} from '~/store/tickets/actions';
import {
  CellWrapper,
  LinkToTicket,
  TicketsWrapper,
} from '~/screens/Person/components/Tabs/Tickets/Tickets.styled';
import { TicketOptions } from '~/components/Ticket/TicketDetails/TicketOptions/TicketOptions';
import { createSelectValues, findLastNameById, findNamesByIds } from '~/common/selectValues';
import { getTicketStatusColor, isTicketStatusComplete } from '~/common/tickets';
import { TicketAccompaniment } from '~/components/Ticket/TicketAccompaniment/TicketAccompaniment';
import { Dots } from '~/ui/assets/icons';
import theme from '~/ui/theme';
import { TicketModal } from '~/components/Ticket/TicketModal/TicketModal';
import useUserRoles from '~/hooks/useUserRoles';
import { useHistory } from 'react-router';
import { ActionButton } from '..';

interface TicketsProps {
  loading?: boolean;
}

export function Tickets({ loading }: TicketsProps) {
  const history = useHistory();
  const { openDrawer, closeDrawer } = useDrawer();
  const { ready, hasRole, roles } = useUserRoles();
  const { ticketLimitHours } = remoteValues;

  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const person = getPersonDetailsControl.result();

  const getServiceChannelsControl = useAwaitControl(getServiceChannels);
  const channels = getServiceChannelsControl.result();

  const getReasonsControl = useAwaitControl(getReasons);
  const reasons = getReasonsControl.result();

  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const isStatusLoading = getTicketStatusControl.isRunning();
  const status = getTicketStatusControl.result();

  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const tickets: Ticket[] = getTicketsByUserControl.result();
  const isTicketsLoading = getTicketsByUserControl.isRunning();

  const selectTicketControl = useAwaitControl(selectTicket);

  const isLoading = useMemo(
    () => loading || isTicketsLoading || isStatusLoading,
    [loading, isTicketsLoading, isStatusLoading],
  );

  const renderNames = useCallback((list, id: number, last = false) => {
    const ids = createSelectValues(list, id);
    const names = findNamesByIds(list, ids) || [];

    if (last) {
      return findLastNameById(list, id);
    }

    return names?.join(' - ');
  }, []);

  const redirectToTicket = (id: string) => {
    closeDrawer('ticket-details');
    setTimeout(() => {
      history.push(`/backoffice/tickets?ticketId=${id}`);
    }, 300);
  };

  const renderTitle = useCallback(
    (id: string, isMonitor) => {
      if (isMonitor) {
        return (
          <>
            Ticket número <LinkToTicket onClick={() => redirectToTicket(id)}>#{id}</LinkToTicket>
          </>
        );
      }

      return (
        <>
          Ticket número <b>#{id}</b>
        </>
      );
    },
    [ready, hasRole, roles],
  );

  const handleDetails = useCallback(
    (id) => () => {
      selectTicketControl.start(id);

      openDrawer('ticket-details', <TicketDetails id={id} />, {
        title: renderTitle(id, hasRole('TICKET_MONITORING')),
        extraAction: <TicketOptions />,
        closable: true,
        width: 600,
      });
    },
    [openDrawer, hasRole, renderTitle],
  );

  const handleOnClickActions = useCallback((e, row) => {
    if (e.id === 'update-ticket') {
      openDrawer('ticket-modal', <TicketModal isUpdating ticket={row} />, {
        title: 'Ticket de encaminhamento',
        width: 460,
        closable: true,
        maskClosable: false,
      });
    }

    return null;
  }, []);

  const isActionsEnabled = useCallback(
    (row) => {
      const isBOT = row.userCreatedId === 393;
      const date = moment(row.ticketOpeningDate);
      const isFortyEightHours = moment().diff(date, 'hours') <= ticketLimitHours;

      if (isBOT && isFortyEightHours) {
        return false;
      }

      return true;
    },
    [ticketLimitHours],
  );

  const columns: TableColumns<Ticket> = [
    {
      accessorKey: 'id',
      header: () => 'COD',
      cell: (info) => (
        <TicketResumePopover
          disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}
          ticket={info.row.original}
        />
      ),
    },
    {
      accessorKey: 'ticketOpeningDate',
      header: () => 'DATA',
      cell: (info) => (
        <CellWrapper disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}>
          <TicketAccompaniment
            triggerText={moment(info.row.original.ticketOpeningDate).format('DD/MM/YYYY')}
            ticket={info.row.original}
          />
        </CellWrapper>
      ),
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId), 'DD/MM/YYYY');
        const dateB = moment(rowB.getValue(columnId), 'DD/MM/YYYY');
        return dateA.isBefore(dateB) ? -1 : 1;
      },
    },
    {
      id: 'channel',
      header: () => 'CANAL',
      cell: (info) => {
        const { serviceChannelId } = info.row.original;
        return (
          <CellWrapper disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}>
            {renderNames(channels, serviceChannelId, true)}
          </CellWrapper>
        );
      },
    },
    {
      id: 'reason',
      header: () => 'MOTIVO',
      ellipsis: true,
      maxSize: 170,
      cell: (info) => {
        const ticket = info.row.original;
        const reason = (
          <CellWrapper
            disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}
            ellipsis
          >
            {ticket?.subReason ? `${ticket?.reason} - ${ticket?.subReason}` : ticket?.reason}
          </CellWrapper>
        );

        return (
          <Tooltip placement="bottomLeft" content={reason} small>
            {reason}
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'ticketStatusId',
      header: () => 'SITUAÇÃO',
      maxSize: 170,
      cell: (info) => {
        const statusName =
          findLastNameById(status, info.getValue<string>()) || info.row.original.statusTicket;
        return (
          <Tooltip placement="bottomLeft" content={statusName} small>
            <Tag small rounded {...getTicketStatusColor(status, info.getValue<string>())}>
              {statusName}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      id: 'history',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton
          onClick={handleDetails(info.row.original.id)}
          iconOnly
          icon="Eye"
          variant="text"
        />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <Popover
          width={250}
          placement="bottom"
          noHeader
          trigger="hover"
          disabled={isActionsEnabled(info.row.original)}
          content={
            <ListGroup
              items={[{ id: 'update-ticket', label: 'Editar ticket' }]}
              onClickItem={(e) => handleOnClickActions(e, info.row.original)}
            />
          }
        >
          <Dots
            color={
              isActionsEnabled(info.row.original)
                ? theme.colors.gray[900]
                : theme.colors.primary.main
            }
          />
        </Popover>
      ),
    },
  ];

  const formatTickets = (ticketsArray: Ticket[]) => {
    const childrenIds = new Set();

    ticketsArray.forEach((ticket) => {
      ticket.children.forEach((child) => {
        childrenIds.add(child.id);
      });
    });

    const ticketsWithoutDuplicatedIds = ticketsArray.filter(
      (ticket) => !childrenIds.has(ticket.id),
    );

    const ticketsOrderByTicketOpeningDate = ticketsWithoutDuplicatedIds.map((ticket) => {
      if (ticket.children.length) {
        // Inicializa o ticket mais recente
        let mostRecentChild = ticket.children[0];

        // Encontra o ticket com a data de abertura mais recente entre os filhos
        ticket.children.forEach((childTicket) => {
          if (
            new Date(childTicket.ticketOpeningDate) > new Date(mostRecentChild.ticketOpeningDate)
          ) {
            mostRecentChild = childTicket;
          }
        });

        // // Verifica se o ticket atual (fora do `if`) é mais recente do que o mais recente entre os filhos
        if (new Date(ticket.ticketOpeningDate) > new Date(mostRecentChild.ticketOpeningDate)) {
          mostRecentChild = ticket;
        }

        // Adiciona o atributo `children` se não existir
        if (!mostRecentChild.children) {
          mostRecentChild.children = [];
        }

        // Adiciona os filhos do ticket no novo objeto chamado mostRecentChild
        ticket.children.forEach((childTicket) => {
          if (childTicket.id !== mostRecentChild.id) {
            mostRecentChild.children.push(childTicket);
          }
        });

        // Adiciona o proprio ticket no novo objeto chamado mostRecentChild caso ele mesmo não seja o mostRecentChild
        if (ticket.id !== mostRecentChild.id) {
          // Remove o children do ticket para não criar um loop na tabela
          const { children, ...rest } = ticket;

          mostRecentChild = { ...mostRecentChild, children: [...mostRecentChild.children, rest] };
        }

        return mostRecentChild;
      }
      return ticket;
    });

    return ticketsOrderByTicketOpeningDate;
  };

  useEffect(() => {
    if (person?.id && !tickets?.length) {
      getTicketsByUserControl.start({ personId: person?.id });
    }

    if (!channels?.length) getServiceChannelsControl.start();
    if (!reasons?.length) getReasonsControl.start();
    if (!status?.length) getTicketStatusControl.start();
  }, [person]);

  return (
    <TicketsWrapper>
      <Table
        noItemsMessage="Nenhum ticket encontrado"
        loading={isLoading}
        data={formatTickets(tickets)}
        responsiveCols={['id', 'ticketOpeningDate', 'reason', 'ticketStatusId']}
        columns={columns}
        isTHeadFixed
        maxHeight={415}
      />
    </TicketsWrapper>
  );
}
