import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useAwaitControl } from 'react-redux-await-control';

import { Button, Select } from '~/ui/components';
import { createDispatchOrder, getCourierStrategies } from '~/store/courier/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { useModal } from '~/hooks/useModal';
import { Content, Label, StyledForm, Wrapper } from './Selectors.styles';
import { StrategyDetails } from '../../drawers/StrategyDetails/StrategyDetails';
import ConfirmOrder from '../../modals/ConfirmOrder/ConfirmOrder';

function Selectors() {
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();

  const form = useRef<WrappedFormUtils>(null);

  const [groupName, setGroupName] = useState('');
  const [strategy, setStrategy] = useState('');
  const [method, setMethod] = useState('');

  const courierStrategiesControl = useAwaitControl(getCourierStrategies);
  const createDispatchOrderControl = useAwaitControl(createDispatchOrder);

  const courierStrategies = courierStrategiesControl.result();
  const isLoading = courierStrategiesControl.isRunning();
  const orderSuccessfullyCreated = createDispatchOrderControl.isSuccessful();

  const groupOptions = useMemo(() => {
    if (!Array.isArray(courierStrategies) || courierStrategies.length === 0) return [];

    return Array.from(new Set(courierStrategies?.map((item) => item.groupName))).map(
      (groupName) => ({
        label: groupName,
        value: groupName,
      }),
    );
  }, [courierStrategies]);

  const strategyOptions = useMemo(() => {
    if (!Array.isArray(courierStrategies) || courierStrategies.length === 0) return [];

    return courierStrategies
      ?.filter((option) => option.groupName === groupName)
      .map((option) => ({
        label: option.name,
        value: option.name,
      }));
  }, [courierStrategies, groupName]);

  const dataMethods = useMemo(() => {
    const methods = [];
    const courierStrategy = courierStrategies?.find((option) => option.name === strategy);

    if (courierStrategy?.query && courierStrategy?.query?.length > 0) {
      methods.push({ label: 'AURORA', value: 'AURORA' });
    }

    if (courierStrategy?.insiderCohortId && courierStrategy?.insiderCohortId?.length > 0) {
      methods.push({ label: 'INSIDER', value: 'INSIDER' });
    }

    if (courierStrategy?.amplitudeCohortId && courierStrategy?.amplitudeCohortId?.length > 0) {
      methods.push({ label: 'AMPLITUDE', value: 'AMPLITUDE' });
    }

    return methods;
  }, [strategy]);

  const handleSubmit = useCallback((e) => {
    openModal(<ConfirmOrder order={e} />, {
      title: `Carregar lista`,
      id: 'load-list-modal',
      closable: true,
      width: 450,
    });
  }, []);

  const handleOnClickStrategy = useCallback(
    (strategy) => {
      openDrawer(
        'strategy-details',
        <StrategyDetails
          strategy={courierStrategies?.find((option) => option.name === strategy)}
        />,
        {
          title: 'Detalhes da estratégia',
          closable: true,
          width: 560,
        },
      );
    },
    [courierStrategies],
  );

  const inputs = useMemo(
    () => [
      {
        id: 'group',
        label: 'Selecione o grupo',
        initialValue: groupName,
        input: (
          <Select
            options={groupOptions}
            loading={isLoading}
            onChange={(e) => setGroupName(e[0])}
            placeholder="Selecione o grupo"
          />
        ),
      },
      {
        id: 'strategy',
        label: 'Selecione a Estrategia',
        initialValue: strategy,
        input: (
          <Select
            options={strategyOptions}
            loading={isLoading}
            disabled={!groupName}
            onChange={(e) => setStrategy(e[0])}
            placeholder="Selecione a Estrategia"
          />
        ),
      },
      {
        id: 'cohortMethod',
        label: 'Fonte de dados',
        initialValue: method,
        input: (
          <Select
            options={dataMethods}
            loading={isLoading}
            disabled={!strategy}
            onChange={(e) => setMethod(e[0])}
            placeholder="Fonte de dados"
          />
        ),
      },
    ],
    [groupOptions, strategyOptions, dataMethods, isLoading, method, groupName, strategy],
  );

  useEffect(() => {
    courierStrategiesControl.start();
  }, []);

  useEffect(() => {
    if (orderSuccessfullyCreated || groupName === '') {
      const formRef = form.current;

      formRef.validateFields((err) => {
        if (!err) {
          formRef.resetFields();
        }
      });
    }
  }, [orderSuccessfullyCreated, groupName]);

  useEffect(() => {
    if (!form.current.getFieldValue('group').length) {
      form.current?.setFieldsValue({ strategy: [], cohortMethod: [] });
      setGroupName('');
      setStrategy('');
      setMethod('');
    }
    if (!form.current.getFieldValue('strategy').length) {
      form.current?.setFieldsValue({ cohortMethod: [] });
      setStrategy('');
      setMethod('');
    }
  }, [groupName, strategy, form]);

  return (
    <Wrapper>
      <Label>Carregar listas</Label>
      <StyledForm ref={form} inputs={inputs} onSubmit={handleSubmit}>
        <Content>
          <Button
            variant="outline"
            disabled={!method || !strategy || !groupName}
            rounded
            size="md"
            type="submit"
          >
            Carregar lista
          </Button>
          <Button
            variant="text"
            rounded
            size="md"
            disabled={!strategy}
            onClick={() => handleOnClickStrategy(strategy)}
            type="button"
          >
            Detalhe da estratégia
          </Button>
        </Content>
      </StyledForm>
    </Wrapper>
  );
}

export default memo(Selectors);
