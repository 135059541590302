import styled, { css } from 'styled-components';
import { Alert } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  display: flex;
  gap: 8px;
  max-width: 320px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

type SectionType = {
  justify?: 'center' | 'space-between' | 'end';
};

export const Section = styled.div<SectionType>`
  display: flex;
  flex-direction: ${({ justify }) => (justify ? 'row' : 'column')};
  gap: 8px;

  div {
    span {
      overflow-wrap: anywhere;
    }
  }

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${({ justify }) => justify};
    `}
`;

export const Imagem = styled.img`
  margin-top: 24px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  margin: 8px 0;
`;

export const NewAlert = styled(Alert)<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
