/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useTheme } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button, Popover, TableColumns, Tag, Typography } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';
import { getTickets, getTicketStatus, selectTicket } from '~/store/tickets/actions';
import { Ticket, TicketAssessmentSearch } from '~/typings/entities/Ticket';
import { CaretDown } from '~/ui/assets/icons';
import { LOAN_TYPE_NAMES } from '~/typings/enums/LoanType';
import { TicketDetails } from '~/components';
import useUserRoles from '~/hooks/useUserRoles';
import { TicketOptions } from '~/components/Ticket/TicketDetails/TicketOptions/TicketOptions';
import { findLastNameById } from '~/common/selectValues';
import { getTicketStatusColor } from '~/common/tickets';
import { Content, Hour, LinkToTicket, StyledTable } from './TicketTable.styles';
import ManageResponsible from '../ManageResponsible/ManageResponsible';
import Actions from '../../drawers/Actions/Actions';

const FlagsColor = {
  WAITING_DEFINITION: { color: 'element', type: 'primary' },
  HIGH: { color: 'alert', type: 'primary' },
  LOW: { color: 'neutral', type: 'secondaryAlt' },
  MEDIUM: { color: 'info', type: 'secondaryAlt' },
};

const StatusColorMap = {
  'Conclusão sem sucesso': 'negative',
  'Conclusão com sucesso': 'positive',
  Andamento: 'alert',
};

type TicketTableProps = {
  data: {
    assessmentStatus: string;
    ticket: Ticket;
  }[];
};

function TicketTable({ data }: TicketTableProps) {
  const history = useHistory();
  const { colors } = useTheme();
  const { openDrawer, closeDrawer } = useDrawer();
  const { ready, hasRole, roles } = useUserRoles();

  const getTicketsControl = useAwaitControl(getTickets);
  const selectTicketControl = useAwaitControl(selectTicket);
  const ticketStatusControl = useAwaitControl(getTicketStatus);

  const ticketStatus = ticketStatusControl.result();

  const isLoadingTickets = getTicketsControl.isRunning();

  const renderPersonId = useCallback((value) => {
    if (!value) return '-';

    return (
      <Link to={`/backoffice/people/${value}`}>
        <b>{value}</b>
      </Link>
    );
  }, []);

  const renderContractId = useCallback((value) => {
    if (!value) return '-';

    return (
      <Popover
        trigger="hover"
        placement="bottom"
        width={265}
        noHeader
        content={
          <Content>
            {value?.map((contract) => (
              <Link to={`/backoffice/contract/${contract.id}`} key={contract.id}>
                <b>{contract.id}</b>
              </Link>
            ))}
          </Content>
        }
      >
        <Content row>
          <Link to={`/backoffice/contract/${value[0].id}`}>
            <b>{value[0].id}</b>
          </Link>
          <CaretDown className="action-icon" width={14} height={14} />
        </Content>
      </Popover>
    );
  }, []);

  const renderStatus = useCallback(
    (ticketStatusId, statusTicket) => {
      if (!ticketStatusId) return '-';

      const statusName = findLastNameById(ticketStatus, ticketStatusId) || statusTicket;

      return (
        <Tag small rounded {...getTicketStatusColor(ticketStatus, ticketStatusId)}>
          {statusName}
        </Tag>
      );
    },
    [ticketStatus],
  );

  const renderFlag = useCallback((row) => {
    const { priorityTicket } = row.row.original.ticket;

    return (
      <Button
        icon="Flag"
        iconOnly
        variant="text"
        customColor={colors?.[FlagsColor[priorityTicket].color]?.[FlagsColor[priorityTicket].type]}
      />
    );
  }, []);

  const renderDate = useCallback((row) => {
    const { ticketOpeningDate } = row.row.original.ticket;

    return (
      <Content gap={2}>
        <Typography type="caption">{moment(ticketOpeningDate).format('DD/MM/YY')}</Typography>
        <Hour>{moment(ticketOpeningDate).format('HH:mm')}</Hour>
      </Content>
    );
  }, []);

  const renderResponsable = useCallback((row) => {
    const { responsibleName, responsibleId, id } = row.row.original.ticket;

    return <ManageResponsible ticketId={id} value={{ name: responsibleName, id: responsibleId }} />;
  }, []);

  const redirectToTicket = (id: string) => {
    closeDrawer('ticket-details');
    setTimeout(() => {
      history.push(`/backoffice/tickets?ticketId=${id}`);
    }, 300);
  };

  const renderTitle = useCallback(
    (id: string, isMonitor) => {
      if (isMonitor) {
        return (
          <>
            Ticket número: <LinkToTicket onClick={() => redirectToTicket(id)}>#{id}</LinkToTicket>
          </>
        );
      }

      return (
        <>
          Ticket número <b>#{id}</b>
        </>
      );
    },
    [ready, hasRole, roles],
  );

  const handleDetails = useCallback(
    (row) => {
      const { id } = row.row.original.ticket;
      selectTicketControl.start(id);

      openDrawer('ticket-details', <TicketDetails id={id} />, {
        title: renderTitle(id, hasRole('TICKET_MONITORING')),
        extraAction: <TicketOptions />,
        closable: true,
        width: 600,
      });
    },
    [openDrawer],
  );

  const handleAction = useCallback((row) => {
    const { id } = row.row.original.ticket;

    openDrawer('ticket-actions', <Actions id={id} />, {
      title: 'Alterar status do ticket',
      closable: true,
      width: 600,
    });
  }, []);

  const columns: TableColumns<TicketAssessmentSearch> = [
    {
      accessorKey: 'flag',
      header: () => '',
      cell: (row) => renderFlag(row),
    },
    {
      accessorKey: 'id',
      header: () => 'TICKET',
      cell: ({ row }) => <b>{row.original.ticket.id || '-'}</b>,
    },
    {
      accessorKey: 'ticketOpeningDate',
      header: () => 'DATA/HORA',
      cell: (row) => renderDate(row),
    },
    {
      accessorKey: 'personId',
      header: () => 'PERSON ID',
      cell: ({ row }) => renderPersonId(row.original.ticket.personId),
    },
    {
      accessorKey: 'contracts',
      header: () => 'CONTRACT ID',
      cell: ({ row }) => renderContractId(row.original.ticket.contracts),
    },
    {
      accessorKey: 'product',
      header: () => 'PRODUTO',
      cell: ({ row }) => LOAN_TYPE_NAMES[row.original.ticket.product] || '-',
    },
    {
      accessorKey: 'channel',
      header: () => 'CANAIS',
      cell: ({ row }) => row.original.ticket.channel || '-',
    },
    {
      accessorKey: 'resolvingArea',
      header: () => 'ÁREA RESOLVEDORA',
      cell: ({ row }) => row.original.ticket.resolvingArea || '-',
    },
    {
      accessorKey: 'reason',
      header: () => 'MOTIVO',
      cell: ({ row }) => row.original.ticket.reason || '-',
    },
    {
      accessorKey: 'ticketStatusId',
      header: () => 'STATUS',
      cell: ({ row }) =>
        renderStatus(row.original.ticket.ticketStatusId, row.original.ticket.statusTicket),
    },
    {
      accessorKey: 'responsibleName',
      header: () => 'RESPONSÁVEL',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (row) => renderResponsable(row),
    },
    {
      accessorKey: 'details',
      header: () => 'DETALHE',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (row) => (
        <Button icon="Eye" iconOnly variant="text" onClick={() => handleDetails(row)} />
      ),
    },
    {
      accessorKey: 'actions',
      header: () => 'AÇÕES',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (row) => (
        <Button icon="Pencil" iconOnly variant="text" onClick={() => handleAction(row)} />
      ),
    },
  ];

  return (
    <StyledTable
      noItemsMessage="Nenhuma informação encontrada"
      responsiveCols={[
        'flag',
        'id',
        'ticketOpeningDate',
        'personId',
        'contracts',
        'product',
        'reason',
        'groupStatus',
        'details',
        'actions',
      ]}
      loading={isLoadingTickets}
      columns={columns}
      data={data}
      maxHeight={580}
      shimmerLines={10}
      isTHeadFixed
    />
  );
}

export default TicketTable;
