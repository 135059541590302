import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Form, Input, Button, Select, Flex } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { reloadAllBaseUrls } from '~/common/utils/http';
import * as services from '~/services';

import {
  Actions,
  Container,
  EnvironmentList,
  EnvironmentText,
  Text,
} from './EnvironmentModal.styled';

const PRODUCTION = {
  backoffice: 'https://mt-backoffice-backend.prd.meutudo.app',
  formalization: 'https://formalization.prd.meutudo.app',
  gateway: 'https://gateway.meutudo.app',
  perm: 'https://mt-perm-levels.meutudo.app',
  telli: 'https://prod-backoffice.meutudo.app',
  ticket: 'https://ticket.prd.meutudo.app/ticket',
};

const HOMOLOG = {
  backoffice: 'https://mt-backoffice-backend.homolog.meutudo.app',
  formalization: 'https://formalization-scaling.homolog.meutudo.app',
  gateway: 'https://gateway.homolog.meutudo.app',
  perm: 'https://mt-perm-levels.homolog.meutudo.app',
  telli: 'https://scaling.homolog.meutudo.app',
  ticket: 'https://mt-ticket.homolog.meutudo.app/ticket',
};

const CANDIDATE = {
  backoffice: 'https://mt-backoffice-backend-candidate.homolog.meutudo.app',
  formalization: 'https://formalization-candidate.homolog.meutudo.app',
  gateway: 'https://gateway-candidate.homolog.meutudo.app',
  perm: 'https://mt-perm-levels.homolog.meutudo.app',
  telli: 'https://candidate.homolog.meutudo.app',
  ticket: 'https://mt-ticket.homolog.meutudo.app/ticket',
};

const CORE = {
  backoffice: 'https://mt-backoffice-backend.homolog.meutudo.app',
  formalization: 'https://formalization-core.homolog.meutudo.app',
  gateway: 'https://gateway.homolog.meutudo.app',
  perm: 'https://mt-perm-levels.homolog.meutudo.app',
  telli: 'https://core.homolog.meutudo.app',
  ticket: 'https://mt-ticket.homolog.meutudo.app/ticket',
};

const SCALING = {
  backoffice: 'https://mt-backoffice-backend.homolog.meutudo.app',
  formalization: 'https://formalization-scaling.homolog.meutudo.app',
  gateway: 'https://gateway.homolog.meutudo.app',
  perm: 'https://mt-perm-levels.homolog.meutudo.app',
  telli: 'https://scaling.homolog.meutudo.app',
  ticket: 'https://mt-ticket.homolog.meutudo.app/ticket',
};

const ENVIRONMENTS = { PRODUCTION, HOMOLOG, CANDIDATE, CORE, SCALING };

export const SERVICES_ENVIRONMENT_OPTIONS = [
  { label: 'Produção', value: 'PRODUCTION' },
  { label: 'Homologação', value: 'HOMOLOG' },
  { label: 'Candidate', value: 'CANDIDATE' },
  { label: 'Core', value: 'CORE' },
  { label: 'Scaling', value: 'SCALING' },
  { label: 'Outro', value: 'OTHER' },
];

export function EnvironmentModal() {
  const form = useRef<WrappedFormUtils>();
  const modal = useModal();

  const [selectedEnvironment, setSelectedEnvironment] = useState();
  const [loading, setLoading] = useState(false);
  const [endpoints] = useState(localStorage.getItem('@endpoints'));

  const handleReset = useCallback(() => {
    setLoading(true);
    localStorage.removeItem('@endpoints');
    localStorage.removeItem('@environment');
    reloadAllBaseUrls(services);
    setTimeout(() => window.location.reload(), 2000);
  }, []);

  const onSubmit = (values) => {
    if (!selectedEnvironment) return;
    setLoading(true);

    if (selectedEnvironment === 'OTHER') {
      const { environment, ...newServices } = values;
      localStorage.setItem('@endpoints', JSON.stringify(newServices));
      localStorage.setItem('@environment', selectedEnvironment);
      reloadAllBaseUrls(services);
    } else {
      localStorage.setItem('@endpoints', JSON.stringify(ENVIRONMENTS[selectedEnvironment]));
      localStorage.setItem('@environment', selectedEnvironment);
      setSelectedEnvironment(null);
      reloadAllBaseUrls(services);
    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleEnvironmentChange = ([value]) => {
    setSelectedEnvironment(value);
  };

  const inputs = useMemo(() => {
    const inputsList = [
      {
        id: 'environment',
        label: 'Informe a url do novo ambiente',
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        input: (
          <Select
            placeholder="Escolha o ambiente"
            options={SERVICES_ENVIRONMENT_OPTIONS}
            onChange={handleEnvironmentChange}
          />
        ),
      },
    ];

    if (selectedEnvironment === 'OTHER') {
      const servicesInputs = Object.keys(services).map((service) => ({
        id: service,
        label: `Informe a url do serviço ${service.toUpperCase()}:`,
        options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        initialValue: services[service].getBaseWithoutPath(),
        input: <Input allowClear />,
      }));

      inputsList.push(...servicesInputs);
    }

    return inputsList;
  }, [selectedEnvironment, services]);

  useEffect(() => {
    modal.setLoading('environment', loading);
  }, [loading]);

  return (
    <Container>
      <Flex direction="column" gap={16}>
        <Text>
          Altere o ambiente para qual o backoffice está apontando. Esta funcionalidade serve para
          testes e deve ser usada com cuidado.
        </Text>

        <EnvironmentText>
          Os serviços atuais são:
          <EnvironmentList>
            {Object.keys(services).map((service) => (
              <div key={service}>
                <strong>{service.toUpperCase()}:</strong> {services[service].defaults.baseURL}
              </div>
            ))}
          </EnvironmentList>
        </EnvironmentText>

        {selectedEnvironment && selectedEnvironment !== 'OTHER' && (
          <EnvironmentText>
            Os serviços ficarão assim após a alteração:
            <EnvironmentList>
              {Object.keys(ENVIRONMENTS?.[selectedEnvironment]).map((key) => (
                <div key={key}>
                  <strong>{key.toUpperCase()}:</strong> {ENVIRONMENTS?.[selectedEnvironment]?.[key]}
                </div>
              ))}
            </EnvironmentList>
          </EnvironmentText>
        )}

        <Form ref={form} onSubmit={onSubmit} inputs={inputs}>
          <Actions>
            <Button
              rounded
              fullWidth
              disabled={!endpoints}
              variant="outline"
              loading={loading}
              onClick={handleReset}
              type="button"
            >
              Retornar para padrão
            </Button>

            <Button
              rounded
              fullWidth
              loading={loading}
              color="black"
              disabled={!selectedEnvironment}
            >
              Alterar ambiente
            </Button>
          </Actions>
        </Form>
      </Flex>
    </Container>
  );
}
