import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Button, InfoGroup, Input, Typography } from '~/ui/components';
import paperPlane from '~/assets/images/paperPlane.png';
import { useAwaitControl } from 'react-redux-await-control';
import { createSegment, processSegment } from '~/store/courier/actions';
import { useModal } from '~/hooks/useModal';
import { ListType } from '../List/List';
import { Content, Divider, Imagem, Section, Wrapper } from './SubmissionContent.styled';

interface SubmissionContentProps {
  cohort: ListType;
}

export function SubmissionContent({ cohort }: SubmissionContentProps) {
  const { closeAll } = useModal();
  const [value, setValue] = useState('');

  const createSegmentControl = useAwaitControl(createSegment);
  const processSegmentControl = useAwaitControl(processSegment);

  const segmentCreatedSuccessfully = createSegmentControl.isSuccessful();

  const handleOnClick = useCallback((cohortStrategyId, volume) => {
    const payload = { volume };

    createSegmentControl.start({ cohortStrategyId, payload });
  }, []);

  useEffect(() => {
    if (segmentCreatedSuccessfully) {
      processSegmentControl.start({ id: cohort?.id });
      closeAll();
      createSegmentControl.clear();
    }
  }, [segmentCreatedSuccessfully, cohort]);

  return (
    <Wrapper>
      <Section justify="center">
        <Imagem width={142} height={127} src={paperPlane} alt="" />
      </Section>
      <Section>
        <Typography type="paragraphSmall" weight={700}>
          Informações do envio
        </Typography>
        <Typography type="caption">Insira o volume que deseja enviar</Typography>
        <Content>
          <Input placeholder="Volume disponível" value={cohort?.currentAvailableVolume} disabled />
          <Input
            placeholder="Volume a disparar"
            onChange={(value) => setValue(value)}
            max={cohort?.currentAvailableVolume}
            min={0}
            type="number"
            number
          />
        </Content>
        {Number(value) > 0 && (
          <Alert label="Atenção! Esta ação não poderá ser desfeita" status="warning" />
        )}
      </Section>
      <Section justify="space-between">
        <InfoGroup label="Grupo" value={cohort?.groupName} />
        <InfoGroup label="Estratégia" value={cohort?.strategyName} />
        <InfoGroup label="Fonte de dados" value={cohort?.cohortMethod} />
      </Section>
      <Divider />
      <Section justify="end">
        <Button variant="outline" rounded size="md" onClick={closeAll}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          rounded
          size="md"
          disabled={!value || Number(value) === 0}
          onClick={() => handleOnClick(cohort?.id, value)}
        >
          Enviar Segmento
        </Button>
      </Section>
    </Wrapper>
  );
}
