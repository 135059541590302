import React from 'react';

import {
  Container,
  Label,
  Info,
  EventList,
  Event,
  Dot,
  Line,
  IconContent,
} from './Timeline.styled';

export type EventType = {
  label: string;
  value: string;
  completed?: boolean;
};

interface TimelineProps {
  events: EventType[];
}

export function Timeline({ events }: TimelineProps) {
  return (
    <Container>
      <EventList data-testid="time-line-events">
        {events.map((event, index) => (
          <Event key={index} isConected={index + 1 < events.length}>
            <IconContent>
              <Dot filled={event?.completed} />
              <Line isConected={index + 1 < events.length} filled={event?.completed} />
            </IconContent>
            <Label>{event.label}</Label>
            <Info>{event.value}</Info>
          </Event>
        ))}
      </EventList>
    </Container>
  );
}
