import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useHistory } from 'react-router';
import { CardMobileActions } from '~/components/ProfileResume/MobileActions/CardMobileActions';

import type { CardDetails as ICardDetails, Invoice, Transaction } from '~/typings/entities/Card';
import { getContractsOfPerson, getPersonDetail, getPersonImageUrl } from '~/store/people/actions';
import { getCardDetails } from '~/store/cards/actions';
import screenRegister from '~/hoc/screenRegister';
import { Breadcrumb, Button, Content, Flex, Typography } from '~/ui/components';
import { ProfileWithActions, ResponsiveMainActions } from '~/components';
// import InvoicesTable from '~/screens/Card/components/InvoicesTable/InvoicesTable';

import SideBarActions from './SideBarActions/SideBarActions';
// import CardStatment from './Drawers/CardStatment/CardStatment';
// import LifeInsurance from './Drawers/LifeInsurance/LifeInsurance';
import TransactionTable from './components/TransactionTable/TransactionTable';
import { DetailsSection, Section, StyledLink, Wrapper } from './CardDetails.styles';
import Details from './Details/Details';
import Invoices from './components/Invoices/Invoices';

type Card = {
  personId: number;
  product: string;
  card: ICardDetails;
  transactionHistory: Transaction[];
  invoiceHistory: Invoice[];
};

type CardDetailsProps = {
  match: any;
};

function CardDetails({ match }: CardDetailsProps) {
  const history = useHistory();
  const { contractId } = match.params;

  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);

  const getPersonImageControl = useAwaitControl(getPersonImageUrl);
  const avatarImage = getPersonImageControl.result('FACE_FRONT');

  const getCardControl = useAwaitControl(getCardDetails);
  const cardDetails: Card = getCardControl.result();
  const cardDetailsLoading = getCardControl.isRunning();
  const cardDetailsError = getCardControl.hasFailure();

  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const person = getPersonDetailsControl.result();
  const personDetailsLoading = getPersonDetailsControl.isRunning();

  const contractUrl = useMemo(
    () => `/backoffice/people/${cardDetails?.personId}?aux=contratos`,
    [cardDetails],
  );

  const loading = useMemo(
    () => personDetailsLoading || cardDetailsLoading,
    [personDetailsLoading, cardDetailsLoading],
  );

  useEffect(() => {
    if (cardDetails?.personId && person?.id !== cardDetails?.personId) {
      getPersonDetailsControl.start({ id: cardDetails?.personId });
      getContractsOfPersonControl.start({ id: cardDetails?.personId });
    }
  }, [cardDetails]);

  useEffect(() => {
    if (cardDetails?.personId && !avatarImage) {
      getPersonImageControl.start(
        { id: cardDetails.personId, type: 'FACE_FRONT' },
        { actionId: 'FACE_FRONT' },
      );
    }
  }, [cardDetails, avatarImage]);

  useEffect(() => {
    getCardControl.start({ id: contractId });
  }, []);

  return (
    <>
      <ProfileWithActions loading={loading} person={person} avatarImage={avatarImage}>
        <SideBarActions loading={loading} />
      </ProfileWithActions>

      <Content>
        <ResponsiveMainActions
          person={person}
          loading={loading}
          avatarImage={avatarImage}
          responsiveActions={<CardMobileActions />}
        />

        {cardDetailsError ? (
          <Flex width="100%" height="40vh" direction="column" align="center" justify="center">
            <p>Não foi possível encontrar dados do cartão</p>

            <Button variant="outline" onClick={() => history.goBack()} rounded size="sm">
              Voltar
            </Button>
          </Flex>
        ) : (
          <>
            <DetailsSection>
              <Breadcrumb
                items={[{ label: 'Contratos', href: contractUrl }, { label: 'Cartão benefício' }]}
              />
              <div>
                <Details details={cardDetails?.card} loading={loading} />
              </div>
            </DetailsSection>

            <Section>
              <Wrapper>
                <Typography type="paragraphLarge" weight={600}>
                  Histórico de transações
                </Typography>
                <StyledLink
                  disabled={loading}
                  to={`/backoffice/card/${contractId}/transaction-history`}
                  data-testid="transaction-history-show-all-button"
                >
                  Mostrar tudo
                </StyledLink>
              </Wrapper>

              <TransactionTable tableData={cardDetails.transactionHistory} loading={loading} />
            </Section>

            <Section>
              <Flex justify="space-between" align="center">
                <Typography type="paragraphLarge" weight={600}>
                  Histórico de faturas
                </Typography>
              </Flex>
              <Invoices person={person} loading={loading} />
            </Section>
          </>
        )}
      </Content>
    </>
  );
}

export default screenRegister({
  screenName: 'CardDetails',
  path: '/backoffice/card/:contractId',
  headerTitle: 'Card',
  customLayout: true,
})(CardDetails);
