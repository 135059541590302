import React, { memo } from 'react';

import { Popover } from '~/ui/components/Popover/Popover';
import { ArrowRightCircle } from '~/ui/assets/icons';
import toMoney from '~/common/masked/toMoney';
import { formatDate } from '~/common/formatDate';
import { Caret } from '~/ui/components';
import { MarginInfo } from '~/typings/entities/Enrollment';
import { Label, ListWithArrow, UpdateDate, Value } from './Popovers.styled';

interface MarginDetailsProps {
  data: MarginInfo;
}

function MarginDetails({ data }: MarginDetailsProps) {
  return (
    <Popover
      placement="bottom"
      trigger="click"
      title="Margens do empréstimo"
      content={
        <ListWithArrow>
          <li>
            <ArrowRightCircle width={16} height={16} />
            <div>
              <Label>Margem calculada</Label>
              <Value>{toMoney(data?.computedMarginLoan)}</Value>
              <UpdateDate>Última atualização: {formatDate(data?.marginUpdateDate)}</UpdateDate>
            </div>
          </li>
          <li>
            <ArrowRightCircle width={16} height={16} />
            <div>
              <Label> Margem empréstimo</Label>
              <Value>{toMoney(data?.marginLoan)}</Value>
              <UpdateDate>Última atualização: {formatDate(data?.marginUpdateDate)}</UpdateDate>
            </div>
          </li>
          <li>
            <ArrowRightCircle width={16} height={16} />
            <div>
              <Label> Última de cartão</Label>
              <Value>{toMoney(data?.marginCard)}</Value>
              <UpdateDate>Última atualização: {formatDate(data?.marginUpdateDate)}</UpdateDate>
            </div>
          </li>
        </ListWithArrow>
      }
    >
      <Label>Detalhes da margem</Label>
      <Caret />
    </Popover>
  );
}

export default memo(MarginDetails);
