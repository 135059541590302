import React, { memo } from 'react';
import { useTheme } from 'styled-components';

import { Person } from '~/typings/entities/person';
import { LOAN_TYPES } from '~/typings/enums/LoanType';
import { Dots } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { Enrollment } from '~/typings/entities/Enrollment';
import Content from './Content';

interface ActionsProps {
  personDetails: Person;
  enrollment: Enrollment;
}

function Actions({ personDetails, enrollment }: ActionsProps) {
  // TODO: melhorar essa lógica quando o endpoint retornar a KEY do produto/convênio. Atualmente só retorna a string traduzida.
  const disabled = ['FGTS', 'Consignado Privado'].includes(enrollment.product);

  const theme = useTheme();

  return (
    <Popover
      width={250}
      placement="bottom"
      noHeader
      trigger="hover"
      content={<Content personDetails={personDetails} enrollment={enrollment} />}
      destroyTooltipOnHide
      disabled={disabled}
      noArrow
      id="enrollmentCollapse"
    >
      <Dots
        data-testid="enrollment-actions"
        color={disabled ? theme.colors.gray[900] : theme.colors.primary.main}
      />
    </Popover>
  );
}

export default memo(Actions);
