import React, { useCallback } from 'react';

import { Button, InfoGroup } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { Container, Content, InfoValue, Label, Section, Wrapper } from './StrategyDetails.styled';
import { Timeline } from '../../components/Timeline/Timeline';

type StragetyType = {
  id: number;
  name: string;
  taskQueueSid: string;
  contentSid: string;
  query: string;
  rules: string[];
  retryPolicy: {
    id: number;
    distance: number;
    interactionAbortsRetries: boolean;
    dispatchesAllowed: {
      id: number;
      retryAttempt: number;
      retryContent: string;
    }[];
  };
  amplitudeCohortId: string;
  insiderCohortId: string;
  agreement: string;
  enableCallback: boolean;
  maxDispatchTime: string;
  groupName: string;
};

interface StrategyDetailsProps {
  strategy: StragetyType;
}

export function StrategyDetails({ strategy }: StrategyDetailsProps) {
  const { openModal } = useModal();

  const renderTimeLine = (segments, contentSid) => [
    { label: 'Disparo inicial - ID', value: `<${contentSid}>`, completed: false },
    ...segments.map((item) => ({
      label: `Redisparo ${item?.retryAttempt} - ID`,
      value: `<${item?.retryContent}>`,
      completed: false,
    })),
  ];

  const renderRules = useCallback((rules) => {
    if (rules?.length < 1) return '-';

    return (
      <Wrapper>
        {rules?.map((rule) => (
          <InfoValue key={rule?.id}>{rule?.name}</InfoValue>
        ))}
      </Wrapper>
    );
  }, []);

  const handleDataSource = useCallback((strategy) => {
    openModal(
      <Section>
        <InfoGroup
          label="AURORA"
          value={strategy?.query && strategy?.query.length >= 1 ? strategy?.query : '-'}
        />
        <InfoGroup
          label="Insider"
          value={
            strategy?.insiderCohortId && strategy?.insiderCohortId.length >= 1
              ? strategy?.insiderCohortId
              : '-'
          }
        />
        <InfoGroup
          label="Amplitude"
          value={
            strategy?.amplitudeCohortId && strategy?.amplitudeCohortId.length >= 1
              ? strategy?.amplitudeCohortId
              : '-'
          }
        />
      </Section>,
      {
        title: `Fonte de dados - COHORT ID`,
        id: 'data-source-modal',
        closable: true,
        width: 540,
      },
    );
  }, []);

  const renderCohortId = useCallback((strategy) => {
    if (!strategy?.query && !strategy?.insiderCohortId && !strategy?.amplitudeCohortId) return '-';

    return (
      <Wrapper row>
        {strategy?.amplitudeCohortId && <InfoValue>Amplitude</InfoValue>}
        {strategy?.insiderCohortId && <InfoValue>Insider</InfoValue>}
        {strategy?.query && <InfoValue>AURORA</InfoValue>}
        <Button
          variant="text"
          rounded
          size="sm"
          onClick={() => handleDataSource(strategy)}
          type="button"
        >
          Ver fonte de dados
        </Button>
      </Wrapper>
    );
  }, []);

  return (
    <Container>
      <Content marginTop>
        <InfoGroup label="Grupo" value={strategy?.groupName} />
        <InfoGroup label="Estratégia" value={strategy?.name} />
        <InfoGroup label="ID da estratégia" value={strategy?.id} />
      </Content>

      <Section>
        <Label>Redisparos programados</Label>
        <InfoGroup
          label="Frequência dos redisparos"
          value={`à cada ${strategy?.retryPolicy?.distance} min`}
        />
        <Timeline
          events={renderTimeLine(strategy?.retryPolicy?.dispatchesAllowed, strategy?.contentSid)}
        />
      </Section>

      <Section>
        <Label>Informações adicionais</Label>
        <Content marginTop>
          <InfoGroup label="Convênio" value={strategy?.agreement} />
          <InfoGroup label="Regras" value={renderRules(strategy?.rules)} />
        </Content>
        <InfoGroup label="Fonte de dados" value={renderCohortId(strategy)} />
        <InfoGroup label="Fila" value={strategy?.taskQueueSid} />
        <Content marginTop>
          <InfoGroup
            label="Cancelar redisparos após interacao?"
            value={strategy?.retryPolicy?.interactionAbortsRetries ? 'sim' : 'não'}
          />
          <InfoGroup
            label="Ligação quando não tiver interação"
            value={strategy?.enableCallback ? 'sim' : 'não'}
          />
          <InfoGroup label="Horário limite para os disparos" value={strategy?.maxDispatchTime} />
        </Content>
      </Section>
    </Container>
  );
}
