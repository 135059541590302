import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { OpportunityListItem, OpportunityStatus } from '~/typings/entities/Opportunity';
import { getOpportunities } from '~/store/opportunities/actions';
import toMoney from '~/common/masked/toMoney';
import LoanType, { LOAN_TYPES } from '~/typings/enums/LoanType';
import { Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';
import { getPersonDetail, reprovePersonRegistry } from '~/store/people/actions';

import {
  FGTSOpportunityStatus,
  FGTSOpportunityStatusColors,
  INSSOpportunityStatus,
  INSSOpportunityStatusColors,
  PortabilityStatus,
  PortabilityStatusColors,
} from '~/typings/enums/Opportunity';
import { useTracking } from '~/hooks/useTracking';
import { ampli } from '~/ampli';
import { CellWrapper, FlexWrapper } from '../Tabs.styled';
import { DetailsButton } from './Opportunities.styled';
import { Opportunity } from '../../Drawers/Opportunity/Opportunity';

export function Opportunities() {
  const { openDrawer } = useDrawer();
  const opportunitiesControl = useAwaitControl(getOpportunities);
  const personControl = useAwaitControl(getPersonDetail);
  const reprovePersonRegistryControl = useAwaitControl(reprovePersonRegistry);
  const reproveSuccess = reprovePersonRegistryControl.isSuccessful();
  const opportunities: OpportunityListItem[] = opportunitiesControl.result();
  const opportunitiesLoading = opportunitiesControl.isRunning();
  const personLoading = personControl.isRunning();
  const personDetails = personControl.result();

  const loading = opportunitiesLoading || personLoading;
  const { handleTracking } = useTracking();

  const isPortability = (loanType: string) => loanType === LOAN_TYPES.PORTABILITY;

  const isINSS = (loanType: string) => {
    const INSSLoans = [
      LOAN_TYPES.NEW,
      LOAN_TYPES.PORTABILITY,
      LOAN_TYPES.PORTABILITYWITHOUTREFIN,
      LOAN_TYPES.REFIN,
      LOAN_TYPES.FUTUREMARGIN,
    ];
    return INSSLoans.includes(loanType);
  };

  const renderStatus = useCallback((loanType, statusKey) => {
    if (isINSS(loanType)) {
      if (isPortability(loanType)) {
        return PortabilityStatus[statusKey];
      }
      return INSSOpportunityStatus[statusKey];
    }

    return FGTSOpportunityStatus[statusKey];
  }, []);

  const renderStatusColor = useCallback((loanType, statusKey) => {
    if (isINSS(loanType)) {
      if (isPortability(loanType)) {
        return PortabilityStatusColors[statusKey];
      }
      return INSSOpportunityStatusColors[statusKey];
    }

    return FGTSOpportunityStatusColors[statusKey];
  }, []);

  const isDetailsDisabled = (loanType, portableContracts, status) => {
    if (isPortability(loanType)) {
      return portableContracts?.[0]?.simulationPortabilityType !== 'UPDATED_HISCON';
    }

    const notDisabled = ['OK', 'PORT_OUT'];

    return !notDisabled.includes(status);
  };

  const handleClickDetails = (opportunity: OpportunityListItem) => {
    const event = ampli.simulacaoIniciouSimulacao({
      titulo_aba: 'Oportunidade',
      cliente_person_id: personDetails.enrollmentId,
      cliente_convenio: personDetails.agreementName,
      cliente_oportunidade: personDetails.isOpportunity ?? false,
    });

    handleTracking(() => event);

    openDrawer('opportunity-details', <Opportunity opportunity={opportunity} />, {
      width: 460,
      title: `Detalhes da oportunidade`,
      closable: true,
      destroyOnClose: true,
      noPadding: true,
    });
  };

  const renderLabelWithInfo = useCallback(
    (label, info) => (
      <FlexWrapper>
        <CellWrapper>{label ?? '-'}</CellWrapper>
        {info && <Tooltip content={info} placement="bottomLeft" maxWidth="350px" />}
      </FlexWrapper>
    ),
    [],
  );

  const columns: TableColumns<OpportunityListItem> = [
    {
      accessorKey: 'date',
      header: () => 'DATA',
      cell: (info) => {
        const date = info.getValue();
        return date ? moment(date).format('DD/MM/YYYY [às] HH:mm') : '-';
      },
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId));
        const dateB = moment(rowB.getValue(columnId));
        return dateA.isBefore(dateB) ? -1 : 1;
      },
    },
    {
      accessorKey: 'loanType',
      header: () => 'PRODUTO',
      cell: (info) => {
        const productKey = info.getValue();
        return productKey ? LoanType(productKey) : '-';
      },
    },
    {
      accessorKey: 'status',
      header: () => 'STATUS',
      cell: (info) => {
        const { loanType, portableContracts } = info.row.original;

        const statusKey = isPortability(loanType)
          ? portableContracts?.[0]?.simulationPortabilityType
          : info.getValue<OpportunityStatus>();

        const status = renderStatus(loanType, statusKey);
        const colors = renderStatusColor(loanType, statusKey);

        return (
          <Tag small rounded bgColor={colors?.bgColor} textColor={colors?.textColor}>
            {status}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'benefitCode',
      header: () => 'COD DO BENEFÍCIO',
      minSize: 80,
      cell: (info) => renderLabelWithInfo(info.row.original.workCode, info.row.original.workName),
    },
    {
      accessorKey: 'loanMaxValue',
      header: () => 'VALOR',
      cell: (info) => {
        const value = info.getValue();
        return value ? `Até ${toMoney(value)}` : '-';
      },
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => {
        const opportunity = row.original;
        const { loanType, portableContracts, status } = row.original;
        const isDisabled = isDetailsDisabled(loanType, portableContracts, status);

        return (
          <DetailsButton
            iconOnly
            icon="Eye"
            variant="text"
            disabled={isDisabled}
            onClick={() => handleClickDetails(opportunity)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (reproveSuccess) {
      opportunitiesControl.clear();
    }
  }, [reproveSuccess]);

  return (
    <Table
      noItemsMessage="Nenhuma oportunidade encontrada"
      loading={!opportunities?.length && loading}
      responsiveCols={['date', 'status', 'loanType', 'loanMaxValue', 'details']}
      data={opportunities}
      columns={columns}
    />
  );
}
