import styled from 'styled-components';
import { Form } from '~/ui/components';

export const StyledForm = styled(Form)`
  max-width: 740px;
  overflow: auto;

  .input-list {
    max-width: 717px;
    max-height: 685px;
    overflow: ${({ overflow }) => overflow || 'auto'};
    padding-right: 12px;

    @media (max-width: 1220px) {
      max-height: 420px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .ant-form-explain {
    position: absolute;
    margin-top: 0px;
  }

  .extra-content {
    align-self: end;
    margin-right: 24px;
  }
`;

export const Flex = styled.div`
  display: flex;

  @media (max-width: 1220px) {
    flex-direction: column;
    gap: 16px;
    align-items: center;

    #id {
      order: 1;
    }

    #form {
      order: 2;
    }
  }
`;
