import React from 'react';
import { Link } from 'react-router-dom';
import { AGREEMENT_NAMES } from '~/common/constants';
import { cellToDate } from '~/common/utils/table';

import { Person } from '~/typings/entities/person';
import { CellProps, Flex, Table, TableColumns, Tooltip, Typography } from '~/ui/components';
import toPersonDocument from '~/common/masked/toPersonDocument';
import toMoney from '~/common/masked/toMoney';
import toMoneyFloor from '~/common/masked/toMoneyFloor';
import ContractStatus from '~/typings/enums/ContractStatus';
import MarginSource from '~/typings/enums/MarginSource';
import { Contract } from '~/typings/entities/contract';
import LoanType from '~/typings/enums/LoanType';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { ContractsTableContainer } from './ContractsTable.styled';

type ContractsTableProps = {
  data: Person[];
  loading?: boolean;
};

export function ContractsTable({ data, loading }: ContractsTableProps) {
  const { contractSearchColumns } = remoteValues;

  function cellToLoanType(props: CellProps<Contract>) {
    const { agreementId, loanType, suggestedOfferType } = props.row.original;

    const loanTypeName = LoanType({ value: suggestedOfferType || loanType });

    if (agreementId) {
      return (
        <Flex direction="column" align="center">
          {loanTypeName} <br />
          <Typography type="bodySmall" color="element.secondary">
            <small>{AGREEMENT_NAMES?.[agreementId]}</small>
          </Typography>
        </Flex>
      );
    }

    return loanTypeName;
  }

  const columns: TableColumns<Contract> = [
    {
      accessorKey: 'id',
      header: () => 'COD',
      cell: ({ row }) => (
        <Link to={`/backoffice/contracts/${row.original.id}`}>{row.original.id}</Link>
      ),
    },
    {
      accessorKey: 'personName',
      header: () => 'NOME',
      minSize: 200,
    },
    {
      accessorKey: 'contractInitialDate',
      header: () => 'DATA INICIAL',
      cell: cellToDate('DD/MM/YYYY'),
    },
    {
      accessorKey: 'consigneeName',
      header: () => 'BANCO',
      minSize: 100,
    },
    {
      accessorKey: 'personDocument',
      header: () => 'CPF',
      cell: ({ getValue }) => toPersonDocument(getValue()),
    },
    {
      accessorKey: 'loanStatus',
      header: () => 'STATUS',
      cell: ({ getValue }) => ContractStatus(getValue()),
    },
    {
      accessorKey: 'contractStatus',
      header: () => 'STATUS LÓGICO',
      maxSize: 170,
      ellipsis: true,
      cell: ({ row }) => {
        let status;
        if (row.original.contractStatus2) {
          status = `${row.original.contractStatus} - ${row.original.contractStatus2}`;
        } else {
          status = row.original.contractStatus;
        }

        return (
          <Tooltip placement="bottomLeft" content={status} small maxWidth={400}>
            {status}
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'operationStep',
      header: () => 'PASSO NA ESTEIRA',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => getValue() || '-',
    },
    {
      accessorKey: 'netValue',
      header: () => 'VALOR LÍQUIDO',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoney(getValue()),
    },
    {
      accessorKey: 'valueForDeposit',
      header: () => 'VALOR DEPÓSITO',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoney(getValue()),
    },
    {
      accessorKey: 'installmentValue',
      header: () => 'VALOR PARCELA',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoney(getValue()),
    },
    {
      accessorKey: 'numberOfInstallments',
      header: () => 'PARCELAS',
      headerAlign: 'center',
      cellAlign: 'center',
    },
    {
      accessorKey: 'flatCommissioning',
      header: () => 'COMISSÃO FIXA',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoneyFloor(getValue() || 0),
    },
    {
      accessorKey: 'deferredCommissioning',
      header: () => 'COMISSÃO DIF',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoneyFloor(getValue() || 0),
    },
    {
      accessorKey: 'flatCommissioningRefinPortability',
      header: () => 'COMISSÃO FIXA REFIN PORT',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoneyFloor(getValue() || 0),
    },
    {
      accessorKey: 'deferredCommissioningRefinPortability',
      header: () => 'COMISSÃO DIF REFIN PORT',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => toMoneyFloor(getValue() || 0),
    },
    {
      accessorKey: 'contractOwner',
      header: () => 'ATENDENTE',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => getValue() || '-',
    },
    {
      accessorKey: 'sentToConsignee',
      header: () => 'ENVIADO',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => (getValue() ? 'Sim' : 'Não'),
    },
    {
      accessorKey: 'loanType',
      header: () => 'TIPO',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: cellToLoanType,
    },
    {
      accessorKey: 'isPrime',
      header: () => 'CLIENTE PRIME',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => (getValue() ? 'Sim' : 'Não'),
    },
    {
      accessorKey: 'marginSource',
      header: () => 'FONTE MARGEM',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => MarginSource(getValue()) || '-',
    },
    {
      accessorKey: 'marginExpirationDate',
      header: () => 'VALIDADE MARGEM',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: cellToDate('DD/MM/YYYY'),
    },
    {
      accessorKey: 'paymentDate',
      header: () => 'DATA PAGAMENTO',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: cellToDate('DD/MM/YYYY'),
    },
    {
      accessorKey: 'paymentDateRefPort',
      header: () => 'DATA PAGAMENTO PORT',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: cellToDate('DD/MM/YYYY'),
    },
  ];

  let filteredColumns = columns;
  const defaultSearchColumns =
    'id,personName,contractInitialDate,consigneeName,personDocument,loanStatus,contractStatus,sentToConsignee,loanType';

  try {
    const columnsFilter =
      localStorage.getItem('contractSearchColumns') ||
      contractSearchColumns ||
      defaultSearchColumns;
    if (columnsFilter && columnsFilter !== 'all') {
      const columnsToFilter = columnsFilter.split(',');
      filteredColumns = columns.filter((col: any) =>
        columnsToFilter.includes(col.id || col.accessorKey),
      );
    }
  } catch (e) {
    // There is no error handling
  }

  return (
    <ContractsTableContainer>
      <Table
        noItemsMessage="Nenhum contrato encontrado"
        loading={loading}
        columns={filteredColumns}
        data={data}
        maxHeight={600}
        isTHeadFixed
      />
    </ContractsTableContainer>
  );
}
