import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Button, Typography } from '~/ui/components';

export * from './AboutClient';
export * from './Contracts';
export * from './GeneralHistory/GeneralHistory';
export * from './Pendencies';
export * from './Resets/Resets';
export * from './Tickets/Tickets';
export * from './Opportunities/Opportunities';

export const TabHeader = styled.div`
  margin: 30px 28px 0;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 16px;
`;

export const TabHeaderTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h5',
  weight: 500,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  margin-bottom: 4px;
  display: flex;
`;

export const TabHeaderText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  width: 381,
  color: theme.colors.element.secondary,
}))``;

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))``;
