import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Profile } from '~/components';

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchSection = styled.div`
  padding: 5px 0px 9px 0px;

  div {
    border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};
    background-color: ${themeColor('background.primary')};
    border-radius: 8px;
    height: 40px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  margin-bottom: 0px;
`;

type ItemProps = {
  spaceBetween?: boolean;
};

export const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  color: ${themeColor('element.primary')};
  gap: 12px;

  :hover {
    background-color: ${themeColor('background.secondary')};
  }

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
  }

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
      cursor: auto;
    `}
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Avatar = styled(Profile)`
  background-color: ${({ theme }) => theme.colors.brand.secondary};

  span {
    color: ${({ theme }) => theme.colors.brand.primary};
    font-size: 14px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;
