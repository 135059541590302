import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { courier } from '~/services';

export const getCourierList = createAsyncAction('GET_GOURIER_LIST', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => courier.get(`/cohort/all`),
    responseParser: ({ data }) => data,
    onRequestError: () => message.error('Falha ao carregar listagem do courier'),
  },
});

export const cancelCohort = createAsyncAction('CANCEL_COHORT', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id }) => courier.post(`/dispatch/block/${id}`),
    onRequestError: () => message.error('Falha ao cancelar cohort'),
    onRequestSuccess: () => message.success('Cohort cancelado com sucesso!'),
  },
});

export const createDispatchOrder = createAsyncAction('CREATE_DISPATCH_ORDER', {
  saveResult: true,
  initialValue: [],
  context: {
    request: (payload: { cohortMethod: string; strategy: string }) =>
      courier.post(`/dispatch/order`, payload),
    onRequestError: () => message.error('Falha ao criar ordem de disparo'),
    onRequestSuccess: () => message.success('Ordem de disparo criada com sucesso!'),
  },
});

export const getCourierStrategies = createAsyncAction('GET_GOURIER_STRATEGIES', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => courier.get(`/strategies`),
    responseParser: ({ data }) => data,
    onRequestError: () => message.error('Falha ao carregar estratégias do courier'),
  },
});

export const createSegment = createAsyncAction('CREATE_SEGMENT', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ cohortStrategyId, payload }) =>
      courier.post(`/dispatch/free/${cohortStrategyId}`, payload),
    onRequestError: () => message.error('Falha ao criar segmento'),
    onRequestSuccess: () => message.success('Segmento enviado com sucesso!'),
  },
});

export const processSegment = createAsyncAction('PROCESS_SEGMENT', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id }) => courier.post(`/cohort/process/${id}`),
  },
});
