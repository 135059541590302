import React, { memo } from 'react';

import { Button, Popover } from '~/ui/components';
import { Avatar } from './ManageResponsible.styles';
import ManageResponsibleContent from './ManageResponsibleContent';

type OptionsType = {
  name: string;
  id: number;
};

interface ManageResponsibleProps {
  ticketId: number;
  value?: OptionsType;
}

function ManageResponsible({ ticketId, value }: ManageResponsibleProps) {
  return (
    <Popover
      placement="bottom"
      trigger="click"
      noHeader
      width={280}
      content={<ManageResponsibleContent ticketId={ticketId} value={value} />}
    >
      {value && <Avatar small title={value.name} />}
      {!value && <Button variant="text" color="brand" title="Adicionar +" />}
    </Popover>
  );
}

export default memo(ManageResponsible);
