// @flow

import React from 'react';
import { Container, ImageContainer } from './styled';

export type ProfileProps = {
  title?: string,
  subtitle?: string,
  image: string,
  small?: boolean,
  pointer?: boolean,
};

function Profile(props: ProfileProps) {
  const { image, title } = props;

  function getInitials() {
    if (!title) return '-';
    const names = title?.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return (
    <Container pointer={props.pointer}>
      <ImageContainer {...props}>
        {image && <img src={image} alt={title} />}
        {!image && <span>{getInitials()}</span>}
      </ImageContainer>
    </Container>
  );
}

export default Profile;
