import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 11%;
  margin-top: 24px;

  @media (max-width: 1400px) {
    gap: 20px 5%;
  }

  @media (max-width: 1285px) {
    gap: 20px 1%;
  }

  @media (max-width: 1200px) {
    gap: 20px 5%;
  }

  @media (max-width: 965px) {
    gap: 20px 1%;
  }

  @media (max-width: 885px) {
    gap: 20px 5%;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

type FlexProps = {
  marginTop: boolean;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 20px;
    `};
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: themeToggleColor(theme, 'element.primary'),
}))`
  width: max-content;
`;

export const Value = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: themeToggleColor(theme, 'element.primary'),
  weight: 600,
}))`
  width: max-content;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TextAlign = styled.div`
  display: flex;
  align-items: center;

  svg {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
