import styled, { css } from 'styled-components';

import { themeColor } from '~/common/utils/theme';
import { Profile } from '~/components';

export const Wrapper = styled.div`
  position: relative;
  gap: 8px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

type SelectProps = {
  disabled?: boolean;
  isOpen?: boolean;
  hasSelection?: boolean;
  pictured?: boolean;
};

export const Select = styled.div<SelectProps>`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: 8px;
  padding-inline: 12px;
  height: 56px;
  min-width: max-content;
  border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  #closeCircle {
    display: none;
  }

  ${({ hasSelection, pictured }) =>
    hasSelection &&
    css`
      #placeholder {
        width: max-content;
        left: ${pictured ? 47 : 12}px;
        font-size: 13px;
        color: ${({ theme }) => theme.colors.element.secondary};
      }

      #label {
        left: ${hasSelection && pictured ? 47 : 12}px;
      }
    `}

  :hover {
    background-color: ${themeColor('background.secondary')};

    ${({ hasSelection }) =>
      hasSelection &&
      css`
        #caretDown {
          display: none;
        }

        #closeCircle {
          display: block;
        }
      `}
  }

  ${({ isOpen, hasSelection, pictured }) =>
    isOpen &&
    css`
      border-color: ${themeColor('brand.primary')};
      background-color: ${themeColor('background.secondary')};

      #caretDown {
        transform: rotate(180deg);
      }

      #placeholder {
        width: max-content;
        top: 7px;
        left: ${hasSelection && pictured ? 47 : 12}px;
        font-size: 13px;
      }
    `}
`;

type SelectLabelProps = {
  minWidth?: number;
};

export const SelectLabel = styled.div<SelectLabelProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: ${({ minWidth }) => minWidth ?? 178}px;
  height: 100%;
`;

export const Placeholder = styled.label`
  color: ${themeColor('element.placeholder')};
`;

type LabelProps = {
  maxWidth?: number;
};

export const Label = styled.span<LabelProps>`
  color: ${themeColor('element.primary')};
  top: 27px;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 5px;
  padding-block: 9px;
  border-radius: 12px;
  background-color: ${themeColor('background.primary')};
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  max-height: 279px;
  z-index: 10;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 0px;
`;

type ItemProps = {
  selected: boolean;
};

export const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  cursor: pointer;
  color: ${themeColor('element.primary')};
  gap: 12px;

  :hover {
    background-color: ${themeColor('background.secondary')};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${themeColor('background.secondary')};
    `}
`;

export const SearchSection = styled.div`
  padding: 5px 12px 9px 12px;

  div {
    border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};
    background-color: ${themeColor('background.primary')};
    border-radius: 8px;
    height: 40px;
  }
`;

export const Avatar = styled(Profile)`
  background-color: ${({ theme }) => theme.colors.brand.secondary};

  span {
    color: ${({ theme }) => theme.colors.brand.primary};
    font-size: 14px;
  }
`;

type ValueProps = {
  hasValue?: boolean;
};

export const Value = styled.div<ValueProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ hasValue }) =>
    hasValue &&
    css`
      flex-direction: column;
      gap: 2px;
      align-items: start;
      justify-content: center;
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;