import { createAsyncAction } from 'react-redux-await-control';
import { ticket as ticketApi } from '~/services';
import { message } from 'antd';
import { CreateTicket, TicketComment } from '~/typings/entities/Ticket';
import { handleErrors } from '~/common/utils/errorHandler';

export const getServiceChannels = createAsyncAction('TICKET_GET_SERVICE_CHANNELS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.channels,
    onRequestError: () => message.error('Erro ao tentar consultar canais de atendimento'),
    request: () => ticketApi.get(`/service-channels`),
  },
});

export const getReasons = createAsyncAction('TICKET_GET_REASONS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.reasons,
    onRequestError: () => message.error('Erro ao tentar consultar motivos de ticket'),
    request: () => ticketApi.get(`/reasons/all-by-journey`),
  },
});

export const getTicketUsers = createAsyncAction('TICKET_GET_USERS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.users,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar usuários do ticket' }),
    request: () => ticketApi.get(`/users`),
  },
});

export const getTicketComments = createAsyncAction('TICKET_GET_COMMENTS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.comments,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar comentários do ticket' }),
    request: (id) => ticketApi.get(`/comments`, { params: { id } }),
  },
});

export const getTicketsByUser = createAsyncAction('TICKET_LIST_BY_USER', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.tickets,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar tickets do usuário' }),
    request: ({ personId }) => ticketApi.get(`/tickets/by-person`, { params: { personId } }),
  },
});

export const getTicketTeams = createAsyncAction('TICKET_GET_TEAMS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.teams?.filter((team) => !!team.enabled),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar times do ticket' }),
    request: () => ticketApi.get(`/teams`),
  },
});

export const getTicketProducts = createAsyncAction('TICKET_GET_PRODUCTS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.products?.filter((product) => !!product.enabled),
    onRequestError: (err) => {
      handleErrors(err, { defaultMessage: 'Erro ao consultar produtos do ticket' });
    },
    request: () => ticketApi.get(`/products`),
  },
});

export const getTicketJourneys = createAsyncAction('TICKET_GET_JOURNEYS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.journeys?.filter((journey) => !!journey.enabled),
    onRequestError: (err) => {
      handleErrors(err, { defaultMessage: 'Erro ao consultar jornadas do ticket' });
    },
    request: () => ticketApi.get(`/journeis`),
  },
});

export const getTicketJourneysSteps = createAsyncAction('TICKET_GET_JOURNEYS_STEPS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.steps,
    onRequestError: (err) => {
      handleErrors(err, { defaultMessage: 'Erro ao consultar etapas da jornada do ticket' });
    },
    request: () => ticketApi.get(`/journeis/steps`),
  },
});

export const getTicketResolvingAreas = createAsyncAction('TICKET_GET_RESOLVING_AREAS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.areas,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar áreas resolvedoras do ticket' }),
    request: () => ticketApi.get(`/resolving-areas`),
  },
});

export const getTicketAccompaniments = createAsyncAction('TICKET_GET_ACCOMPANIMENTS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.accompaniments.reverse(),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar historico do ticket' }),
    request: ({ id }) => ticketApi.get(`/tickets/${id}/accompaniments`),
  },
});

export const getTicketStatus = createAsyncAction('TICKET_GET_STATUS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.status,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar status do ticket' }),
    request: () => ticketApi.get(`/status`),
  },
});

export const getTicketDetails = createAsyncAction('TICKET_GET_DETAILS', {
  initialValue: null,
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar detalhes do ticket' }),
    request: ({ id }) => ticketApi.get(`/tickets/${id}`),
  },
});

export const getTicketResolutionReasons = createAsyncAction('TICKET_GET_RESOLUTION_REASONS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.justifications,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar motivos de resolução do ticket' }),
    request: () => ticketApi.get(`/justifications`),
  },
});

export const getTicketSubResolutionReasons = createAsyncAction(
  'TICKET_GET_RESOLUTION_SUB_REASONS',
  {
    initialValue: [],
    saveResult: true,
    context: {
      errorParser: () => [],
      responseParser: ({ data }) => data.subJustifications,
      onRequestError: (err) =>
        handleErrors(err, {
          defaultMessage: 'Erro ao consultar sub-motivos de resolução do ticket',
        }),
      request: () => ticketApi.get(`/justifications/children`),
    },
  },
);

export const createTicket = createAsyncAction('TICKET_CREATE', {
  initialValue: {},
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar criar um ticket' }),
    onRequestSuccess: () => message.success('Ticket criado com sucesso'),
    request: ({ ticket }: { ticket: CreateTicket }) => ticketApi.post(`/tickets`, ticket),
  },
});

export const createTicketComment = createAsyncAction('TICKET_CREATE_COMMENT', {
  context: {
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar criar um comentário' }),
    onRequestSuccess: () => message.success('Comentário criado com sucesso'),
    request: (data: TicketComment) => ticketApi.post(`/comments`, data),
  },
});

export const updateTicketComment = createAsyncAction('TICKET_UPDATE_COMMENT', {
  context: {
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar atualizar um comentário' }),
    onRequestSuccess: () => message.success('Comentário atualizado com sucesso'),
    request: ({ id, ...data }) => ticketApi.put(`/comments/${id}/update`, data),
  },
});

export const updateTicket = createAsyncAction('TICKET_UPDATE', {
  context: {
    responseParser: ({ data }) => data,
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Erro ao atualizar o ticket' }),
    onRequestSuccess: () => message.success('Ticket atualizado com sucesso'),
    request: ({ ticketId, data }) => ticketApi.put(`/tickets/${ticketId}/update`, data),
  },
});

export const hasLinkedTicket = createAsyncAction('HAS_TICKET_LINKED', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => {},
    request: (data) => ticketApi.post(`/tickets/has-link`, data),
  },
});

export const selectTicket = createAsyncAction('SELECT_TICKET', {
  saveResult: true,
  context: {
    responseParser: (res: any) => res,
    request: (data) => data,
  },
});

export const selectAssessment = createAsyncAction('SELECT_TICKET_ASSESSMENT', {
  saveResult: true,
  context: {
    responseParser: (res: any) => res,
    request: (data) => data,
  },
});

export const getTickets = createAsyncAction('GET_TICKETS', {
  saveResult: true,
  initialValue: {},
  context: {
    responseParser: ({ data: { resource, ...rest } }) => ({
      tickets: resource,
      ...rest,
    }),
    onRequestError: () => {},
    request: (params) => ticketApi.get(`/tickets`, { params }),
  },
});

export const getExperts = createAsyncAction('GET_TICKETS_EXPERTS', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    onRequestError: () => {},
    request: () => ticketApi.get(`/tickets/experts`),
  },
});

export const createTicketAssessment = createAsyncAction('CREATE_TICKET_ASSESSMENT', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => {},
    request: ({ ticketId, ...data }) => ticketApi.post(`/tickets/${ticketId}/assessments`, data),
  },
});

export const updateTicketAssessment = createAsyncAction('UPDATE_TICKET_ASSESSMENT', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => {},
    request: ({ ticketId, ...data }) =>
      ticketApi.put(`/tickets/${ticketId}/assessments/${data.id}`, data),
  },
});

export const getTicketMessages = createAsyncAction('GET_TICKETS_MESSAGES', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => message.error('Falha ao carregar novas mensagens'),
    request: ({ conversationId, pageToken, pageSize = 10 }) =>
      ticketApi.get(`/conversations/${conversationId}/messages`, {
        params: {
          pageSize,
          pageToken,
        },
      }),
  },
});

export const getTicketMedia = createAsyncAction('GET_TICKETS_MEDIA', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => {},
    request: ({ conversationId, sid }) =>
      ticketApi.get(`/conversations/${conversationId}/media/${sid}`),
  },
});

export const getTicketAssessment = createAsyncAction('GET_TICKETS_ASSESSMENT', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.assessments,
    onRequestError: () => {},
    request: ({ ticketId }) => ticketApi.get(`/tickets/${ticketId}/assessments`),
  },
});

export const getTicketResponsibles = createAsyncAction('GET_TICKET_RESPONSIBLES', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data,
    onRequestError: () => {},
    request: ({ page, size, sort, responsibleName }) =>
      ticketApi.get(`/responsibles`, {
        params: {
          page,
          size,
          sort,
          responsibleName,
        },
      }),
  },
});

export const updateResponsible = createAsyncAction('UPDATE_TICKET_RESPONSIBLE', {
  context: {
    onRequestError: () => {},
    request: (data) => ticketApi.put(`/responsibles/update`, data),
  },
});

export const removeResponsible = createAsyncAction('DELETE_TICKET_RESPONSIBLE', {
  context: {
    onRequestError: () => {},
    request: (data) => ticketApi.delete(`/responsibles/delete`, { data }),
  },
});

export const getTicketStatusGroup = createAsyncAction('TICKET_GET_STATUS_GROUP', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.groupStatus,
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao consultar grupo de status do ticket' }),
    request: () => ticketApi.get(`/status/groups`),
  },
});

export const getTicketReasons = createAsyncAction('GET_TICKET_REASONS', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.reasons,
    onRequestError: () => message.error('Erro ao tentar consultar motivos de ticket'),
    request: () => ticketApi.get(`/reasons`),
  },
});
