import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { Person } from '~/typings/entities/person';
import { Collapse } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import useUserRoles from '~/hooks/useUserRoles';
import { Enrollment } from '~/typings/entities/Enrollment';
import {
  getConcessionByEnrollment,
  getHisconByEnrollment,
  getMedicalCertificateByEnrollment,
  requestHisconUpdate,
  uploadConcessionByEnrollment,
  uploadHisconByEnrollment,
} from '~/store/enrollments/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { Integration } from '~/screens/Person/components/Drawers/Integration/Integration';
import { UploadCertificateModal } from './UploadCertificateModal';

interface ContentProps {
  personDetails: Person;
  enrollment: Enrollment;
}

const isAuthorized = true;

function Content({ personDetails, enrollment }: ContentProps) {
  const [inputFile, setInputFile] = useState('');
  const { openDrawer } = useDrawer();

  const getConcessionControl = useAwaitControl(getConcessionByEnrollment);
  const uploadConcessionControl = useAwaitControl(uploadConcessionByEnrollment);
  const getMedicalCertificateControl = useAwaitControl(getMedicalCertificateByEnrollment);
  const getHisconControl = useAwaitControl(getHisconByEnrollment);
  const requestHisconAutoUpdateControl = useAwaitControl(requestHisconUpdate);
  const uploadHisconControl = useAwaitControl(uploadHisconByEnrollment);

  const isLoadingHiscon = getHisconControl.isRunning();
  const isHisconUpdateLoading = requestHisconAutoUpdateControl.isRunning();
  const isLoadingConcession = getConcessionControl.isRunning();
  const isLoadingMedicalExpertise = getMedicalCertificateControl.isRunning();

  const concessionLoaded = getConcessionControl.isSuccessful();
  const medicalCertificateLoaded = getMedicalCertificateControl.isSuccessful();
  const hisconLoaded = getHisconControl.isSuccessful();

  const concessionFile = getConcessionControl.result();
  const medicalCertificateFile = getMedicalCertificateControl.result();
  const hisconData = getHisconControl.result();

  const inputNewEl = useRef<HTMLInputElement>(null);

  const { openModal } = useModal();
  const { isAdmin } = useUserRoles();
  const lastRequestHisconUpdate = personDetails?.lastRequestHisconUpdate;

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const onPressConcessionInputFile = useCallback(() => {
    setInputFile('concession');
    if (inputNewEl.current) {
      inputNewEl.current.click();
    }
  }, [inputNewEl]);

  const onPressUploadConcession = useCallback(
    (event) => {
      const reader = new FileReader();
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        uploadConcessionControl.start({ file, enrollmentId: enrollment?.id });

        inputNewEl.current.value = null;
      };
    },
    [inputNewEl, enrollment],
  );

  const onPressUploadHiscon = useCallback(
    (event) => {
      const reader = new FileReader();
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        uploadHisconControl.start({ file, enrollmentId: enrollment?.id, version: 'new' });

        inputNewEl.current.value = null;
      };

      setUploadSuccess(true);
    },
    [inputNewEl, enrollment],
  );

  const handleMedicalExpertiseUpload = useCallback(
    () =>
      openModal(<UploadCertificateModal enrollmentId={enrollment.id} />, {
        title: 'Enviar arquivo de Perícia Médica',
        id: 'upload-medical-certificate',
        closable: true,
      }),
    [enrollment],
  );

  const loadingHiscon = useMemo(
    () => isLoadingHiscon || isHisconUpdateLoading,
    [isLoadingHiscon, isHisconUpdateLoading],
  );

  const canRequestHisconAutoUpdate = useMemo(() => {
    if (!lastRequestHisconUpdate || isAdmin || isAuthorized) return true;
    const date = moment(lastRequestHisconUpdate);
    return moment().diff(date, 'hours') >= 24;
  }, [lastRequestHisconUpdate, isAdmin]);

  const handleOnClick = (opportunity) => {
    const elemento = document.getElementById('enrollmentCollapse');

    if (elemento) {
      elemento.remove();
    }

    openDrawer('integration-drawer', <Integration enrollmentId={opportunity.id} />, {
      width: 557,
      title: `Conectar ao portal Meu INSS`,
      closable: true,
      destroyOnClose: true,
      noPadding: true,
    });
  };

  const renderItems = useMemo(
    () => [
      {
        label: 'Hiscon',
        id: 'hiscon',
        disabled: !!loadingHiscon,
        onClick: () => handleOnClick(enrollment),
      },
      {
        label: 'Carta de concessão',
        id: 'concession-letter',
        children: [
          {
            label: 'Abrir concessão',
            id: 'open-concession',
            disabled: isLoadingConcession,
            onClick: () => getConcessionControl.start({ enrollmentId: enrollment?.id }),
          },
          {
            label: 'Upload',
            id: 'concession-upload',
            onClick: () => onPressConcessionInputFile(),
          },
        ],
      },
      {
        label: 'Perícia médica',
        id: 'medical-expertise',
        children: [
          {
            label: 'Abrir perícia médica',
            id: 'open-medical-expertise',
            disabled: isLoadingMedicalExpertise,
            onClick: () => getMedicalCertificateControl.start({ enrollmentId: enrollment?.id }),
          },
          {
            label: 'Upload',
            id: 'medical-expertise-upload',
            onClick: () => handleMedicalExpertiseUpload(),
          },
        ],
      },
    ],
    [
      personDetails,
      enrollment,
      canRequestHisconAutoUpdate,
      isLoadingConcession,
      isLoadingMedicalExpertise,
      isHisconUpdateLoading,
    ],
  );

  const onClickHandlers = {
    hiscon: onPressUploadHiscon,
    concession: onPressUploadConcession,
  };

  useEffect(() => {
    if (concessionLoaded) {
      const downloadUrl = window.URL.createObjectURL(concessionFile);
      window.open(downloadUrl, '__blank');
      window.URL.revokeObjectURL(downloadUrl);
    }

    return () => {
      getConcessionControl.clear();
    };
  }, [concessionLoaded]);

  useEffect(() => {
    if (medicalCertificateLoaded) {
      const downloadUrl = window.URL.createObjectURL(medicalCertificateFile);

      window.open(downloadUrl, '__blank');
      window.URL.revokeObjectURL(downloadUrl);
    }

    return () => {
      getMedicalCertificateControl.clear();
    };
  }, [medicalCertificateLoaded]);

  useEffect(() => {
    if (hisconLoaded) {
      const downloadUrl = window.URL.createObjectURL(hisconData);

      window.open(downloadUrl, '__blank');
      window.URL.revokeObjectURL(downloadUrl);
    }

    return () => {
      getHisconControl.clear();
    };
  }, [hisconLoaded]);

  useEffect(() => {
    if (uploadSuccess) {
      setUploadSuccess(false);
    }
  }, [uploadSuccess, personDetails]);

  return (
    <>
      <Collapse items={renderItems} maxHeight={280} />
      <input
        ref={inputNewEl}
        type="file"
        accept="application/pdf"
        onChange={onClickHandlers[inputFile]}
        data-testid="enrollment-file-importer"
        style={{ display: 'none' }}
      />
    </>
  );
}

export default memo(Content);
