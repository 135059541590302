import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SearchBar, SelectDate, SelectSearch } from '~/ui/components';
import { Calendar } from '~/ui/assets/icons';
import { useDrawer } from '~/hooks/useDrawer';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getTicketResolvingAreas,
  getTicketResponsibles,
  getTickets,
  getTicketStatus,
} from '~/store/tickets/actions';
import moment from 'moment';
import usePagination from '~/hooks/usePagination';
import { Box, Content, MoreFilters, SearchWrapper, FilterWrapper, Wrapper } from './Filters.styles';
import AllFilters from '../../drawers/AllFilters/AllFilters';
import {
  normalizeData,
  normalizedInitialData,
  normalizedInitialResolvingArea,
  normalizedInitialStatusData,
} from '../../common/ticket';
import Title from '../Title/Title';
import { debounce } from 'lodash';
import { StyledSelect } from '../../drawers/Actions/Actions.styles';

type FiltersProps = {
  onChange: (value: any) => void;
  onSearch: (value) => void;
};

function Filters({ onChange, onSearch }: FiltersProps) {
  const { openDrawer } = useDrawer();

  const [selections, setSelections] = useState<Record<string, any>>({ statusId: 63 });
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [statusSearch, setStatusSearch] = useState('')
  const [searchResponsible, setSearchResponsible] = useState('');
  const [wasFiltered, setWasFiltered] = useState(false);

  const getTicketsControl = useAwaitControl(getTickets);
  const ticketStatusControl = useAwaitControl(getTicketStatus);
  const getTicketResponsiblesControl = useAwaitControl(getTicketResponsibles);
  const getTicketResolvingAreasControl = useAwaitControl(getTicketResolvingAreas);

  const isLoadingResponsibles = getTicketResponsiblesControl.isRunning();
  const isLoadingResolvingAreas = getTicketResolvingAreasControl.isRunning();
  const isLoadingStatus = ticketStatusControl.isRunning();

  const tickets = getTicketsControl.result();
  const ticketStatus = ticketStatusControl.result();
  const ticketResponsibles = getTicketResponsiblesControl.result();
  const ticketResolvingAreas = getTicketResolvingAreasControl.result();

  const { size, clear } = usePagination({
    data: tickets?.tickets,
    total: tickets?.total,
    pageSize: 50,
  });

  const handleUpdate = useCallback(() => {
    setIsUpdateDisabled(true);
    clear();
    setSelections({});
    onSearch(null);
    onChange({});
    getTicketsControl.start({ page: 0, size, sort: 'id,desc' });

    setTimeout(() => {
      setIsUpdateDisabled(false);
    }, 2000);
  }, []);

  const handleClearFilters = useCallback(() => {
    clear();
    setSelections({});
    onSearch(null);
    onChange({});
    getTicketsControl.start({ page: 0, size, sort: 'id,desc' });
  }, []);

  const handleOnChangeMoreFilters = useCallback((value) => {
    setSelections(value);
    onChange(value);
  }, []);

  const handleMoreFilters = useCallback(() => {
    openDrawer(
      'more-filters-drawer',
      <AllFilters
       onChange={handleOnChangeMoreFilters} 
       initialValues={selections} 
       onClear={handleClearFilters}
      />,
      {
        title: 'Mais filtros',
        width: 460,
        closable: true,
        maskClosable: true,
      },
    );
  }, [selections]);

  const handleOnSelect = useCallback(
    (selector: string, option) => {
      setSelections((prevSelections) => {
        let newSelections = { ...prevSelections };

        const isData = selector === 'date';
        const start = 'start';
        const end = 'end';

        if (option && isData) {
          newSelections = {
            ...newSelections,
            [start]: moment(option[0]).format('YYYY-MM-DD'),
            [end]: moment(option[1]).format('YYYY-MM-DD'),
          };
        }

        if (!option && isData) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [start]: _start, [end]: _end, ...rest } = newSelections;
          newSelections = rest;
        }

        if (option?.value && !isData) {
          newSelections = {
            ...newSelections,
            [selector]: option?.value,
          };
        }

        if (!option?.value && !isData) {
          const { [selector]: _, ...rest } = newSelections;
          newSelections = rest;
        }

        onChange(newSelections);

        return newSelections;
      });
    },
    [setSelections, onChange],
  );

  const handleResolvingArea = useCallback((value) => {
    const newValue = value.length > 1 ? { value: value[1] } : { value: value[0] };
    handleOnSelect('resolvingareaId', newValue);
  }, [])

  const handleSearch = useCallback((value) => {
    if (value.length >= 3) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
        responsibleName: value,
      });
      setWasFiltered(true);
    }
  }, []);

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), []);

  const handleSearchResponsible = (value) => {
    setSearchResponsible(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    ticketStatusControl.start();
  }, []);

  useEffect(() => {
    if (!ticketResponsibles) {
      getTicketResponsiblesControl.start({ page: 0, size: 50, sort: 'string' });
    }

    if (!ticketResolvingAreas.length) {
      getTicketResolvingAreasControl.start();
    }
  }, []);

  useEffect(() => {
    if (searchResponsible.length === 0 && wasFiltered) {
      getTicketResponsiblesControl.start({
        page: 0,
        size: 50,
        sort: 'string',
      });
      setWasFiltered(false);
    }
  }, [searchResponsible, wasFiltered]);

  return (
    <Wrapper>
      <Title onUpdate={handleUpdate} isUpdateDisabled={isUpdateDisabled} />
      <FilterWrapper>
        <SearchWrapper>
          <SearchBar placeholder="Buscar pelo ticket" onChange={onSearch} />
        </SearchWrapper>
        <Content>
          <Box>
            <SelectDate
              icon={<Calendar />}
              selectRange
              value={selections?.start ? [selections.start, selections.end] : null}
              maxDate={new Date()}
              placeholder="Filtrar por data"
              onChange={(value) => handleOnSelect('date', value)}
            />
          </Box>
          <SelectSearch
            placeholder="Status"
            width={180}
            value={normalizedInitialStatusData(ticketStatus, selections?.statusId)}
            onSearch={setStatusSearch}
            options={normalizeData(ticketStatus)?.filter((data) =>
              statusSearch ? data.label.toLowerCase().includes(statusSearch.toLowerCase().trim()) : true
            )}
            onChange={(value) => handleOnSelect('statusId', value)}
            loading={isLoadingStatus}
          />
          <Box>
            <StyledSelect
              placeholder="Área resolvedora"
              value={normalizedInitialResolvingArea(ticketResolvingAreas, selections?.resolvingareaId)}
              options={ticketResolvingAreas}
              loading={isLoadingResolvingAreas}
              onChange={handleResolvingArea}
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Box>
          <SelectSearch
            placeholder="Responsável"
            pictured
            value={normalizedInitialData(
              ticketResponsibles?._embedded?.responsibleList,
              selections?.responsibleId,
            )}
            options={normalizeData(ticketResponsibles?._embedded?.responsibleList)}
            onChange={(value) => handleOnSelect('responsibleId', value)}
            onSearch={handleSearchResponsible}
            loading={isLoadingResponsibles}
          />
          <MoreFilters icon="Filter" variant="outline" size="lg" onClick={handleMoreFilters}>
            Mais Filtros
          </MoreFilters>
        </Content>
      </FilterWrapper>
    </Wrapper>
  );
}

export default Filters;
