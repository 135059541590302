const StatusColors = {
  1: 'neutral',
  2: 'alert',
  3: 'positive',
  21: 'negative',
};

export const normalizeStatusGroup = (statusGroup) =>
  statusGroup?.map((status) => ({
    label: status.name,
    value: status.id,
    color: StatusColors[status.id],
  }));

export const normalizeData = (options) =>
  options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

export const normalizeProducts = (products) =>
  products?.map((product) => ({
    label: product.description,
    value: product.name,
  }));

export const normalizedInitialData = (array, value) =>
  array?.find((option) => option.id === value)
    ? normalizeData([array.find((option) => option.id === value)])[0]
    : null;

export const normalizedInitialStatusData = (array, value) =>
  array?.find((option) => option.id === value)
    ? normalizeStatusGroup([array.find((option) => option.id === value)])[0]
    : null;

export const normalizedInitialResolvingArea = (array, id, path = []) => {
  for (const item of array) {
    if (item.id === id) {
        return [...path, item.id];
    }
    if (item.children && item.children.length > 0) {
        const result = normalizedInitialResolvingArea(item.children, id, [...path, item.id]);
        if (result) return result;
    }
  }

  return null;
}
  