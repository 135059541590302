export const formatCPF = (cpf: string) => {
  if (cpf.length !== 11) {
    return '-';
  }

  const formattedCPF = `***.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-**`;
  return formattedCPF;
};

export function removeCpfPunctuation(cpf) {
  const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  
  if (regex.test(cpf)) {
      return cpf.replace(/\D/g, '');
  }
  
  return cpf;
}
