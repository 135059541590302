import moment from 'moment';
import React from 'react';
import { Money } from '~/common';
import toCPFFormat from '~/common/masked/toCPFFormat';
import { themeToggleColor } from '~/common/utils/theme';
import { useTheme } from 'styled-components';
import { ConsigPrivProposal } from '~/typings/entities/Proposals';
import {
  PartialApprovedStatusMessages,
  ProposalsStatus,
  ProposalStatusColors,
  ProposalStatusMessages,
  ProposalStatusNames,
} from '~/typings/enums';
import { CellProps, Flex, InfoGrid, Tag, Tooltip, Typography } from '~/ui/components';

export type ProposalDetailsProps = {
  info: ConsigPrivProposal;
};

export function ProposalDetails({ info }: ProposalDetailsProps) {
  const theme = useTheme();

  function formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  function renderStatus () {
    const status = info?.status
    const origin = info.proposalOrigin;

    const colors = ProposalStatusColors?.[status] || {
      bgColor: 'neutral.secondaryAlt',
      textColor: 'neutral.primary',
    };

    let proposalMessage: string;

    if (status === ProposalsStatus.PARTIAL_APPROVED) {
      proposalMessage = PartialApprovedStatusMessages?.[origin];
    } else {
      proposalMessage = ProposalStatusMessages?.[status];
    }

    if (proposalMessage) {
      return (
        <Tooltip content={proposalMessage} small>
          <Tag {...colors}>{ProposalStatusNames?.[status] || status || '-'}</Tag>
        </Tooltip>
      )
    }

    return <Tag {...colors}>{ProposalStatusNames?.[status] || status || '-'}</Tag>;
  }

  function renderIncludedCtps(includedCtps: boolean) {
    const color = includedCtps ? 'positive.primary' : 'negative.primary';

    return (
      <Typography type="bodyMedium" weight={700} color={color}>{includedCtps ? 'Sim' : 'Não'}</Typography>
    )
  }

  const infoList = [
    { name: 'CPF', value: toCPFFormat(info?.document) },
    { name: 'Origem da proposta', value: info?.proposalOrigin },
    { name: 'Inclusão da proposta', value: renderIncludedCtps(info?.includedCtps) },
    { name: 'Data expiração', value: formatDate(info?.validUntil) },
    { name: 'Última atualização', value: formatDate(info?.updatedAt) },
    { name: 'Criação da proposta', value: formatDate(info?.createdAt) },
    { name: 'Valor proposta', value: Money(info?.value) },
    { name: 'Parcelas', value: info?.installmentsNumber },
    { name: 'Valor das Parcelas', value: Money(info?.installmentsValue)},
    { name: 'Total calculado', value: Money(info?.totalValue) },
  ];

  return (
    <Flex pv={24} direction="column" gap={32}>
      <Flex justify="space-between">
        <Typography type="headingH5">
          ID nº <Typography.Bold weight={600}>{info.idSolicitacao}</Typography.Bold>
        </Typography>

        {renderStatus()}
      </Flex>

      <InfoGrid fullWidth list={infoList} grid={3} responsive={{ 540: 2 }} />

      <Flex
        direction="column"
        gap={32}
        bg={themeToggleColor(theme, 'background.secondary')}
        radius={24}
        ph={24}
        pv={24}
      >
        <Typography type="bodySmall" weight={600}>
          Informações disponíveis
        </Typography>

        <Flex direction="column" gap={8}>
          <Typography type="bodyMedium">Quantidade de parcelas</Typography>

          <Flex gap={8}>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {info?.minInstallments}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                mínimo
              </Typography>
            </Flex>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {info?.maxInstallments}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                máximo
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap={8}>
          <Typography type="bodyMedium">Valor das parcelas</Typography>

          <Flex gap={8}>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {Money(info?.minInstallmentsValue)}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                mínimo
              </Typography>
            </Flex>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {Money(info?.maxInstallmentsValue)}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                máximo
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap={8}>
          <Typography type="bodyMedium">Valor do empréstimo</Typography>

          <Flex gap={8}>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {Money(info?.minValue)}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                mínimo
              </Typography>
            </Flex>
            <Flex direction="column" gap={2} shrink={1}>
              <Typography type="bodyMedium" weight={700} color="element.secondary">
                {Money(info?.maxValue)}
              </Typography>
              <Typography type="bodyXSmall" weight={600} color="element.secondary">
                máximo
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
