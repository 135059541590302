import React from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import screenRegister from '~/hoc/screenRegister';
import { listProposals } from '~/store/proposals/actions';
import { Content } from '~/ui/components';
import { ProposalsTable } from './ProposalsTable/ProposalsTable';
import { Filters } from './Filters/Filters';
import { removeCpfPunctuation } from '~/common/formatDocument';

function Proposals() {
  const proposalsControl = useAwaitControl(listProposals);
  const proposals = proposalsControl.result();
  const loading = proposalsControl.isRunning();

  const handleSubmit = ({ document }) => {
    if (document) {
      const updatedDocument = removeCpfPunctuation(document)
      proposalsControl.start({ document: updatedDocument });
    }
  };

  return (
    <Content>
      <Filters onSubmit={handleSubmit} loading={loading} />
      <ProposalsTable data={proposals} loading={loading} />
    </Content>
  );
}

export default screenRegister({
  screenName: 'Proposals',
  headerTitle: 'Propostas',
  path: '/backoffice/proposals',
  customLayout: true,
})(Proposals);
