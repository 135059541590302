import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Typography } from '~/ui/components';
import { Docs } from '~/ui/assets/icons';
import {
  getHisconHistoryExtract,
  requestHisconDownload,
  uploadHisconByEnrollment,
} from '~/store/enrollments/actions';
import useAsyncActionOpenFile from '~/hooks/useAsyncActionOpenFile';
import { Section, Wrapper } from './Integration.styled';
import { Card } from './components/Card/Card';
import { History } from './components/History/History';
import { HistoryLoading } from './components/History/HistoryLoading';
import { ampli } from '~/ampli';
import { useTracking } from '~/hooks/useTracking';
import { getPersonDetail } from '~/store/people/actions';

export type IntegrationProps = {
  enrollmentId: number;
};

export function Integration({ enrollmentId }: IntegrationProps) {
  const inputNewEl = useRef<HTMLInputElement>(null);
  const [isNew, setIsNew] = useState(false);
  const [enrollmentOpened, setEnrollmentOpened] = useState(0);
  const { handleTracking } = useTracking();

  const uploadHisconControl = useAwaitControl(uploadHisconByEnrollment);
  const getHistoryControl = useAwaitControl(getHisconHistoryExtract);
  const getPaycheckFileControl = useAwaitControl(requestHisconDownload);
  const personControl = useAwaitControl(getPersonDetail);

  const historyIsLoading = getHistoryControl.isRunning();
  const payCheckFileIsLoading = getPaycheckFileControl.isRunning();

  const histories = getHistoryControl.result()?.entries;

  const personDetails = personControl.result();

  const uploadHisconSuccessfully = uploadHisconControl.isSuccessful();

  const hasHistories = histories && histories.length > 0;

  useAsyncActionOpenFile(getPaycheckFileControl);

  const onPressHisconDownload = useCallback(
    (paycheckId: number) => {
      setEnrollmentOpened(paycheckId);
      getPaycheckFileControl.start({ enrollmentId, paycheckId });
    },
    [enrollmentId],
  );

  const onPressInputFile = useCallback(() => {
    if (inputNewEl.current) {
      inputNewEl.current.click();
    }
  }, [inputNewEl]);

  const onPressUploadHiscon = useCallback(
    (event) => {
      const reader = new FileReader();
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        uploadHisconControl.start({ file, enrollmentId, version: 'new' });

        inputNewEl.current.value = null;
      };
    },
    [inputNewEl, enrollmentId],
  );

  const options = [
    {
      id: 1,
      title: 'Arquivo do extrato',
      description: 'Enviar o extrato (pdf) desse benefício',
      disabled: false,
      onClick: onPressInputFile,
    },
    {
      id: 2,
      title: 'Conectar com a senha ao Meu INSS',
      description: 'Insira a senha do portal, e conecte automaticamente',
      disabled: true,
      onClick: () => null,
    },
    {
      id: 3,
      title: 'Acessar Meu INSS',
      description:
        'Acesse ao portal meu INSS e use o código de autencidade para baixar o último hiscon',
      disabled: true,
      onClick: () => null,
    },
  ];

  useEffect(() => {
    if (enrollmentId) {
      getHistoryControl.start({ enrollmentId });
    }

    return () => {
      getHistoryControl.clear();
      uploadHisconControl.clear();
    };
  }, [enrollmentId, uploadHisconSuccessfully]);

  useEffect(() => {
    if (uploadHisconSuccessfully) {
      setIsNew(true);

      const trackEvent = ampli.integracaoConcluiuIntegracao({
        titulo_tela: 'PersonDetailsNew',
        titulo_aba: 'Matrículas',
        cliente_person_id: personDetails.id,
        cliente_convenio: personDetails.agreementName,
        cliente_oportunidade: personDetails.isOpportunity ?? false,
      });

      handleTracking(() => trackEvent);
    }
  }, [uploadHisconSuccessfully, personDetails]);

  return (
    <Wrapper>
      <Section>
        {options.map((option) => (
          <Card card={option} key={option.id} />
        ))}
      </Section>
      <Section>
        <Typography type="bodyLarge" element="h6" weight={600}>
          Histórico de extratos
        </Typography>
        {historyIsLoading && <HistoryLoading />}

        {hasHistories &&
          histories.map((history, index) => (
            <History
              key={index}
              history={history}
              onClick={onPressHisconDownload}
              isNew={isNew && index === 0}
              isLoading={payCheckFileIsLoading && enrollmentOpened === history.id}
            />
          ))}
      </Section>
      {!hasHistories && !historyIsLoading && (
        <Section maxWidth={300} center>
          <Docs width={123} height={108} />
          <Typography type="paragraphSmall" center>
            Os documentos carregados serão exibidos nessa área
          </Typography>
        </Section>
      )}
      <input
        ref={inputNewEl}
        type="file"
        accept="application/pdf"
        onChange={onPressUploadHiscon}
        data-testid="integration-file-importer"
        style={{ display: 'none' }}
      />
    </Wrapper>
  );
}
