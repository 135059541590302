import styled, { css } from 'styled-components';

import { themeToggleColor } from '~/common/utils/theme';
import { Profile } from '~/components';
import { Table, Typography } from '~/ui/components';

export const StyledTable = styled(Table)`
  margin: 0px 28px 24px 0px;

  thead {
    height: 60px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

export const Avatar = styled(Profile)`
  background-color: ${({ theme }) => theme.colors.brand.secondary};

  span {
    color: ${({ theme }) => theme.colors.brand.primary};
    font-size: 14px;
  }
`;

type ContentProps = {
  row?: boolean;
  gap?: number;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.background.primary};

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
    width: 12px;
    height: 12px;
  }

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

export const Hour = styled(Typography).attrs(({ theme }) => ({
  type: 'bodyXSmall',
  color: themeToggleColor(theme, 'element.secondary'),
}))``;

export const LinkToTicket = styled.b`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.brand.primary};
`;
