import React, { useCallback } from 'react';
import moment from 'moment';

import { CaretDown, User } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { PersonRegistrationLog } from '~/typings/entities/person';

import { formatName } from '~/common';
import { BasicListRow, BasicInfo, InfoTitle, InfoText } from './ProfileResume.styled';
import { RegistrationLogList } from './RefusedPopover.styled';

type Props = {
  registrationLog: PersonRegistrationLog[];
};

const RegistrationStatus = {
  APPROVED: 'Cadastro aprovado',
  REFUSED: 'Cadastro reprovado',
  COMPLETE: 'Cadastro aprovado',
};

export function RefusedPopover({ registrationLog }: Props) {
  const formatDate = useCallback((dateToFormat) => {
    if (!dateToFormat) return 'Indisponível';
    return moment(dateToFormat).format('DD [de] MMMM [de] YYYY, HH[h]mm');
  }, []);

  const handleShowDescription = (logItem) => {
    if (!logItem.userId) {
      return `Aprovação automática - ${formatDate(logItem.operationDate)}`;
    }
    return `${formatName(logItem.userName)} - ${formatDate(logItem.operationDate)}`;
  };

  const popoverContent = (
    <RegistrationLogList>
      {registrationLog.map((logItem) => (
        <BasicListRow key={logItem.id}>
          <User className="action-icon" width={14} height={14} />
          <BasicInfo>
            <InfoTitle>
              {RegistrationStatus[logItem.registrationStatus] || logItem.registrationStatus}
            </InfoTitle>
            <InfoText>{handleShowDescription(logItem)}</InfoText>
          </BasicInfo>
        </BasicListRow>
      ))}
    </RegistrationLogList>
  );

  return (
    <Popover
      placement="right"
      trigger="click"
      title="Histórico de cadastro"
      content={popoverContent}
    >
      <CaretDown className="action-icon" width={14} height={14} />
    </Popover>
  );
}
