import AccountType from './AccountType';
import CommissioningType from './CommissioningType';
import ContractSituation from './ContractSituation';
import ContractStatus from './ContractStatus';
import ContractType from './ContractType';
import CreatedLocation from './CreatedLocation';
import CreditCardCashoutSituation from './CreditCardCashoutSituation';
import CreditCardStatus from './CreditCardStatus';
import CreditCardType from './CreditCardType';
import FGTSIntegrationStatus from './FGTSIntegrationStatus';
import LoanFactorType from './LoanFactorType';
import LoanStatus from './LoanStatus';
import LoanType from './LoanType';
import MarginSource from './MarginSource';
import OperationStatusType from './OperationStatusType';
import OperationStepType from './OperationStepType';
import RegistrationStatus from './RegistrationStatus';
import ReservationStatus from './ReservationStatus';
import ValidationStatusUnico from './ValidationStatusUnico';
import ImageOrigin from './ImageOrigin';

export * from './Proposals';

export default {
  AccountType,
  CommissioningType,
  ContractSituation,
  ContractStatus,
  ContractType,
  CreatedLocation,
  CreditCardCashoutSituation,
  CreditCardStatus,
  CreditCardType,
  FGTSIntegrationStatus,
  ImageOrigin,
  LoanFactorType,
  LoanStatus,
  LoanType,
  MarginSource,
  OperationStatusType,
  OperationStepType,
  RegistrationStatus,
  ReservationStatus,
  ValidationStatusUnico,
};
