import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';

import { eraseAgreementPassword } from '~/store/agreements/actions';
import { getEnrollmentByUserId } from '~/store/enrollments/actions';
import { getPersonDetail } from '~/store/people/actions';
import { useAwaitControl } from 'react-redux-await-control';
import { Enrollment } from '~/typings/entities/Enrollment';
import { Shimmer } from '~/ui/components';
import { getIN100Process } from '~/store/in100/actions';
import { AccessByCostumerButton, SendNotificationButton } from './components';
import {
  ActionsTitle,
  CollapseButton,
  ItemText,
  MainButton,
  MenuItem,
  Divider,
  Collapse,
  ShimmerContainer,
} from './PersonActions.styled';

const { Panel } = Collapse;

type MainActionsProps = {
  loading?: boolean;
  className?: string;
  onClick?: () => void;
};

export function PersonActions({ className, loading, onClick }: MainActionsProps) {
  const [collapsed, setCollapsed] = useState(false);

  const history: any = useHistory();

  const getPersonDetailControl = useAwaitControl(getPersonDetail);
  const getIN100ProcessControl = useAwaitControl(getIN100Process);
  const eraseAgreementPasswordControl = useAwaitControl(eraseAgreementPassword);

  const personDetails = getPersonDetailControl.result();
  const { id: personId } = personDetails;

  const getEnrollmentsControl = useAwaitControl(getEnrollmentByUserId);
  const enrollments: Enrollment[] = getEnrollmentsControl.result();

  const hancleClick = () => {
    setCollapsed(!collapsed);
  };

  const handleContractExtract = () => {
    const { marginCard, marginLoan, marginExpirationDate } = personDetails;

    onClick?.();

    history.push({
      pathname: `/backoffice/people/${personId}/contract-extract`,
      state: { marginCard, marginLoan, marginExpirationDate, fromPerson: true, personId },
    });
  };

  const renderNewOffer = () => {
    onClick?.();

    history.push({
      pathname: `/backoffice/people/${personId}/offer-person/register`,
      query: { showAge: true },
      state: { fromPerson: true, personId },
    });
  };

  const renderFillContract = () => {
    const { agreementId } = personDetails;

    onClick?.();

    history.push({
      pathname: `/backoffice/people/${personId}/fill-contract`,
      state: { personAgreementId: agreementId, fromPerson: true, personId },
    });
  };

  const reprocessIn100 = () => {
    onClick?.();
    getIN100ProcessControl.start({ id: personId });
  };

  const onPressInvalidateAgreementPassword = useCallback(() => {
    eraseAgreementPasswordControl.start({ id: personId });
  }, [personId, eraseAgreementPasswordControl]);

  function renderInvalidateAgreementPassword() {
    if (!personDetails.agreementPassword) return null;

    return (
      <Popconfirm
        title="Deseja realmente invalidar a senha?"
        placement="left"
        cancelText="Não"
        okText="Sim"
        onConfirm={onPressInvalidateAgreementPassword}
      >
        <MenuItem>
          <ItemText>Invalidar Senha do Convênio</ItemText>
        </MenuItem>
      </Popconfirm>
    );
  }

  const hasAgreement = useCallback(
    (agreementName) =>
      enrollments?.find((enrollment) => enrollment?.agreementName === agreementName),
    [enrollments],
  );

  function renderIn100Button() {
    const { registrationStatus } = personDetails;
    const REGISTRATION_STATUS_ALLOWED_FOR_REPROCESS_IN100 = [
      'PASSWORD',
      'PENDING_IN100',
      'VALIDATION',
      'COMPLETE',
      'APPROVED',
    ];

    if (!hasAgreement('INSS')) return null;

    if (!REGISTRATION_STATUS_ALLOWED_FOR_REPROCESS_IN100.includes(registrationStatus)) {
      return null;
    }

    return (
      <MenuItem onClick={reprocessIn100}>
        <ItemText>Reprocessar In100</ItemText>
      </MenuItem>
    );
  }

  const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    border: 0,
    overflow: 'hidden',
  };

  if (loading) {
    return (
      <ShimmerContainer>
        <Shimmer width={220} height={30} mt={25} mb={18} ml={32} mr={24} />
        {Array.from({ length: 7 }).map((_, index) => (
          <Shimmer width={200} height={25} ml={32} mb={8} key={index} />
        ))}
        <Divider />
      </ShimmerContainer>
    );
  }

  return (
    <div className={className}>
      <MainButton onClick={hancleClick} className="main-button">
        <CollapseButton collapsed={collapsed} />
        <ActionsTitle>Ações</ActionsTitle>
      </MainButton>

      <Collapse
        defaultActiveKey="collapse"
        activeKey={collapsed ? null : 'collapse'}
        bordered={false}
      >
        <Panel showArrow={false} header={null} key="collapse" style={customPanelStyle}>
          <AccessByCostumerButton onClick={onClick} />

          <MenuItem onClick={handleContractExtract}>
            <ItemText>Extrato de Contratos</ItemText>
          </MenuItem>

          <SendNotificationButton onClick={onClick} />

          <MenuItem onClick={renderNewOffer}>
            <ItemText>Nova Oferta</ItemText>
          </MenuItem>

          <MenuItem onClick={renderFillContract}>
            <ItemText>Preencher Contrato</ItemText>
          </MenuItem>

          {renderIn100Button()}

          {renderInvalidateAgreementPassword()}
          <Divider />
        </Panel>
      </Collapse>
    </div>
  );
}
