import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  width: 100%;
  padding-bottom: 32px;
  margin-bottom: 7px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.divider.primary};

  p,
  span {
    text-align: center;
    width: 300px;
  }
`;
