import styled from 'styled-components';

import { Form, Select } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  align-items: center;

  button {
    min-width: 170px;
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 24px;
  height: calc(100vh - 144px);
  justify-content: space-between;
`;

export const StyledSelect = styled(Select)`
  &.ant-cascader-picker {
    background-color: transparent;
    border: 1.5px solid ${({ theme }) => theme.colors.divider.primary};
    border-radius: 8px;
  }

  &.ant-cascader-picker:hover {
    background-color: ${({ theme }) => theme.colors.neutral.secondary};
  }
`;
