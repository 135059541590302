import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { AlertCircle, CaretDown, CloseCircle, LoadingSpinner } from '~/ui/assets/icons';
import {
  Wrapper,
  Select,
  SelectLabel,
  List,
  Item,
  SearchSection,
  DropDown,
  Placeholder,
  Label,
  Avatar,
  Value,
  IconWrapper,
} from './SelectSearch.styled';
import { SearchBar } from '../SearchBar/SearchBar';
import { Tag } from '../Tags/Tags';

type OptionsType = {
  label: string;
  value: string | number;
  img?: string;
  color?: 'positive' | 'negative' | 'alert' | 'neutral' | 'info';
};

type SelectSearchProps = {
  placeholder?: string;
  disabled?: boolean;
  pictured?: boolean;
  searchable?: boolean;
  withTags?: boolean;
  fullWidth?: boolean;
  width?: number;
  options: OptionsType[];
  onChange?: (value: string) => void;
  value?: OptionsType;
  onSearch?: (value: string) => void;
  onClear?: () => void;
  loading?: boolean;
};

export function SelectSearch({
  placeholder = 'Selecione uma opção',
  disabled = false,
  pictured = false,
  searchable = true,
  withTags = false,
  fullWidth = false,
  width,
  value = null,
  options,
  loading = false,
  onChange,
  onSearch,
  onClear,
}: SelectSearchProps) {
  const { colors } = useTheme();
  const selectRef = useRef(null);
  const labelRef = useRef(null);
  const [selected, setSelected] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [minWidth, setMinWidth] = useState(null);

  const textColor = (palette) => colors?.[palette]?.primaryAlt;
  const bgColor = (palette) => colors?.[palette]?.secondary;

  const handleSelect = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelectItem = useCallback((value) => {
    setSelected(value);
    setIsOpen(false);
    onChange(value);
  }, []);

  const handleClearSelection = useCallback(() => {
    setSelected(null);
    setIsOpen(false);
    onChange(null);
    onClear();
  }, []);

  const selectedLabel = useMemo(
    () => options?.find((option) => option.value === selected?.value),
    [selected, options],
  );

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (labelRef.current) {
      // Pega o tamanho do elemento
      const { width } = labelRef.current.getBoundingClientRect();
      setMinWidth(width + 40);
    }
  }, []);

  useEffect(() => {
    if (value !== selected) {
      setSelected(value);
    }
  }, [value]);

  return (
    <div>
      <Wrapper ref={selectRef} fullWidth={fullWidth}>
        <Select
          disabled={disabled}
          isOpen={isOpen}
          hasSelection={!!selected}
          pictured={pictured}
          onClick={handleSelect}
        >
          <SelectLabel minWidth={width > minWidth ? width : minWidth}>
            {pictured && selected && (
              <Avatar title={selectedLabel?.label} image={selectedLabel?.img} small />
            )}
            <Value hasValue={!!selectedLabel}>
              <Placeholder id="placeholder" ref={labelRef}>
                {placeholder}
              </Placeholder>
              {selectedLabel && (
                <Label id="label" maxWidth={minWidth}>
                  {!withTags && selectedLabel?.label}
                  {withTags && (
                    <Tag
                      small
                      ellipsis
                      rounded
                      textColor={textColor(selectedLabel?.color)}
                      bgColor={bgColor(selectedLabel?.color)}
                    >
                      {selectedLabel?.label}
                    </Tag>
                  )}
                </Label>
              )}
            </Value>
          </SelectLabel>
          <CaretDown id="caretDown" width={12} height={12} />
          {selected && (
            <CloseCircle id="closeCircle" onClick={handleClearSelection} width={12} height={12} />
          )}
        </Select>

        {isOpen && (
          <DropDown>
            {searchable && (
              <SearchSection>
                <SearchBar onChange={onSearch} />
              </SearchSection>
            )}
            <List>
              {Array.isArray(options) && !loading && options?.map((option) => (
                <Item
                  onClick={() => handleSelectItem(option)}
                  selected={selected?.value === option.value}
                  key={option.value}
                >
                  {pictured && !withTags && (
                    <Avatar small title={option.label} image={option?.img} />
                  )}
                  {!withTags && <span>{option.label}</span>}
                  {withTags && (
                    <Tag
                      small
                      rounded
                      textColor={textColor(option.color)}
                      bgColor={bgColor(option.color)}
                    >
                      {option.label}
                    </Tag>
                  )}
                </Item>
              ))}
              {!options && !loading && (
                <IconWrapper>
                  <AlertCircle width={20} height={20} />
                  <span>Nenhuma opção disponível!</span>
                </IconWrapper>
              )}
              {!options && loading && (
                <IconWrapper>
                  <LoadingSpinner />
                </IconWrapper>
              )}
            </List>
          </DropDown>
        )}
      </Wrapper>
    </div>
  );
}
