const registrationStatus = {
  PASSWORD: 'PASSWORD',
  EMAIL: 'EMAIL',
  FACEPICTURE: 'FACEPICTURE',
  TERMS_OF_CONTRACT: 'TERMS_OF_CONTRACT',
  OPEN: 'OPEN',
};

const consignee = {
  326: 'Parati',
  329: 'Qitech',
  PARATI: 'Parati',
  QITECH: 'Qitech',
};

enum AGREEMENT {
  INSS = 42,
  FGTS = 50,
  PAB = 75,
  CLIX = 999,
  CONSIGPRIV = 10,
}

const AGREEMENT_NAMES = {
  [AGREEMENT.INSS]: 'INSS',
  [AGREEMENT.FGTS]: 'FGTS',
  [AGREEMENT.PAB]: 'PAB',
  [AGREEMENT.CLIX]: 'CLIX',
  [AGREEMENT.CONSIGPRIV]: 'Consignado Privado',
};

export { AGREEMENT, AGREEMENT_NAMES, registrationStatus, consignee };
